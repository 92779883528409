<script setup>
import { ref, watch, onMounted, onBeforeUnmount, nextTick } from "vue";
import * as echarts from "echarts";

const props = defineProps({
  options: {
    type: Object,
    required: true,
  },
  chartStyle: {
    type: Object,
    default: () => ({}),
  },
});

const chartRef = ref(null);

let chartInstance = null;

const initChart = async () => {
  if (chartRef.value) {
    await nextTick();
    const container = chartRef.value;
    if (container.clientWidth && container.clientHeight) {
      chartInstance = echarts.init(container);
      chartInstance.setOption(props.options);
    } else {
      setTimeout(initChart, 100);
    }
  } else {
    setTimeout(initChart, 100);
  }
};
const updateChart = () => {
  if (chartInstance && props.options) {
    chartInstance.setOption(props.options, true);
  }
};

const handleResize = () => {
  if (chartInstance) {
    chartInstance.resize();
  }
};

watch(
  () => props.options,
  (newOptions) => {
    updateChart();
  },
  { immediate: true, deep: true }
);

onMounted(() => {
  initChart();
  window.addEventListener("resize", handleResize);
});

onBeforeUnmount(() => {
  if (chartInstance) {
    chartInstance.dispose();
    chartInstance = null;
  }
  window.removeEventListener("resize", handleResize);
});
</script>

<template>
  <div :style="{ width: '100%', height: '450px' }" ref="chartRef"></div>
</template>
