import ResourceService from "./resource.service";
import * as Vue from 'vue';
import Client from "@/services/api/api.service"
class ReportsService extends ResourceService {
  static get resource() {
    return "admin/reports";
  }

  static facility(params) {
    const url = `${this.resource}/facility`;
    //TODO: shouldnot call axios, should call ReportsService, same for all
    return new Promise((resolve, reject) => {
      Client
        .get(url, { params })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static applications(params) {
    const url = `${this.resource}/applications`;

    return new Promise((resolve, reject) => {
      Client
        .get(url, { params })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static companies(params) {
    const url = `${this.resource}/companies`;

    return new Promise((resolve, reject) => {
      Client
        .get(url, { params })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static payments(params) {
    const url = `${this.resource}/payments`;

    return new Promise((resolve, reject) => {
      Client
        .get(url, { params })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default ReportsService;
