<template>
  <div id="version-page">
    <div>
      <h2>Admin Version</h2>
      <p>
        This is version 1.0.9 Scoring Admin
      </p>
        <router-link to="/">Go back to home</router-link>

    </div>
  </div>
</template>

<style scoped>
#version-page {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
}
</style>
