<template>
  <div>
    <v-chart class="chart-total" :option="option" />
    <div class="p-4 m-t-350">
      <div class="info">
        <EllipseSvg color="#B4E7E2" />
        <div class="info-title">Eligible:</div>
        <div class="info-amount">{{ eligibleAmount }}</div>
      </div>
      <div class="info">
        <EllipseSvg color="#0A7FA3" />
        <div class="info-title">Ineligible:</div>
        <div class="info-amount">{{ ineligibleAmount }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";

import VChart from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

import EllipseSvg from "@/components/common/ellipseSvg.vue";

export default {
  name: "UnutilizedPage",
  components: { VChart, EllipseSvg },
  props: ["eligibleAmount", "ineligibleAmount"],
  data() {
    return {
      option: {
        title: [
          {
            text: "Total",
            left: "center",
            top: "45%",
            textStyle: {
              color: "#071015",
              fontSize: 16,
            },
          },
          {
            text: "Applications",
            left: "center",
            top: "52%",
            textStyle: {
              color: "#071015",
              fontSize: 16,
            },
          },
          {
            text: "197",
            left: "center",
            top: "59%",
            textStyle: {
              color: "#071015",
              fontSize: 16,
            },
          },
        ],
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            return `${params.name}: ${params.data.actualValue}`;
          },
        },
        toolbox: {
          show: true,
        },
        series: [
          {
            type: "pie",
            radius: [80, 120],
            center: ["50%", "60%"],
            roseType: "radius",
            itemStyle: {
              borderRadius: 0,
            },
            avoidLabelOverlap: false,
            padAngle: 3,
            label: {
              show: false,
            },

            data: [
              {
                value: 75,
                actualValue: parseInt(this.eligibleAmount),
                name: "eligible",
                itemStyle: { color: "#DAF3F1" },
              },
              {
                value:120 ,
                actualValue: parseInt(this.ineligibleAmount),
                name: "ineligible",
                itemStyle: { color: "#0A7FA3" },
              },
            ],
          },
        ],
      },
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.m-t-350 {
  margin-top: 350px;
}
.header-amount {
  font-size: 32px;
  font-weight: 700;
  line-height: 35.2px;
  color: #071015;
  margin-top: 16px;
}
.header-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: #071015;
}
.info-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  color: #071015;
}
.info-amount {
  font-size: 14px;
  font-weight: 700;
  line-height: 18.2px;
  color: #071015;
}
.info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
}
.chart-total {
  height: 330px;
  position: absolute;
  top: 0;
  left: 0;
}
.m-t-130 {
  margin-top: 130px;
}
</style>
