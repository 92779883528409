import ResourceService from "./resource.service";
import * as Vue from 'vue';
import Client from "@/services/api/api.service"

class SettingsService extends ResourceService {
  static get resource() {
    return "admin";
  }

  static settings() {
    return SettingsService.get("settings");
  }

  static updateFacilityRaised(payload) {
    return SettingsService.post(payload, "facility_raised");
  }

  static usersData(params) {
    return SettingsService.get("users", params);
  }

  static addUsers(payload) {
    return SettingsService.post(payload, "users");
  }

  static companyLists() {
    return SettingsService.get("companies-list");
  }

  static updateUser(payload) {
    return SettingsService.patch(`users/${payload.id}`, payload);
  }

  static userSoftDelete(user_id) {
    const url = `${this.resource}/users/${user_id}`;

    return new Promise((resolve, reject) => {
      Client
        .delete(url)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static checkUser(params) {
    return SettingsService.get("check_users", params);
  }
}


export default SettingsService;
