<template>
  <div id="login-container">
    <div class="flex-container">
      <div class="form-container">
        <div class="logo-container">
          <img src="/images/Capifly_Logo.png" alt="Capifly_Logo" />
        </div>
        <div class="welcome-text">Log Into My Account</div>
        <b-alert :show="error && error.length" variant="danger">
          <div v-html="error || '&nbsp;'"></div>
        </b-alert>
        <form @submit.prevent="submitForm">
          <base-input
            placeholder="Email"
            type="text"
            field="email"
            :value="state.email"
            @change-value="setValue"
            :disabled="loading"
            :is_invalid="v$.email.$invalid && v$.email.$dirty"
            :helper_text="v$.email.$error ? v$.email.$errors[0].$message : ''"
          ></base-input>
      

          <base-input
            placeholder="Password"
            type="password"
            field="password"
            :value="state.password"
            @change-value="setValue"
            :disabled="loading"
            :is_invalid="v$.password.$invalid && v$.password.$dirty"
            :helper_text="
              v$.password.$error ? v$.password.$errors[0].$message : ''
            "
          ></base-input>
      

          <base-button
            type="submit"
            style_variant="primary"
            cus_style="font-size: 15px; font-family: Roboto_Medium"
            :disabled="loading"
            :loading="loading"
          >
            <span v-if="loading">Logging In...</span>
            <span v-else>LOG IN</span>
          </base-button>
        </form>
        <div class="link-container">
          <div class="flex-container" style="justify-content: flex-end">
            <div class="link" @click="changeLink('forgot-password')">
              Forgot password?
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  required,
  minLength,
  email as emailValidator,
} from "@vuelidate/validators";
import { reactive } from "vue";
import { useVuelidate } from "@vuelidate/core";



import { LOGIN } from "../../store/auth.module.js";

export default {
  name: "Login-Page",
  components: {},
  data() {
    return {
      error: null,
      loading: false,
    };
  },
  setup() {
    const state = reactive({
      email: "",
      password: "",
    });
    const rules = {
      email: { required, email: emailValidator },
      password: { required, minLength: minLength(6) },
    };
    const v$ = useVuelidate(rules, state);

    return { v$, state };
  },
  methods: {
    async submitForm() {
      this.v$.$touch();
      const valid = await this.v$.$validate();
      if (!valid) return;

      const payload = {
        email: this.state.email,
        password: this.state.password,
      };

      this.error = null;
      this.loading = true;

      return this.$store
        .dispatch(LOGIN, payload)
        .then(() => {
          if (this.$route.query.next)
            return this.$router.push(this.$route.query.next);
          else return this.$router.push({ name: "Dashboard" });
        })
        .catch(({ message }) => {
          this.error = message;
          
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setValue(val, field) {
      this.state[field]=val;
    },
    changeLink(name) {
      this.$router.push({ name });
    },
  },
};
</script>

<style scoped>
#login-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#login-container .flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
#login-container .logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}
#login-container .welcome-text {
  color: #5e595c;
  font-size: 24px;
  text-align: center;
  margin-bottom: 2rem;
}
#login-container .form-container {
  background-color: #ffffff;
  box-shadow: 1px 2px 29px #1717241a;
  border-radius: 5px;
  padding: 50px;
  width: 500px;
}
#login-container .link-container {
  margin-top: 1rem;
}
#login-container .link-container .link {
  color: #8f8b8d;
  font-size: 15px;
  cursor: pointer;
}
#login-container .link-container .link:hover {
  text-decoration: underline;
}
</style>
