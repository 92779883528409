<script setup>
import { reactive, watch, computed, toRefs, ref } from "vue";
import VueMultiselect from "vue-multiselect";
import Chart from "./Chart.vue";
import { genericChart } from "@/utils/chartUtils";
import SettingsCharts from "@/services/settingsChart.service";
import { useStore } from "vuex";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";

const store = useStore();
const props = defineProps({
  columns: {
    type: Object,
    required: true,
  },
  industry: {
    type: String,
    required: true,
  },
  formData: {
    required: true,
  },
  chartType: {
    required: true,
  },
  metricOptions: {
    required: true,
  },
});

//change this to backend Data or move to utils
const FORMAT_OPTIONS = [
  {
    name: "1.01",
    format: "{:.2f}",
  },
  {
    name: "1",
    format: "{:.0f}",
  },
  {
    name: "1.1",
    format: "{:.1f}",
  },
];
const AXIS_OPTIONS = [
  {
    name: "1",
    axis: "1",
  },
  {
    name: "0",
    axis: "0",
  },
];
const DRAWING_OPTIONS = [
  {
    name: "LINE",
    drawing: "LINE",
  },
  {
    name: "BAR",
    drawing: "BAR",
  },
  {
    name: "AREA",
    drawing: "AREA",
  },
  {
    name: "STACKED-COLUMN",
    drawing: "STACKED-COLUMN",
  },
  {
    name: "SINGLE_VALUE",
    drawing: "SINGLE_VALUE",
  },
];
const COLORS_OPTIONS = [
  {
    name: "#024058",
    color: "#024058",
  },
  {
    name: "#0A7FA3",
    color: "#0A7FA3",
  },
  {
    name: "#B4E7E2",
    color: "#B4E7E2",
  },
  {
    name: "#FFDE8B",
    color: "#FFDE8B",
  },
  {
    name: "#FF6E6E",
    color: "#FF6E6E",
  },
  {
    name: "#B1F3A4",
    color: "#B1F3A4",
  },
  {
    name: "#FFBA8F",
    color: "#FFBA8F",
  },
  {
    name: "#FFFB7B",
    color: "#FFFB7B",
  },
  {
    name: "#DAF3F1",
    color: "#DAF3F1",
  },
];

const stateOptions = reactive({
  metricOption: [],
  colorsOption: [],
  customOptions: [],
});

const show = ref(false);
const disableFinalSubmit = ref(false);
const disablePreview = ref(false);
const chartData = ref(null);
const isSingle = ref(false);
const chartDraftId = ref(null);
const showCopyToNew = ref(false);
const newChartName = ref("");

const state = reactive({
  columns: [
    {
      label: null,
      metric: null,
      color: null,
      format: null,
      axis: null,
      drawing: null,
      isMainAxis: false,
      isMainMetric: false,
    },
  ],
  mainAxisIndex: null,
  mainMetricIndex: null,
});

const industry = computed(() => props.industry);
const storeSegment = computed(() => store.state.chart.segment);

const { formData } = toRefs(props);

const filteredDrawingOptions = computed(() => {
  const type = props.formData?.type?.type;

  if (type !== "SINGLE_VALUE") {
    return DRAWING_OPTIONS.filter((option) => option.drawing !== "SINGLE_VALUE");
  }
  return DRAWING_OPTIONS;
});

const fetchMetricOption = () => {
  stateOptions.metricOption = props.metricOptions;
};
const fetchMPreviewDataOption = async (status) => {
  store.commit("chart/update", { field: "loading", value: true });
  try {
    const prepareColumn = state.columns.map((column) => ({
      ...column,
      metric: column.metric.metric,
      axis: column.axis.axis,
      color: column.color.color,
      type: column.drawing.drawing,
      format: column.format.format,
    }));

    const payload = {
      name: formData.value?.chartName,
      is_metric: formData.value?.isMetric,
      industry: industry.value,
      segment: formData?.value?.segment || storeSegment.value,
      main_axis: parseInt(state.columns[state.mainAxisIndex].axis["axis"]) || 0,
      metric_axis: parseInt(state.columns[state.mainMetricIndex].axis["axis"]) || 0,
      bucket: formData.value?.bucket?.bucket,
      type: formData.value?.type?.type,
      columns: prepareColumn,
      status: status,
      chart_id: status === "published" && showCopyToNew.value ? null : formData.value?.chartType?.chartType || chartDraftId.value,
      application_id: formData.value?.applicationId?.app,
    };

    const res = await SettingsCharts.getPreviewData(payload);
    return res;
  } catch (e) {
    console.error("Error fetching metricOption:", e);
  } finally {
    store.commit("chart/update", { field: "loading", value: false });
  }
};

const processColumns = async (newData, state, stateOptions) => {
  await fetchMetricOption();

  if (newData?.columns && newData?.columns.length > 0) {
    const transformedColumns = newData.columns.map((column) => {
      const metricOption = props?.metricOptions?.find(
        (item) => item.metric === column.metric
      );
      return {
        ...column,
        color: { name: column.color, color: column.color },
        drawing: { name: column.type, drawing: column.type },
        axis: { name: column.axis, axis: column.axis },
        format: { name: column.format, format: column.format },
        metric: metricOption ?? null,
      };
    });

    state.columns = transformedColumns;

    state.mainAxisIndex = newData.columns.findIndex(
      (column) => column.axis === newData.main_axis
    );

    state.mainMetricIndex = newData.columns.findIndex(
      (column) => column.axis === newData.metric_axis
    );
  } else {
    state.columns = [
      {
        label: "",
        metric: null,
        color: null,
        format: null,
        axis: null,
        drawing: null,
        isMainAxis: false,
        isMainMetric: false,
      },
    ];
  }
};
watch(
  () => props.columns,
  async (newData) => {
    await processColumns(newData, state, stateOptions);
  },
  { immediate: true }
);
watch(
  () => formData.value?.type?.type,
  (newData) => {
    if (newData === "SINGLE_VALUE") {
      handleSingleValueType();
      isSingle.value = true;
    } else {
      handleDefaultType();
      isSingle.value = false;
    }
  },
  { deep: true, immediate: true }
);

function handleSingleValueType() {
  if (state.mainAxisIndex != null) {
    state.columns = [state.columns[state.mainAxisIndex]];
  } else {
    state.columns = processColumns(props.columns, state, stateOptions);
  }
}

function handleDefaultType() {
  state.columns = processColumns(props.columns, state, stateOptions);
}

watch(
  () => props.chartType,
  (newData) => {
    show.value = false;
    //disableFinalSubmit.value = true;
  },
  { immediate: true }
);

watch(
  () => state.columns,
  (newData) => {
    if (newData.length > 0) {
      const metricColumnsData = newData?.map((item) => item?.metric);
      const metricOptions = stateOptions.metricOption;

      const filterObjects = (first, second) =>
        first.filter((item1) => !second.some((item2) => item2?.metric === item1?.metric));
      stateOptions.customOptions = filterObjects(metricOptions, metricColumnsData);
    }
  },
  { deep: true, immediate: true }
);

function getTextColor(bgColor) {
  if (!bgColor) return "#000000";
  const hex = bgColor.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? "#000000" : "#ffffff";
}

function addColumn() {
  state.columns.push({
    label: "",
    metric: null,
    color: null,
    format: null,
    axis: null,
    drawing: null,
    isMainAxis: false,
    isMainMetric: false,
  });
}

const validateColumns = (data) => {
  if (data.mainAxisIndex == null || data.mainMetricIndex == null) {
    return "Missing main axis  or main metric";
  }
  if (
    data.mainAxisIndex > data?.columns?.length - 1 ||
    data.mainMetricIndex > data?.columns?.length - 1
  ) {
    return "Missing required fields.";
  }
  for (const column of data.columns) {
    if (
      !column.label ||
      !column.metric ||
      !column.color ||
      !column.format ||
      !column.axis ||
      !column.drawing
    ) {
      return "Missing required fields.";
    }
  }
  return null;
};
const modalRef = ref(null);

const showModal = () => {
  if (modalRef.value) {
    modalRef.value.$bvModal.show("confirmation-modal");
  }
};
async function submitData() {
  const validationError = validateColumns(state);
  if (validationError) {
    alert(validationError);
    return;
  }
  let result = await fetchMPreviewDataOption("draft");
  if (result?.chart2?.columns[0]?.data?.length < 1) {
    alert("no data available");
    show.value = false;
    //disableFinalSubmit.value = true;
    return;
  }
  chartDraftId.value = result?.draft_id || null;
  if (result) {
    chartData.value = result?.chart2;
    show.value = true;
    //disableFinalSubmit.value = false;
  }
}

const handleSubmit = async () => {
  const validationError = validateColumns(state);
  if (validationError) {
    alert(validationError);
    return;
  }
  showModal();
};
const handleFinalSubmit = async () => {
  const validationError = validateColumns(state);
  if (validationError) {
    alert(validationError);
    return;
  }

  try {
    store.commit("chart/update", { field: "loading", value: true });
    await fetchMPreviewDataOption("published");
    window.location.reload();
  } catch (e) {
    console.error("Error fetching colorsOption:", e);
  } finally {
    store.commit("chart/update", { field: "loading", value: false });
  }
};

const handleCopyToNew = () => {
  showCopyToNew.value = true;
  disableFinalSubmit.value = true;
};

const handleCopySubmit = async () => {
  if (!newChartName.value) {
    alert("Chart name is required.");
    return;
  }

  const validationError = validateColumns(state);
  if (validationError) {
    alert(validationError);
    return;
  }

  try {
    store.commit("chart/update", { field: "loading", value: true });
    chartDraftId.value = null; // Clear the chart ID
    formData.value.chartName = newChartName.value; // Set the new chart name
    formData.value.chart_id = null; // Clear the chart ID
    formData.value.chartDraftId = null; // Clear the chart draft ID
    await fetchMPreviewDataOption("published");
    window.location.reload();
  } catch (e) {
    console.error("Error fetching colorsOption:", e);
  } finally {
    store.commit("chart/update", { field: "loading", value: false });
  }
};

const handleDelete = (idx) => {
  state.columns.splice(idx, 1);
};

const confirmationDialogText = computed(() => {
  return formData?.value?.isNewChart
    ? "Are you sure you want to create this new chart?"
    : "This change will apply to all existing charts across all applications.\nDo you wish to proceed?";
});

function handleMetricInput() {
  // Get all selected metrics (columns from your `state.columns` data structure)
  const selectedMetrics = state.columns.map(col => col.metric); // Remove null/undefined values

  console.log("Selected Metrics:", selectedMetrics);

  // Check if any metric has `has_data: true`
  const hasFalseData = selectedMetrics.find(metric => metric && metric.has_data === false);
  disablePreview.value = !!hasFalseData; // If a matching item is found, disable preview

}
</script>

<template>
  <div class="py-4">
    <h1>Columns</h1>
    <div v-for="(column, index) in state.columns" :key="index" class="columns-group">
      <div class="columns-label">
        <div>Label:</div>
        <input
          type="text"
          placeholder="Label *"
          class="form-control"
          v-model="column.label"
        />
      </div>
      <div class="columns-metric">
        <div>Metric:</div>
        <VueMultiselect
          v-model="column.metric"
          tag-placeholder="Select metric *"
          placeholder="Select metric *"
          label="name"
          track-by="metric"
          :options="stateOptions.customOptions"
          @select="handleMetricInput()"
        />
      </div>
      <div class="columns-color">
        <div>Color:</div>
        <VueMultiselect
          v-model="column.color"
          tag-placeholder="Select color *"
          placeholder="Select color *"
          label="name"
          track-by="color"
          :options="COLORS_OPTIONS"
        >
          <template #option="{ option }">
            <div
              class="color-option"
              :style="{
                backgroundColor: option.color,
                color: getTextColor(option.color),
              }"
            >
              {{ option.name }}
            </div>
          </template>
        </VueMultiselect>
      </div>
      <div class="columns-format">
        <div>Format:</div>
        <VueMultiselect
          v-model="column.format"
          tag-placeholder="Select format *"
          placeholder="Select format *"
          label="name"
          track-by="format"
          :options="FORMAT_OPTIONS"
        />
      </div>
      <div class="columns-axis">
        <div>Axis:</div>
        <VueMultiselect
          v-model="column.axis"
          tag-placeholder="Select axis *"
          placeholder="Select axis *"
          label="name"
          track-by="axis"
          :options="AXIS_OPTIONS"
        />
      </div>
      <div class="columns-drawing">
        <div>Drawing:</div>
        <VueMultiselect
          v-model="column.drawing"
          tag-placeholder="Select drawing *"
          placeholder="Select drawing *"
          label="name"
          track-by="drawing"
          :options="filteredDrawingOptions"
        />
      </div>
      <div class="columns-mainAxis">
        <div>Main Axis:</div>
        <input type="radio" :value="index" v-model="state.mainAxisIndex" />
      </div>
      <div class="columns-metricAxis">
        <div>Main Metric:</div>
        <input type="radio" :value="index" v-model="state.mainMetricIndex" />
      </div>
      <button class="standard-btn" @click="handleDelete(index)">delete column</button>
    </div>

    <div class="py-2">
      <button
        class="standard-btn"
        @click="addColumn"
        :class="{ disable: isSingle }"
        :disabled="isSingle"
      >
        Add Column
      </button>
    </div>

    <button class="standard-btn"
            :class="{ disable: disablePreview }"
          @click="submitData">Submit for Preview</button>
    <div></div>

    <div class="preview-width">
      <Chart
        :options="genericChart(chartData, false).options"
        :chartStyle="{ width: '100%', height: '450px' }"
        v-if="show"
      />
    </div>

    <div class="py-2 d-flex justify-content-center">
      <button
          :class="{ disable: disableFinalSubmit }"
        class="standard-btn"
        @click="handleSubmit"
      >
        FINAL SUBMIT
      </button>
    </div>

    <div class="py-2 d-flex justify-content-center">
      <button
          class="standard-btn"
          @click="handleCopyToNew"
      >
        COPY TO NEW
      </button>
    </div>

    <div v-if="showCopyToNew" class="py-2">
      <input
          type="text"
          placeholder="New Chart Name *"
          class="form-control"
          v-model="newChartName"
      />
      <button class="standard-btn" @click="handleCopySubmit">Submit New Chart</button>
    </div>

    <ConfirmationDialog
      ref="modalRef"
      :onSubmit="handleFinalSubmit"
      :text="confirmationDialogText"
    />
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.preview-width {
  width: 533px;
}
.disable {
  opacity: 0.6;
  pointer-events: none;
  cursor: not-allowed;
}
.err-msg {
  font-size: 12px;
  color: red;
}
.columns-group {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  border: 1px solid #ccc;
  padding: 16px;
  margin-bottom: 16px;
}
.standard-btn {
  height: 36px;
  padding: 8px 10px 8px 10px;
  border-radius: 6px;
  background-color: #024058;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
</style>
