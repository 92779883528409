<template>
  <div>
    <div class="container-wrapper">
      <div class="container-sections">
        <div class="welcome">Hello, {{ adminName }}!</div>
        <div class="section-one">
          <div class="flex-container">
            <div class="box box-75">
              <TotalRaised
                totalAmount="1400000"
                investorsAmount="672300"
                profitAmount="122500"
              />
            </div>
            <div class="box box-25 position-relative">
              <Unutilized
                unutilizedInvestorAmount="0"
                unutilizedFacilityAmount="0"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="container-sections">
        <div class="section-two">
          <div class="flex-container">
            <div class="box-75">
              <FacilityExtended
                facilityExtendedAmount="2000000"
                repaidAmount="1200000"
                outstandingAmount="1100000"
              />

              <div class="mt-4 statistics">
                <div class="head-line">
                  <div class="statistics-title">Average Annual Return</div>
                  <div class="statistics-amount-AAR">17%</div>
                </div>
                <div class="head-line">
                  <div class="statistics-title">
                    Total Number of Transactions
                  </div>
                  <div class="statistics-amount-TT">
                    <div>17</div>
                    <div class="statistics-subtitle">17 transaction across 11 startups
                    </div>
                  </div>
                </div>

                <div class="head-line">
                  <div class="statistics-title">
                    Conversion <br />
                    Rate
                  </div>
                  <div class="statistics-amount-CR">
                    <div>11%</div>
                    <div class="statistics-subtitle">
                      funded/total application
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="box box-25 position-relative">
              <TotalApplication eligibleAmount="79" ineligibleAmount="111" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="filter-section container-wrapper-filter">
      <FilterSection />
    </div>
  </div>
</template>

<script>
import TotalRaised from "./totalRaised.vue";
import Unutilized from "./unutilized.vue";
import FacilityExtended from "./facilityExtended.vue";
import TotalApplication from "./totalApplication.vue";
import FilterSection from "./filterSection.vue";

export default {
  name: "DashboardPage",
  components: {
    TotalRaised,
    Unutilized,
    FacilityExtended,
    TotalApplication,
    FilterSection,
  },

  data() {
    return {
      adminName: "Admin",
      unutilized: "$205k",
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.filter-section {
  border-radius: 8px;
}

.statistics-subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  color: #071015;
}

.statistics-title {
  padding: 0px 0px 16px 0px;
  max-width: 160px;
  min-height: 57px;
}
.statistics-amount-AAR {
  padding: 8px;
  border-radius: 8px;
  background: #e7ffe5;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.statistics-amount-TT {
  padding: 8px;
  border-radius: 8px;
  background: #daf3f1;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.statistics-amount-CR {
  padding: 8px;
  border-radius: 8px;
  background: #e7ffe5;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.statistics {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
.head-line {
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
  color: #071015;
  padding: 16px;
  background: #ffffff;
  border-radius: 8px;
  min-height: 175px;
  flex: 1 1 calc(33% - 16px);
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 0px #0000001a;
}
.welcome {
  font-size: 18px;
  font-weight: 700;
  line-height: 23.4px;
  color: #394044;
  padding: 0px 0 32px 0;
}

.info-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 16px;
}
.header-value {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #071015;
  margin-top: 8px;
}
.facility-box {
  padding: 24px !important;
}
.section-two-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: self-start;
}
.m-130 {
  margin-top: 130px;
}
.m-350 {
  margin-top: 350px;
}
.header-amount {
  font-size: 32px;
  font-weight: 700;
  line-height: 35.2px;
  color: #071015;
  margin-top: 16px;
}
.header-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: #071015;
}
.info-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  color: #071015;
}
.info-amount {
  font-size: 14px;
  font-weight: 700;
  line-height: 18.2px;
  color: #071015;
}
.info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  margin-top: 16px;
}
.container-wrapper {
  margin: 0;
  padding: 47px 40px 0px 40px;
}
.container-wrapper-filter {
  margin: 0;
  padding: 40px 40px 40px 56px;
}
.container-sections {
  margin: 0;
  padding: 16px;
}

.flex-container {
  display: flex;
  gap: 20px;
}

.box {
  padding: 24px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px #0000001a;
}

.box-75 {
  flex: 0 0 70%;
}

.box-25 {
  flex: 0 0 30%;
  padding: 0;
}
.chart {
  height: 300px;
  position: absolute;
  top: 0;
  left: 0;
}
.chart-total {
  height: 300px;
  position: absolute;
  top: 0;
  left: 0;
}
.chartProgress {
  height: 80px;
}
</style>
