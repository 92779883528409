<template>
    <div id="SubmitStatus">
        <div class="top-container">

            <div>
                <button class="refresh-btn" @click="handleRefresh">Refresh</button>
            </div>

            <div class="pagination-container" v-if="total_count">
                <base-pagination :active_page="active_page" :total_page="total_page" :total_count="total_count"
                    :page_size="page_size" @set-page="setPage" />
            </div>

        </div>
        <div v-if="querying">
            <common-loading />
        </div>
        <div class="content-table" v-if="data && data.length > 0">
            <div class="table-container">
                <b-row class="table-title">
                    <b-col class="table-title-field">
                        <div class="table-title-text">Date</div>
                        <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
                    </b-col>
                    <b-col class="table-title-field">
                        <div class="table-title-text">Company Name</div>
                        <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
                    </b-col>
                    <b-col class="table-title-field">
                        <div class="table-title-text">Output Link</div>
                        <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
                    </b-col>
                    <b-col class="table-title-field">
                        <div class="table-title-text">Output</div>
                        <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
                    </b-col>
                    <b-col class="table-title-field">
                        <div class="table-title-text">Status</div>
                        <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
                    </b-col>
                </b-row>
                <b-alert :show="error && error.length ? true : false" variant="danger">
                    <div v-html="error || '&nbsp;'"></div>
                </b-alert>
                <div v-if="querying">
                    <common-loading />
                </div>
                <b-row class="table-data" v-for="(each, index) in data" :key="index">
                    <b-col class="com-info">
                        <div class="com-name" @click.stop="navigateToApplication(each.application_id)">{{
                            formattedDate(each.date) }}</div>
                    </b-col>
                    <b-col>
                        <div class="com-country">{{ each.company_id }}</div>
                    </b-col>
                    <b-col>
                        <div :class="each.status != 'SUCCESS' ? 'com-country' : 'link-s3'"
                        @click.stop="downloadS3(each.id,'output',each.application_id)">{{ each.status != "SUCCESS" ? "No Output" : "Download File"
                            }}</div>
                    </b-col>
                    <b-col>
                        <div :class="each.output ? 'link-s3' : 'com-country'" @click.stop="openShowErrorModal(each.output)">
                            {{ each.output ? "show Output" : "No Output" }}</div>
                    </b-col>
                    <b-col>
                        <div class="com-country">{{ each.status }}</div>
                    </b-col>
                </b-row>
            </div>
        </div>
        <div class="bottom-container">
            <div class="pagination-container" v-if="total_count">
                <base-pagination :active_page="active_page" :total_page="total_page" :total_count="total_count"
                    :page_size="page_size" @set-page="setPage" />
            </div>
        </div>
        <ShowErrorModal :data="errorData" />
    </div>
</template>
  
<script>
import ApplicationsService from '../../services/applications.service';
import { ErrorMessages } from '../../lib/errorMessages'
import ShowErrorModal from '../../components/common/ShowErrorModal.vue'
import moment from 'moment';

export default {
    name: "SubmitStatus",
    components: { ShowErrorModal },
    data() {
        return {
            error: null,
            querying: false,
            page_size: 10,
            active_page: 1,
            total_page: 0,
            total_count: 0,
            data: [],
            errorData: "",
        };
    },
    methods: {
        downloadS3(id, isConfig,applicationId) {
            const payload = { analysisId: id, isConfig: isConfig };

            ApplicationsService.analysisUrl(payload, applicationId)
                .then(({ data }) => {
                    if (data) {
                        window.open(data, "_blank");
                    }
                })
                .catch(({ message }) => {
                    this.error = message ? message : ErrorMessages.errorLoading;
                });
        },
        setPage(page) {
            this.querying = true
            this.active_page = page;
            this.updateFilteredData();
            this.querying = false;
        },
        navigateToApplication(application_id) {
            this.$router.push(`/applications/${application_id}`);
        },
        openS3(file) {
            //TODO: File shouldn't be included in the page (security) so please change it to pass App ID, and backend should know how to get the file
            if (!file) return
            let payload = { "file": file }
            ApplicationsService.applicationDownloadOutput(payload)
                .then(({ data }) => {
                    if (data) {
                        window.open(data, "_blank");
                    }
                })
                .catch(({ message }) => {
                    this.error = message
                        ? message
                        : ErrorMessages.errorLoading;
                })
        },
        query(params = {}) {
            params["page"] = this.active_page || 1;
            params["limit"] = this.page_size;

            this.error = null;
            this.querying = true;
            this.data = [];
            this.total_count = 0;

            ApplicationsService.getAllAnalysis(params)
                .then(({ data }) => {
                    if (!data) {
                        this.error = ErrorMessages.noData;
                    } else {
                        this.total_count = data.count;
                        this.data = data?.data.map(item => {
                            return {
                                id: item.id,
                                application_id: item.application_id,
                                company_id: item.company_id,
                                date: item.created_at,
                                output_path: item.output_path,
                                output: item.output,
                                status: item.status,
                            }
                        })
                        this.updateFilteredData();
                        this.total_page = Math.ceil(this.filteredData.length / this.page_size);
                    }

                })
                .catch(({ message }) => {
                    this.error = message
                        ? message
                        : ErrorMessages.errorLoading;
                })
                .finally(() => {
                    this.querying = false;
                });
        },
        updateFilteredData() {
            const start = (this.active_page - 1) * this.page_size;
            const end = start + this.page_size;
            this.filteredData = this.data.slice(start, end);
        },
        formattedDate(date) {
            return moment(date).format('YYYY-MM-DD HH:mm');
        },
        openShowErrorModal(data) {
            if (!data) return;
            this.errorData = data;
            this.$bvModal.show("ShowErrorModal");
        },
        handleRefresh() {
            window.location.reload();
        }
    },
    watch: {
        total_count(val) {
            this.total_page = Math.ceil(val / this.page_size);
        },
        active_page() {
            this.query();
        },
        $route(val) {
            if (val) this.query();
        },
    },
    mounted() {
        this.querying = true;
        this.query();
        this.querying = false;
    },
};

</script>
  
<style scoped>
.refresh-btn {
    border-radius: 6px;
    text-transform: capitalize;
    padding: 10px 20px;
    width: -webkit-fill-available;
    cursor: pointer;
    font-family: Roboto_Regular;
    background-color: #0A7FA3;
    color: #B4E7E2;
    border: 1px solid #0A7FA3;
}
.refresh-btn:hover{
    border-radius: 6px;
    text-transform: capitalize;
    padding: 10px 20px;
    width: -webkit-fill-available;
    cursor: pointer;
    background-color: #024058;
    color: #B4E7E2;
    border: 1px solid #024058;
}


#SubmitStatus {
    padding: 1rem 1rem;
}

#SubmitStatus .top-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0rem;
}

#SubmitStatus .content-table {
    margin-top: 1.5rem;
    box-shadow: 1px 2px 29px #1717241a;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 1rem;
}

#SubmitStatus .table-container {
    padding: 0.5rem;
}

#SubmitStatus .table-title {
    background-color: #f5f3ef;
    border-radius: 5px;
    padding: 1rem;
    align-items: center;
    margin-bottom: 1rem;
}

#SubmitStatus .table-title-field {
    display: flex;
    align-items: center;
    border-right: 1px solid #e9e6e3;
}

#SubmitStatus .sort-icon {
    height: 10px;
    margin-left: 0.5rem;
    cursor: pointer;
}

#SubmitStatus .table-title-text {
    font-size: 11px;
    font-family: Roboto_Bold;
    color: #5e595c;
}

#SubmitStatus .table-data {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 0.5rem;
    box-shadow: 0px 1px 4px #17172429;
    align-items: center;
    margin-bottom: 1rem;
    cursor: pointer;
}

#SubmitStatus .com-info {
    display: flex;
    align-items: center;
}

#SubmitStatus .com-name {
    font-size: 15px;
    font-family: Roboto_Bold;
}

#SubmitStatus .com-country {
    font-size: 13px;
    color: #8f8b8d;
}

#SubmitStatus .link-s3 {
    font-size: 13px;
    color: blue;
    font-weight: 600;
}

#SubmitStatus .bottom-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
}
</style>
  