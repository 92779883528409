<template>
  <b-modal id="update-data-modal" size="lg" centered hide-header hide-footer @hidden="reset">
    <div class="modal-title">Update Data</div>
    <b-alert :show="errors && errors.length ? true : false" variant="danger">
      <div v-html="errors || '&nbsp;'"></div>
    </b-alert>

    <div>
      <b-row>
        <b-col lg="6" sm="12">
          <p>
            <a href="/CohortsGistsTemplate.xlsx">Cohorts and GistsTemplate</a>
          </p>
          <base-input
            label="Upload File (one file containing all sheets)"
            :placeholder="'Upload your file here'"
            type="file"
            accept=".xlsx"
            field="uploadedFile"
            :value="uploadedFile && uploadedFile.name ? uploadedFile.name : ''"
            @change-value="setValue"
            :disabled="loading"
            :is_invalid="errors && errors.length"
            :helper_text="errors && errors.length ? errors[0] : ''">
          </base-input>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col offset-lg="8" lg="4" offset-sm="6" sm="6" class="d-flex align-items-center">
        <base-button
          style_variant="primary"
          cus_style="font-size: 15px; font-family: Roboto_Medium;margin-right:0.5rem;"
          :loading="loading"
          :disabled="!status"
          @onClick="submit">
          SUBMIT
        </base-button>
        <base-button
          style_variant="secondary"
          cus_style="font-size: 15px; font-family: Roboto_Medium"
          :disabled="loading"
          @onClick="closeModal">
          CANCEL
        </base-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import ApplicationsService from "../../services/applications.service";

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default {
  name: "UpdateDataModal",
  emits: ["refresh-page"],
  props: ["application_data"],
  data() {
    return {
      errors: null,
      loading: false,
      uploadedFile: null,
      status: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      let payload = {
        sheet: await toBase64(this.uploadedFile),
      };
      try {
        await ApplicationsService.updateApplicationData(this.application_data.id, payload);
        this.$emit("refresh-page");
        this.closeModal();
      } finally {
        this.loading = false;
      }
    },
    closeModal() {
      this.$bvModal.hide("update-data-modal");
      this.reset();
    },
    setValue(val, field) {
      if (field == "uploadedFile") this.status = true;
      this[field] = val;
    },

    reset() {
      this.errors = null;
      this.loading = false;
      this.status = null;
      this.uploadedFile = null;
    },
  },
};
</script>

<style scoped>
#change-status-modal .modal-title {
  font-size: 24px;
  font-family: Roboto_Medium;
  color: #5e595c;
  border-bottom: 2px solid #dfddd9;
  margin-bottom: 2rem;
}
</style>
