export function formatNumber(num) {
  if (num >= 1_000_000) {
    const formatted = (num / 1_000_000).toFixed(1);
    return `$${parseFloat(formatted)}M`;
  } else if (num >= 1_000) {
    const formatted = (num / 1_000).toFixed(1);
    return `$${parseFloat(formatted)}K`;
  } else {
    return `$${num}`;
  }
}
