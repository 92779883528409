<template>
  <div id="applicationDetails">
    <div v-if="!loading">
      <b-alert :show="error ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>
    </div>

    <div v-if="querying">
      <common-loading />
    </div>
    <div class="content-table" v-if="data && data.length > 0">
      <div class="table-container">
        <b-row class="table-title">
          <b-col class="table-title-field">
            <div class="table-title-text">Date</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">Application Name</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">Status</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
        </b-row>
        <b-alert :show="error ? true : false" variant="danger">
          <div v-html="error || '&nbsp;'"></div>
        </b-alert>
        <div v-if="querying">
          <common-loading />
        </div>
        <b-row class="table-data" v-for="(each, index) in data" :key="index">
          <b-col class="com-info">
            <div class="com-name" @click.stop="navigateToApplication(each.id)">
              {{ formattedDate(each.created_at) }}
            </div>
          </b-col>
          <b-col>
            <div class="com-country">{{ each.name }}</div>
          </b-col>
          <b-col>
            <div class="com-country">{{ each.state }}</div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyService from "../../services/company.service";
import { ErrorMessages } from "@/lib/errorMessages";
import moment from "moment";

export default {
  name: "ApplicationDetails",
  props: {
    company_name: String,
    company_id: String,
  },
  data() {
    return {
      error: null,
      querying: false,
      data: [],
      errorData: "",
      loading: false,
    };
  },
  methods: {
    navigateToApplication(application_id) {
      this.$router.push(`/applications/${application_id}`);
    },
    query() {
      this.error = null;
      this.querying = true;
      this.data = [];
      this.loading = true;

      CompanyService.applications(this.company_id)
        .then((apps) => {
          if (!apps == null || apps.length == 0) {
            this.error = ErrorMessages.noApplications;
          } else {
            this.data = apps;
          }
        })
        .catch(({ message }) => {
          this.error = message ? message : ErrorMessages.errorLoadingApplications;
        })
        .finally(() => {
          this.querying = false;
          this.loading = false;
        });
    },
    formattedDate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm");
    },
  },

  watch: {
    $route(val) {
      if (val) this.query();
    },
  },
  mounted() {
    this.querying = true;
    this.query();
    this.querying = false;
  },
};
</script>

<style scoped>
.refresh-btn {
  border-radius: 6px;
  text-transform: capitalize;
  padding: 10px 20px;
  width: -webkit-fill-available;
  cursor: pointer;
  font-family: Roboto_Regular;
  background-color: #0a7fa3;
  color: #b4e7e2;
  border: 1px solid #0a7fa3;
}

#applicationDetails {
  padding: 1rem 1rem;
}

#applicationDetails .top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0rem;
}

#applicationDetails .content-table {
  margin-top: 1.5rem;
  box-shadow: 1px 2px 29px #1717241a;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem;
}

#applicationDetails .table-container {
  padding: 0.5rem;
}

#applicationDetails .table-title {
  background-color: #f5f3ef;
  border-radius: 5px;
  padding: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}

#applicationDetails .table-title-field {
  display: flex;
  align-items: center;
  border-right: 1px solid #e9e6e3;
}

#applicationDetails .sort-icon {
  height: 10px;
  margin-left: 0.5rem;
  cursor: pointer;
}

#applicationDetails .table-title-text {
  font-size: 11px;
  font-family: Roboto_Bold;
  color: #5e595c;
}

#applicationDetails .table-data {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem;
  box-shadow: 0px 1px 4px #17172429;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
}

#applicationDetails .com-info {
  display: flex;
  align-items: center;
}

#applicationDetails .com-name {
  font-size: 15px;
  font-family: Roboto_Bold;
}

#applicationDetails .com-country {
  font-size: 13px;
  color: #8f8b8d;
}

#applicationDetails .link-s3 {
  font-size: 13px;
  color: blue;
  font-weight: 600;
}

#applicationDetails .bottom-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}
</style>
