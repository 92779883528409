<template>
  <div>
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
            class="nav-link"
            aria-current="page"
            :class="{ active: selectedTab === 'stepOne' }"
            @click="selectTab('stepOne')"
        >
          System Settings
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link"
            @click="selectTab('stepTwo')"
            :class="{ active: selectedTab === 'stepTwo' }"
        >
          Users Settings
        </a>
      </li>
      <li class="nav-item" v-if="$store.getters.user.is_superuser">
        <a
            class="nav-link"
            @click="selectTab('stepThree')"
            :class="{ active: selectedTab === 'stepThree' }"
        >
          Integration Settings
        </a>
      </li>
    </ul>

    <div v-if="selectedTab === 'stepOne'">
      <Settings />
    </div>
    <div v-else-if="selectedTab === 'stepTwo'">
      <UsersSettings />
    </div>
    <div v-else-if="selectedTab === 'stepThree'">
      <IntegrationSettings />
    </div>
  </div>
</template>

<script>
import Settings from "./Settings.vue";
import UsersSettings from "./UsersSettings.vue";
import IntegrationSettings from "./IntegrationSettings.vue";

export default {
  name: "settings-page",
  components: {
    Settings,
    UsersSettings,
    IntegrationSettings,
  },
  data() {
    return {
      selectedTab: "stepOne",
    };
  },
  methods: {
    selectTab(tab) {
      this.selectedTab = tab;
    },
  },
};
</script>