<script setup>
import { reactive, onMounted, watch, computed } from "vue";
import VueMultiselect from "vue-multiselect";
import SettingsCharts from "@/services/settingsChart.service";
import ChartForm from "./ChartForm.vue";
import { useStore } from "vuex";

const store = useStore();
const loading = computed(() => store.state.chart.loading);

const state = reactive({
  industry: null,
  industryOptions: [],
  chartDetails: null,
  segment: null,
  segmentOptions: [],
  selectedApp: null,
  appsOptions: [],
  metricOption: [],
  applicationAllData: null,
});

const handleNewChart = () => {
  state.isNewChart = true;
  state.chartType = null;
  state.chartDetails = [];
};

const fetchIndustries = async () => {
  store.commit("chart/update", { field: "loading", value: true });

  try {
    const res = await SettingsCharts.industries();
    state.industryOptions = res.map((item) => ({ name: item, industry: item }));
  } catch (e) {
    console.error("Error fetching industries:", e);
  } finally {
    store.commit("chart/update", { field: "loading", value: false });
  }
};

const fetchApps = async () => {
  store.commit("chart/update", { field: "loading", value: true });

  try {
    const payload = {
      industry: state.industry?.industry,
      segment: state.segment?.segment,
    };
    const res = await SettingsCharts.getApps(payload);
    state.appsOptions = res.map((item) => ({ name: item.app_name, app: item.app_id }));
    state.applicationAllData = res;
  } catch (e) {
    console.error("Error fetching industries:", e);
  } finally {
    store.commit("chart/update", { field: "loading", value: false });
  }
};

const fetchCharts = async (industry) => {
  store.commit("chart/update", { field: "loading", value: true });

  try {
    const params = new URLSearchParams();
    if (state.selectedApp?.app) params.append("application_id", state.selectedApp.app);
    if (state.segment?.segment) params.append("segment", state.segment?.segment);
    const queryString = params.toString();

    const res = await SettingsCharts.getCharts(industry, `?${queryString}`);
    state.chartsOptions = res.map((item) => ({
      name: item.name,
      chartType: item.id,
    }));
  } catch (e) {
    console.error("Error fetching charts:", e);
  } finally {
    store.commit("chart/update", { field: "loading", value: false });
  }
};

const fetchChartData = async (id) => {
  store.commit("chart/update", { field: "loading", value: true });

  try {
    state.chartDetails = await SettingsCharts.getChartDetails(id);
  } catch (e) {
    console.error("Error fetching chart details:", e);
  } finally {
    store.commit("chart/update", { field: "loading", value: false });
  }
};

const fetchSegments = async (industry) => {
  store.commit("chart/update", { field: "loading", value: true });

  try {
    let res = await SettingsCharts.getSegmentOption(industry);
    state.segmentOptions = res.map((item) => ({
      name: item,
      segment: item,
    }));
  } catch (e) {
    console.error("Error fetching chart details:", e);
  } finally {
    store.commit("chart/update", { field: "loading", value: false });
  }
};
watch(
  () => state.industry,
  (newIndustry) => {
    if (newIndustry) fetchSegments(newIndustry.industry);
    state.selectedApp = null;
    state.chartType = null;
    state.chartsOptions = null;
    state.chartDetails = null;
    state.isNewChart = false;
    state.appsOptions = [];
    state.segment = null;
  }
);
watch(
  () => state.chartType,
  (chartType) => {
    if (chartType) {
      fetchChartData(chartType.chartType);
      state.isNewChart = false;
    }
  }
);
watch(
  () => state.selectedApp,
  (newData) => {
    state.chartType = null;
    if (newData) fetchCharts(state.industry?.industry);

    const selectedAppMetricData = state.applicationAllData?.find(
      (item) => item.app_id == newData?.app
    );
    const dataOptionsExtract = (data) => {
      return data.map((item) => ({
        name: item.metric_name,
        metric: item.metric,
        has_data: item.has_data,
      }));
    };
    state.metricOption = dataOptionsExtract(selectedAppMetricData?.unique_metrics);
  }
);
watch(
  () => state.segment,
  (newData) => {
    if (newData) {
      store.commit("chart/update", { field: "segment", value: newData?.segment });
    }
  }
);

const handleDeleteChart = async () => {
  await SettingsCharts.deleteChart();
};

const handleNextOne = () => {
  fetchApps();
};

onMounted(fetchIndustries);
</script>

<template>
  <div class="main-container">
    <div v-if="loading" class="loader-overlay">
      <common-loading />
    </div>
    <div class="form-container">
      <div v-if="state.industryOptions.length" class="form-group">
        <label for="industry" class="form-label">Industry *</label>
        <VueMultiselect
          v-model="state.industry"
          :options="state.industryOptions"
          placeholder="Select industry"
          label="name"
          track-by="industry"
          :multiple="false"
          aria-label="Select an industry"
        />
      </div>

      <div v-if="state.industry && state.segmentOptions.length" class="form-group">
        <label for="chartType" class="form-label">Segment</label>
        <VueMultiselect
          v-model="state.segment"
          :options="state.segmentOptions"
          placeholder="Select segment"
          label="name"
          track-by="segment"
          :multiple="false"
          aria-label="Select a segment"
        />
      </div>

      <button class="standard-btn ml-4" @click="handleNextOne">NEXT</button>

      <div v-if="state.industry && state.appsOptions?.length" class="form-group">
        <label for="chartType" class="form-label">Applications</label>
        <VueMultiselect
          v-model="state.selectedApp"
          :options="state.appsOptions"
          placeholder="Select application"
          label="name"
          track-by="app"
          :multiple="false"
          aria-label="Select a application"
        />
      </div>

      <div
        v-if="
          state.industry && state?.chartsOptions?.length && state?.appsOptions?.length
        "
        class="form-group"
      >
        <label for="chartType" class="form-label">Chart Type</label>
        <VueMultiselect
          v-model="state.chartType"
          :options="state.chartsOptions"
          placeholder="Select chart type"
          label="name"
          track-by="chartType"
          :multiple="false"
          aria-label="Select a chart type"
        />
      </div>

      <div
        v-if="state.industry && state.selectedApp"
        class="py-2 d-flex justify-content-center"
      >
        <button class="standard-btn" @click="handleNewChart">New Chart</button>
        <button
          class="standard-btn ml-4"
          :class="{ disable: !state.chartType }"
          @click="handleDeleteChart"
        >
          Delete Chart
        </button>
      </div>

      <ChartForm
        v-if="
          state.industry && (state.chartType || state.isNewChart) && state.selectedApp
        "
        :chartType="state.chartType"
        :industry="state.industry"
        :detailsData="state.chartDetails"
        :isNewChart="state.isNewChart"
        :application-id="state.selectedApp"
        :metricOptions="state.metricOption"
      />
    </div>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.disable {
  opacity: 0.6;
  pointer-events: none;
  cursor: not-allowed;
}
.standard-btn {
  height: 36px;
  padding: 8px 10px 8px 10px;
  border-radius: 6px;
  background-color: #024058;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.chart-container {
  width: 1000px;
}
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
}
.form-container {
  width: 70%;
}
.mapping-label {
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
  color: #394044;
  margin-bottom: 8px;
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  z-index: 1000;
}
</style>
