<template>
  <b-modal
    id="edit-modal"
    size="lg"
    centered
    hide-header
    hide-footer
    @hidden="resetForm"
  >
    <div class="modal-title">Edit User Info</div>
    <div class="settings-wrapper">
      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>
      <b-row>
        <b-col lg="12" sm="12">
          <base-input
            label="New password"
            type="text"
            field="password"
            placeholder="leave blank to keep current password"
            :value="state.password"
            @change-value="setValue"
            :is_invalid="errors && errors.length"
            :helper_text="errors && errors.length ? errors[0] : ''"
          ></base-input>
        </b-col>
        <b-col lg="12" sm="12">
          <base-input
            label="First name"
            type="text"
            field="firstName"
            placeholder="First name"
            :value="state.firstName"
            @change-value="setValue"
            :is_invalid="v$.firstName.$error"
            :helper_text="
              v$.firstName.$error ? v$.firstName.$errors[0].$message : ''
            "
          ></base-input>
        </b-col>
        <b-col lg="12" sm="12">
          <base-input
            label="Last name"
            type="text"
            field="lastName"
            placeholder="Last name"
            :value="state.lastName"
            @change-value="setValue"
            :is_invalid="v$.lastName.$error"
            :helper_text="
              v$.lastName.$error ? v$.lastName.$errors[0].$message : ''
            "
          ></base-input>
        </b-col>
        <b-col lg="12" sm="12" class="py-2">
          <base-button
            :style_variant="isSubmitDisabled ? 'secondary' : 'primary'"
            cus_style="font-size:15px"
            @onClick="submit"
            :disabled="isSubmitDisabled"
          >
            SUBMIT
          </base-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { reactive } from "vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import SettingsService from "@/services/settingsDashboard.service";
import { ErrorMessages } from "@/lib/errorMessages";

export default {
  components: {},
  props: ["data"],
  emits: ["refresh-page"],
  setup() {
    const state = reactive({
      password: null,
      firstName: null,
      lastName: null,
    });

    const rules = reactive({
      firstName: { required },
      lastName: { required },
    });

    const v$ = useVuelidate(rules, state);

    const resetForm1 = () => {
      state.password = null;
      state.firstName = null;
      state.lastName = null;
      v$.value.$reset();
    };
    return {
      state,
      v$,
      resetForm1,
    };
  },
  data() {
    return {
      error: null,
    };
  },
  methods: {
    resetForm() {
      this.resetForm1();
    },
    setValue(val, field) {
      this.state[field] = val;
    },
    async submit() {
      this.v$.$touch();
      const valid = await this.v$.$validate();
      if (!valid) return;

      if (!this.data) {
        this.error = ErrorMessages.noData;
        return;
      }
      const payload = {
        id: this.data.id,
        password: this.state.password,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
      };
      SettingsService.updateUser(payload)
        .then((res) => {
          this.$emit("refresh-page");
          this.$bvModal.hide("edit-modal");
        })
        .catch((e) => {
          this.error = ErrorMessages.errorUploading;
        });
    },
  },

  computed: {
    isSubmitDisabled() {
      return !this.state.firstName || !this.state.lastName;
    },
  },
  watch: {
    data: {
      handler: function (newData) {
        if (newData) {
          this.state.firstName = newData.first_name;
          this.state.lastName = newData.last_name;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.data) {
      this.state.firstName = this.data.first_name;
      this.state.lastName = this.data.last_name;
    }
  },
};
</script>

<style scoped>
.modal-title {
  font-size: 24px;
  font-family: Roboto_Medium;
  color: #5e595c;
  border-bottom: 2px solid #dfddd9;
  margin-bottom: 1rem;
}

wrapper {
  padding: 80px;
}

.labelText {
  font-size: 15px;
  color: #5e595c;
  margin-bottom: 5px;
}

.helper-text {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.form-control:focus-visible {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}

.form-control:hover {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}

.form-control:focus {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}
</style>
