<template>
  <b-modal
    id="share-application-modal"
    title="Share Analysis"
    size="md"
    centered
    hide-footer
    hide-header
    @hidden="reset"
  >
    <div class="modal-title">Share Analysis</div>
      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>
      <div>

        <b-row>
          <b-col lg="12" sm="12">
          
              <base-input
                label="Name"
                placeholder="Name"
                type="text"
                field="clientName"
                :value="clientName"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"
              ></base-input>
          </b-col>
        </b-row>



        <b-row>
          <b-col lg="12" sm="12">
              <base-input
                label="Email"
                placeholder="Enter email address"
                type="email"
                field="email"
                :value="email"
                @change-value="setValue"
                :disabled="loading">
              </base-input>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="12" sm="12">
          
              <base-input
                label="Access Permissions Duration (Days)"
                placeholder="Enter number of days"
                type="number"
                field="days"
                min="1"
                max="30"
                :value="days"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"
              ></base-input>
          </b-col>
        </b-row>        
      </div>
      <b-row>
        <b-col
          offset-lg="6"
          lg="6"
          offset-sm="6"
          sm="6"
          class="d-flex align-items-center"
        >
        <base-button
            style_variant="secondary"
            cus_style="font-size: 15px; font-family: Roboto_Medium"
            :disabled="loading"
            @onClick="closeModal"
          >
            CANCEL
          </base-button>        
          <base-button
            style_variant="primary"
            cus_style="font-size: 15px; font-family: Roboto_Medium;margin-left:0.5rem;"
            :loading="loading"
            :disabled="!isValidEmail || !isValidDays || !isValidName"
            @onClick="submit"
          >
            SUBMIT
          </base-button>
        </b-col>
      </b-row>
  </b-modal>
</template>

<script>


import ApplicationsService from "../../services/applications.service";
import TheSidebar from "../Layout/TheSidebar.vue";
const options = [];

export default {
  name: "ShareApplicationModal",
  components: {
  
  },
  emits: ["refresh-page"],
  props: ["application_data"],
  data() {
    return {
      error: null,
      loading: false,
      status: null,
      clientName:null,
      email: null,
      days: 7,
      validEmail: false,
      validDays: false
    };
  },
  computed: {
    isValidEmail() {
      let valid = /^[^@]+@\S+\.\S+$/.test(this.email);
      return valid;
    },
    isValidDays() {
      return this.days>0 && this.days < 30;
    },

    isValidName() {
      return this.clientName && this.clientName.length > 0;
    }
  }, 
  methods: {
    async submit() {

      let payload = {
        email: this.email,
        days: this.days,
        name: this.clientName
      };


      const status_rec = [];

      if (this.application_data) {
        this.loading = true;
        ApplicationsService.shareApplication(
          this.application_data.id,
          payload
        )
          .then(() => {
            this.$emit("refresh-page");
            this.closeModal();
          })
          .catch(({ message }) => {
            this.error = message
              ? message
              : "There was an error updating status.";
          })
          .finally(() => {
            this.loading = false;
          });
      }

    },
    closeModal() {
      this.$bvModal.hide("share-application-modal");
      this.reset();
    },
    setValue(val, field) {
      this[field] = val;
    },
    reset() {
      this.error = null;
      this.loading = false;
      this.status = null;
      this.validEmail = false;
      this.validDays = false;
    },
  },
  watch: {
    email: function(val) {
      //TODO: Show message under the field
    }
  },
  mounted() {
    this.loading = false;
  },
};
</script>

<style scoped>
#share-application-modal .modal-title {
  font-size: 24px;
  font-family: Roboto_Medium;
  color: #5e595c;
  border-bottom: 2px solid #dfddd9;
  margin-bottom: 2rem;
}
</style>
