<template>
  <b-modal
    id="decline-reason-modal"
    size="md"
    centered
    hide-header
    hide-footer
    @hidden="reset"
  >
    <div class="modal-title">Decline Company</div>
    <b-alert :show="error && error.length ? true : false" variant="danger">
      <div v-html="error || '&nbsp;'"></div>
    </b-alert>
      <b-row>
        <b-col>
         
                <base-select
                  label="Reason"
                  :options="declineOptions"
                  field="reason"
                  :value="reason"
                  @change-value="setValue"
                  :disabled="loading"
                  :is_invalid="errors && errors.length"
                  :helper_text="errors && errors.length ? errors[0] : ''"
                ></base-select>
          </b-col>
      </b-row>      
      <b-row>
        <b-col>
        
            <base-input
              label="Comment"
              placeholder="Comment"
              type="text"
              field="reasonComment"
              :value="reasonComment"
              @change-value="setValue"
              :disabled="loading"
              :is_invalid="errors && errors.length"
              :helper_text="errors && errors.length ? errors[0] : ''"
            ></base-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex align-items-center">
          <base-button
            style_variant="primary"
            cus_style="font-size: 15px; font-family: Roboto_Medium;margin-right:0.5rem;"
            :loading="loading"
            :disabled="loading"
            @onClick="submit"
          >
            SUBMIT
          </base-button>
          <base-button
            style_variant="secondary"
            cus_style="font-size: 15px; font-family: Roboto_Medium"
            :disabled="loading"
            @onClick="closeModal"
          >
            CANCEL
          </base-button>
        </b-col>
      </b-row>
  </b-modal>
</template>

<script>
import CompanyService from "../../services/company.service";
import EnumeratesService from "../../services/enumerates.service";



export default {
  name: "ChangeStatusModal",
  props: ["company_id"],
  emits: ["refresh-page"],
  components: {
  },
  data() {
    return { 
      error: null, 
      loading: false, 
      reason: null,
      reasonComment:null,
      declineOptions: []
     };
  },
  methods: {
    setValue(val, field) {
      this[field] = val;
    },
    async submit() {
      this.loading = true;

      CompanyService.decline(this.company_id, { declined_reason: this.reason, declined_comment:this.reasonComment })
        .then(() => {
          this.$emit("refresh-page");
          this.closeModal();
        })
        .catch(({ message }) => {
          this.error = message
            ? message
            : "There was an error declining company.";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeModal() {
      this.$bvModal.hide("decline-reason-modal");
      this.reset();
    },
    reset() {
      this.error = null;
      this.loading = false;
      this.reason = "";
    },
  },
  mounted() {
    this.querying = true;
    EnumeratesService.decline_options()
      .then((data) => {
        if (data && data.length)
          this.declineOptions = data.map((item) => {
            return {
              id: item.id,
              label: item.value,
            };
          });
      })
      .catch(({ message }) => {
        this.error = message;
      })
      .finally(() => {
        this.declineOptions.unshift({
              id: null,
              label: "Choose reason",
            });        
        this.querying = false;
      });    
  }
};

</script>

<style scoped>
#decline-reason-modal .modal-title {
  font-size: 24px;
  font-family: Roboto_Medium;
  color: #5e595c;
  border-bottom: 2px solid #dfddd9;
  margin-bottom: 2rem;
}
</style>
