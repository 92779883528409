<script setup>
import { watch, reactive, ref, computed, onMounted } from "vue";
import VueMultiselect from "vue-multiselect";
import ColumnForm from "./ColumnForm.vue";
import SettingsCharts from "@/services/settingsChart.service";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useStore } from "vuex";

const store = useStore();

const props = defineProps({
  chartType: {
    required: false,
  },
  industry: {
    required: true,
  },
  detailsData: {
    required: true,
  },
  isNewChart: {
    required: true,
  },
  applicationId: {
    required: true,
  },
  metricOptions: {
    required: true,
  },
});

const state = reactive({
  segment: null,
  chartName: null,
  bucket: null,
  type: null,
  isMetric: false,
  isNewChart: props.isNewChart,
  chartType: props.chartType,
  bucketOption: [],
  visualizationTypeOption: [],
  firstStep: false,
  applicationId: props.applicationId,
  metricOptions: props.metricOptions,
});

const industry = ref(props.industry["industry"]);

const fetchOptions = async (apiCall, stateField, mapKey) => {
  store.commit("chart/update", { field: "loading", value: true });

  try {
    const res = await apiCall();
    state[stateField] = res.map((item) => ({ name: item, [mapKey]: item }));
  } catch (e) {
    console.error(`Error fetching ${stateField}:`, e);
  } finally {
    store.commit("chart/update", { field: "loading", value: false });
  }
};

const updateFormData = () => {
  if (props.detailsData) {
    const { name, segment, is_metric, bucket, type } = props.detailsData;
    state.chartName = name || null;
    state.segment = segment;
    state.isMetric = is_metric || false;
    state.bucket = bucket ? { name: bucket, bucket } : null;
    state.type = type ? { name: type, type } : null;

    fetchOptions(
      () => SettingsCharts.getBucketsOption(industry.value),
      "bucketOption",
      "bucket"
    );
    fetchOptions(SettingsCharts.chartTypes, "visualizationTypeOption", "type");
  }
};

const rules = {
  chartName: { required },
  bucket: { required },
  type: { required },
  isMetric: { required },
};
const v$ = useVuelidate(rules, state);

const handleFirstSubmit = () => {
  v$.value.$touch();
  if (v$.value.$invalid) return;
  state.firstStep = true;
};

const watcher = computed(() => ({
  detailsData: props.detailsData,
  industry: props.industry,
  chartType: props.chartType,
  isNewChart: props.isNewChart,
  metricOptions: props.metricOptions,
}));

watch(
  watcher,
  ({ detailsData, industry, chartType, isNewChart, metricOptions }) => {
    if (detailsData) updateFormData();
    state.chartType = chartType;
    state.isNewChart = isNewChart;
    state.metricOptions = metricOptions;
    if (isNewChart) {
      v$.value.$reset();
      state.firstStep = false;
    }
  },
  { immediate: true, deep: true }
);
const storeSegment = computed(() => store.state.chart.segment);

onMounted(() => {
  updateFormData();
});
</script>

<template>
  <div class="form-container py-4">
    <div>
      <div>Industry: {{ props.industry.name }}</div>
      <div>Segment: {{ state.segment || storeSegment }}</div>

      <input
        type="text"
        class="form-control"
        v-model="state.chartName"
        placeholder="Chart name *"
      />
      <div v-if="v$.chartName.$error" class="error-message">
        {{ v$.chartName.$errors[0]?.$message }}
      </div>

      <div class="py-2">
        <VueMultiselect
          v-model="state.bucket"
          tag-placeholder="Select bucket *"
          placeholder="Select bucket *"
          label="name"
          track-by="bucket"
          :options="state.bucketOption"
          :multiple="false"
        />
        <div v-if="v$.bucket.$error" class="error-message">
          {{ v$.bucket.$errors[0]?.$message }}
        </div>
      </div>

      <div class="py-2">
        <VueMultiselect
          v-model="state.type"
          tag-placeholder="Select type *"
          placeholder="Select type *"
          label="name"
          track-by="type"
          :options="state.visualizationTypeOption"
          :multiple="false"
        />
        <div v-if="v$.type.$error" class="error-message">
          {{ v$.type.$errors[0]?.$message }}
        </div>
      </div>

      <div class="py-2 d-flex align-items-baseline custom-gap">
        <label for="isMetric">Metrics</label>
        <input name="isMetric" type="checkbox" v-model="state.isMetric" />
      </div>
    </div>
    <div class="py-2 d-flex justify-content-center">
      <button class="standard-btn" @click="handleFirstSubmit">Next</button>
    </div>

    <div class="columns-container" v-if="state.firstStep">
      <ColumnForm
        :columns="props.detailsData"
        :industry="industry"
        :formData="state"
        :chartType="state.chartType"
        :metricOptions="state.metricOptions"
      />
    </div>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.error-message {
  font-size: 12px;
  color: red;
}
.custom-gap {
  gap: 8px;
}
.standard-btn {
  height: 36px;
  padding: 8px 10px 8px 10px;
  border-radius: 6px;
  background-color: #024058;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.form-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
}
</style>
