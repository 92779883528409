<template>
  <svg
    width="8"
    height="9"
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="4.02344" r="4"  :fill="color" />
  </svg>
</template>

<script>
export default {
  name: "EllipseSvg",
  props: {
    color: {
      type: String,
      default: "0A7FA3",
    },
  },
};
</script>

<style scoped>
</style>
