const state = {
  loading: false,
  segment:null,
};

const mutations = {
  update(state, { field, value }) {
    state[field] = value;
  },
};

const actions = {
  updateState({ commit }, { field, value }) {
    commit("update", { field, value });
  },
};

const getters = {
  getStateField: (state) => (field) => state[field],  
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};