<template>
  <b-modal
    id="new-company-modal"
    size="md"
    centered
    hide-header
    hide-footer
    @hidden="reset"
  >
    <div class="modal-title">Create Company (1st Application)</div>
      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>

      <b-row>
        <b-col md="12">
         
            <base-input
              label="Work Email Address*"
              placeholder="Enter Work Email Address"
              type="text"
              field="email"
              :value="email"
              @change-value="setValue"
              :disabled="loading"
              :is_invalid="errors && errors.length"
              :helper_text="errors && errors.length ? errors[0] : ''"
              @blur="validateField('myinput')"
            ></base-input>
          </b-col
        ><b-col md="12">
        
            <base-input
              label="First Name"
              placeholder="Enter first name"
              type="text"
              field="first_name"
              :value="first_name"
              @change-value="setValue"
              :disabled="loading"
              :is_invalid="errors && errors.length"
              :helper_text="errors && errors.length ? errors[0] : ''"
            ></base-input>
           </b-col
        ><b-col md="12">
         
            <base-input
              label="Last Name"
              placeholder="Enter last name"
              type="text"
              field="last_name"
              :value="last_name"
              @change-value="setValue"
              :disabled="loading"
              :is_invalid="errors && errors.length"
              :helper_text="errors && errors.length ? errors[0] : ''"
            ></base-input>
          
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
            <base-input
              label="Company Name (Name as registered)*"
              placeholder="Enter Company Name (Name as registered)"
              type="text"
              field="name"
              :value="name"
              @change-value="setValue"
              :disabled="loading"
              :is_invalid="errors && errors.length"
              :helper_text="errors && errors.length ? errors[0] : ''"
            ></base-input>
        </b-col>
        <b-col md="12">
         
            <base-input
              label="Company Website URL*"
              placeholder="Enter Company Website URL"
              type="text"
              field="website"
              :value="website"
              @change-value="setValue"
              :disabled="loading"
              :is_invalid="errors && errors.length"
              :helper_text="errors && errors.length ? errors[0] : ''"
            ></base-input>
        </b-col>
        <b-col md="12">
            <base-input
              label="Mobile Number*"
              placeholder="Enter Mobile Number"
              type="text"
              field="phone"
              :value="phone"
              @change-value="setValue"
              :is_invalid="errors && errors.length"
              :helper_text="errors && errors.length ? errors[0] : ''"
            ></base-input>
        </b-col>
        <b-col md="12">
            <base-select
              label="Place of Operations*"
              :options="country_options"
              field="country"
              :value="country"
              @change-value="setValue"
              :disabled="loading"
              :is_invalid="errors && errors.length"
              :helper_text="errors && errors.length ? errors[0] : ''"
            ></base-select>
        </b-col>
        <b-col md="12">
            <base-select
              label="Currency"
              :options="currency_options"
              field="currency"
              :value="currency"
              @change-value="setValue"
              :disabled="loading"
              :is_invalid="errors && errors.length"
              :helper_text="errors && errors.length ? errors[0] : ''"
            ></base-select>
        </b-col>
        <b-col sm="12">
          <base-input
            label="Image"
            :placeholder="'Upload your image here'"
            type="file"
            accept="image/*"
            field="image"
            :value="image && image.name ? image.name : ''"
            @change-value="setValue"
            :disabled="loading"
          ></base-input>
        </b-col>
        <b-col sm="12">
         
            <base-input
              label="Date of Establishment*"
              placeholder="Enter the Date of Establishment"
              type="date"
              field="established_at"
              :value="established_at"
              @change-value="setValue"
              :disabled="loading"
              :is_invalid="errors && errors.length"
              :helper_text="errors && errors.length ? errors[0] : ''"
            ></base-input>
        </b-col>
        <b-col md="12">
          <base-tags-select
            label="Company Business Type*"
            :options="types_options"
            field="types"
            :value="types"
            @change-value="setValue"
            :disabled="loading"
          />
        </b-col>
        <b-col md="12">
          <base-tags-select
            label="Subscription Management Tool*"
            :options="platform_options"
            field="platforms"
            :value="platforms"
            @change-value="setValue"
            :disabled="loading"
          />
        </b-col>
      </b-row>
    <b-row>
      <b-col
        offset-lg="6"
        lg="6"
        offset-sm="6"
        sm="6"
        class="d-flex align-items-center"
      >
        <base-button
          style_variant="primary"
          cus_style="font-size: 15px; font-family: Roboto_Medium;margin-right:0.5rem;"
          :loading="loading"
          :disabled="loading"
          @onClick="submit"
        >
          SUBMIT
        </base-button>
        <base-button
          style_variant="secondary"
          cus_style="font-size: 15px; font-family: Roboto_Medium"
          :disabled="loading"
          @onClick="closeModal"
        >
          CANCEL
        </base-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>


import EnumeratesService from "../../services/enumerates.service";
import CompanyService from "../../services/company.service";

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default {
  name: "NewCompanyModal",
  emits: ["refresh-page"],
  components: {
  },
  data() {
    return {
      error: null,
      loading: false,
      country_options: [],
      currency_options: [],
      types_options: [],
      platform_options: [],
      email: null,
      first_name: null,
      last_name: null,
      name: null,
      website: null,
      phone: null,
      country: null,
      currency: null,
      image: null,
      established_at: null,
      types: [],
      platforms: [],
    };
  },
  methods: {
    validateField(field) {
      const provider = this.$refs[field];

      return provider.validate();
    },
    async submit() {
      // const valid = await this.$refs.observer.validate();

      // if (!valid) return;

      let payload = {
        name: this.name,
        website: this.website,
        phone: this.phone,
        country: this.country,
        currency: this.currency,
        image:
          this.image && this.image.name ? await toBase64(this.image) : null,
        established_at: this.established_at,
        types: this.types,
        platforms: this.platforms,
        user: {
          email: this.email,
          first_name: this.first_name,
          last_name: this.last_name,
        },
      };

      this.loading = true;
      CompanyService.post(payload)
        .then(() => {
          this.$emit("refresh-page");
          this.closeModal();
        })
        .catch(({ message }) => {
          this.error = message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeModal() {
      this.$bvModal.hide("new-company-modal");
      this.reset();
    },
    setValue(val, field) {
      this[field] = val;
    },
    reset() {
      this.error = null;
      this.loading = false;
      this.email = null;
      this.first_name = null;
      this.last_name = null;
      this.name = null;
      this.website = null;
      this.phone = null;
      this.country = null;
      this.currency = null;
      this.image = null;
      this.established_at = null;
      this.types = [];
      this.platforms = [];
    },
  },
  mounted() {
    this.loading = true;
    EnumeratesService.countries()
      .then((data) => {
        if (data && data.length)
          this.country_options = data.map((item) => {
            return {
              id: item.id,
              label: item.name,
            };
          });
      })
      .catch(({ message }) => {
        this.error = message;
      })
      .finally(() => {
        this.country_options.unshift({
          id: null,
          label: "Choose country",
        });
        EnumeratesService.currencies()
          .then((data) => {
            if (data && data.length)
              this.currency_options = data.map((item) => {
                return {
                  id: item.id,
                  label: item.name,
                };
              });
          })
          .catch(({ message }) => {
            this.error = message;
          })
          .finally(() => {
            this.currency_options.unshift({
              id: null,
              label: "Choose currency",
            });
            EnumeratesService.types({ type: "COMPANY" })
              .then((data) => {
                if (data && data.length)
                  this.types_options = data.map((item) => {
                    return {
                      id: item.id,
                      label: item.name,
                    };
                  });
              })
              .catch(({ message }) => {
                this.error = message;
              })
              .finally(() => {
                EnumeratesService.platforms({ type: "COMPANY" })
                  .then((data) => {
                    if (data && data.length)
                      this.platform_options = data.map((item) => {
                        return {
                          id: item.id,
                          label: item.name,
                        };
                      });
                  })
                  .catch(({ message }) => {
                    this.error = message;
                  })
                  .finally(() => {
                    this.loading = false;
                  });
              });
          });
      });
  },
};
</script>

<style scoped>
#new-company-modal .modal-title {
  font-size: 24px;
  font-family: Roboto_Medium;
  color: #5e595c;
  border-bottom: 2px solid #dfddd9;
  margin-bottom: 2rem;
}
</style>
