import ResourceService from "./resource.service";


export default class MetricService extends ResourceService {
  static get resource() {
    return "admin";
  }

  static getMetrics(params) {
    return MetricService.get(`settings/metrics?${params}`);
  }
  static addMetrics(payload) {
    return MetricService.post(payload,`settings/metrics`);
  }
  static industries() {
    return MetricService.get("settings/industries");
  }

  static getSegmentOption(params) {
    return MetricService.get(`settings/industries/${params}/segments`);
  }
 


}