<template>
  <div class="table-con">
    <div class="header-wrapper">
      <button class="back-btn" @click="handleBack"><img src="/images/arrowleft.svg" alt="" />BACK</button>
      <div class="header">
        Applications /
        <span class="business-name">
          {{ businessName }}
        </span>
      </div>
    </div>

    <div class="tabs-container">
      <div
        class="tabs-wrapper"
        :class="{ active: selectedTab === 'stepOne', disabled: isStepOneDataEmpty }"
        @click="selectTab('stepOne')">
        <div>
          <div class="steps">Step 1</div>
          <div class="titles">Eligibility Check</div>
        </div>
        <img :src="!isStepOneDataEmpty ? '/images/tick.svg' : '/images/tickinactive.svg'" alt="" />
      </div>
      <div
        class="tabs-wrapper"
        :class="{ active: selectedTab === 'stepTwo', disabled: isStepTwoDataEmpty }"
        @click="selectTab('stepTwo')">
        <div>
          <div class="steps">Step 2</div>
          <div class="titles">Orders System</div>
        </div>
        <img :src="!isStepTwoDataEmpty ? '/images/tick.svg' : '/images/tickinactive.svg'" alt="" />
      </div>

      <div
        class="tabs-wrapper"
        :class="{ active: selectedTab === 'stepThree', disabled: isStepThreeDataEmpty }"
        @click="selectTab('stepThree')">
        <div>
          <div class="steps">Step 3</div>
          <div class="titles">Accounting Documents</div>
        </div>
        <img :src="!isStepThreeDataEmpty ? '/images/tick.svg' : '/images/tickinactive.svg'" alt="" />
      </div>
      <div
        class="tabs-wrapper"
        :class="{ active: selectedTab === 'stepFour', disabled: isStepFourDataEmpty }"
        @click="selectTab('stepFour')">
        <div>
          <div class="steps">Step 4</div>
          <div class="titles">Financial Documents</div>
        </div>
        <img :src="!isStepFourDataEmpty ? '/images/tick.svg' : '/images/tickinactive.svg'" alt="" />
      </div>
    </div>

    <div v-if="selectedTab === 'stepOne' && stepOneData">
      <stepOne :data="stepOneData" />
    </div>
    <div v-else-if="selectedTab === 'stepTwo'">
      <stepTwo :data="stepTwoData" />
    </div>
    <div v-else-if="selectedTab === 'stepThree'">
      <stepThree :data="stepThreeData" />
    </div>
    <div v-else-if="selectedTab === 'stepFour'">
      <stepFour :data="stepFourData" />
    </div>
  </div>
</template>
<script>
import ApplicationsService from "../../services/applications.service";
import OnboardingService from "@/services/onboarding.service";
import stepOne from "./stepOne.vue";
import stepTwo from "./stepTwo.vue";
import stepThree from "./stepThree.vue";

import stepFour from "./stepFour.vue";

export default {
  components: { stepOne, stepTwo, stepThree, stepFour },
  data() {
    return {
      id: null,
      data: {},
      selectedTab: "stepOne",
      stepOneData: {},
      stepTwoData: {},
      stepThreeData: {},
      stepFourData: {},
      order: null,
      balanceSheet: null,
      companyCapTable: null,
      companyRegistration: null,
      monthlyManagement: null,
      statement2021: null,
      statement2022: null,
      businessName: null,
    };
  },
  methods: {
    handleBack() {
      this.$router.push(`/OnboardingApplication`);
    },
    selectTab(tab) {
      this.selectedTab = tab;
    },
    openS3(file) {
      const fileValue = this[file];
      if (!fileValue) return;
      let payload = { file: fileValue };
      OnboardingService.download(payload)
        .then(({ data }) => {
          if (data) {
            window.open(data, "_blank");
          }
        })
        .catch(({ message }) => {});
    },
    isStepDataEmpty(stepData) {
      return !stepData || Object.keys(stepData).length === 0;
    },
  },
  mounted() {
    this.id = this.$route.params.id;

    OnboardingService.userDetails(this.id)
      .then((data) => {
        this.data = data;
        this.stepOneData = data;
        if (data.eligibility_step?.businessName) {
          this.businessName = data.eligibility_step?.businessName;
        }
        this.stepTwoData = data.transactions_step;
        let financials_step = data.financials_step;
        this.stepThreeData = data.financials_step;
        this.stepFourData = financials_step;
        if (data.transactions_step?.order) {
          this.order = data.transactions_step?.order;
        }
        this.balanceSheet = financials_step.balanceSheet;
        this.companyCapTable = financials_step.companyCapTable;
        this.companyRegistration = financials_step.companyRegistration;
        this.monthlyManagement = financials_step.monthlyManagement;
        this.statement2021 = financials_step.statement2021;
        this.statement2022 = financials_step.statement2022;
      })
      .catch((e) => {
        console.log("error:", e);
      });
  },
  computed: {
    isStepOneDataEmpty() {
      return this.isStepDataEmpty(this.stepOneData);
    },
    isStepTwoDataEmpty() {
      return this.isStepDataEmpty(this.stepTwoData);
    },
    isStepThreeDataEmpty() {
      return this.isStepDataEmpty(this.stepThreeData);
    },
    isStepFourDataEmpty() {
      return this.isStepDataEmpty(this.stepFourData);
    },
  },
};
</script>
<style scoped>
.titles {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #024058;
}

.steps {
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  color: #0a7fa3;
  padding-bottom: 5px;
}

.tabs-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.tabs-wrapper {
  padding: 8px 16px 8px 16px;
  gap: 16px;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  width: 100%;
}

.business-name {
  color: #071015;
  font-weight: 700;
}

.header-wrapper {
  display: flex;
  align-items: center;
  gap: 32px;
}

.back-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9e9ea;
  padding: 8px 10px 8px 10px;
  border: none;
  border-radius: 6px;
  gap: 6px;
  font-size: 14px;
  font-weight: 700;
  line-height: 15.4px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #394044;
}

.navs {
  border-bottom-color: transparent;
}

.header {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: #83888a;
  padding: 20px 0px 20px 0px;
}

.active-tab {
  color: red;
}

.stepone {
  display: flex;
  align-items: center;
  gap: 20px;
}

.custom-tab {
  cursor: pointer;
}

.custom-tab1 {
  cursor: pointer;
  color: blue;
}

.table-con {
  padding: 30px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.nav-tabs .nav-link.active {
  color: #024058;
  background-color: transparent;
  border: none;
  font-size: 16px;
  border-bottom: 5px solid #b4e7e2;
  padding: 8px 0px 8px 0px;
}

.nav-tabs .nav-link {
  color: #394044;
  border-bottom: 5px solid #cdcfd0;
  padding: 8px 0px 8px 0px;
  margin-left: 40px;
}

.active {
  background-color: #daf3f1;
}

.tabs-wrapper.disabled .steps,
.tabs-wrapper.disabled .titles {
  color: #cdcfd0;
}

.disabled {
  pointer-events: none;
}
</style>
