<template>
  <b-modal
    id="new-notification-modal"
    size="lg"
    centered
    hide-header
    hide-footer
  >
    <div class="modal-title">New Notification</div>
    <b-alert :show="error && error.length ? true : false" variant="danger">
      <div v-html="error || '&nbsp;'"></div>
    </b-alert>
    <p>Please select the notification type, and message from below</p>
    <b-row>
      <b-col lg="6" sm="12">
        <base-select
          label="Notification Message"
          :options="notificationOptions"
          field="notificationType"
          :value="notificationType"
          @change-value="setValue"
        >
        </base-select>
      </b-col>
    </b-row>
    <b-row v-if="notificationType != null">
      <b-col lg="10" sm="12">
        <textarea class="form-control" v-model="notificationText"></textarea>
      </b-col>
    </b-row>
    <b-row
      v-if="notificationType == 'ApplicationQualified'"
      style="margin-top: 10px"
    >
      <b-col lfg="12" sm="12">
        <div class="form-label labelText">Offer Id</div>
        <input
          type="text"
          v-model="offerId"
          id="offerId"
          class="form-control"
        />
      </b-col>
    </b-row>
    <b-row
      v-if="notificationType == 'ApplicationReportsReady'"
      style="margin-top: 10px"
    >
      <b-col lfg="12" sm="12">
        <div class="form-label labelText">Report Id</div>
        <input
          type="text"
          v-model="reportId"
          id="offerId"
          class="form-control"
        />
      </b-col>
    </b-row>
    <b-row v-if="showAllow" style="margin-top: 10px">
      <b-col lfg="12" sm="12">
        <input
          type="checkbox"
          v-model="allowApply"
          id="allowMoreCheckbox"
          :disabled="notificationType == 'CompanyMoreFunding'"
        />
        <label for="allowMoreCheckbox">&nbsp;Allow New application</label>
      </b-col>
    </b-row>
    <b-row v-if="allowApply" style="margin-top: 10px">
      <b-col lg="12" sm="12">
        <base-select
          label="Fields to update"
          :options="availableFields"
          @change-value="setFieldsValue"
        >
        </base-select>

        <div v-for="field in selectedFields" :key="field.id" :value="field.id">
          {{ field.label }} <a @click="moveBack(field.id)">x</a>
        </div>
      </b-col>
    </b-row>

    <b-row style="margin-top: 30px">
      <b-col lg="6" sm="6">
        <base-button
          style_variant="secondary"
          cus_style="font-size:15px"
          @onClick="closeModal"
        >
          Cancel
        </base-button>
      </b-col>
      <b-col lg="6" sm="6">
        <base-button
          style_variant="primary"
          cus_style="font-size:15px"
          @onClick="handleSubmit"
        >
          Confirm
        </base-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
const updates = [
  {
    id: null,
    label: "Select update type to send",
  },
  {
    id: "ApplicationFunded",
    label: "Application Funded",
    subTitleMessage:
      "Congratulations! Your application has been successfully funded with $50,000. We are excited to support your growth and look forward to seeing your progress.",
  },
  {
    id: "ApplicationGathering",
    label: "Welcome to Capifly",
    subTitleMessage:
      "We are currently gathering all necessary information for your application. Our data team will be in touch with you shortly.",
  },
  {
    id: "ApplicationScreening",
    label: "Screening in Progress",
    subTitleMessage:
      "Your application is under review by our team. We will update you with the results shortly. Thank you for your patience.",
  },
  {
    id: "ApplicationCompleted",
    label: "Application Completed",
    subTitleMessage:
      "Your application process is now complete. Thank you for partnering with Capifly. We wish you continued success in your endeavors..",
  },
  {
    id: "ApplicationCanceled",
    label: "Application Canceled",
    subTitleMessage:
      "Your application process is now complete. Thank you for partnering with Capifly. We wish you continued success in your endeavors.",
  },
  {
    id: "ApplicationQualified",
    label: "Qualified for Funding",
    subTitleMessage:
      "Congratulations! Your application has been qualified for funding. Please download the offer letter and approve the offer to proceed.",
  },
  {
    id: "ApplicationNotQualified",
    label: "Application Not Qualified",
    subTitleMessage:
      "Congratulations! Your application has been qualified for funding. Please download the offer letter and approve the offer to proceed.",
  },
  {
    id: "ApplicationExecution",
    label: "Execution Phase",
    subTitleMessage:
      "Congratulations! Your application has been qualified for funding. Please download the offer letter and approve the offer to proceed.",
  },
  {
    id: "ApplicationSubmitted",
    label: "Application Submitted",
    subTitleMessage:
      "Your application has been submitted successfully. Check the status or start a new application.",
  },
  {
    id: "ApplicationUpdate",
    label: "Update Your Data",
    subTitleMessage:
      "Some data or documents need updating based on the latest request from the Capifly admin. Please review the details and update the necessary information or documents.",
  },
  {
    id: "ApplicationReportsReady",
    label: "Financial Report Ready for Download",
    subTitleMessage:
      "Your financial report is now ready for download. You can download the report to review all the financial details.",
  },
  {
    id: "CompanyMoreFunding",
    label: "Eligible for More Funding",
    subTitleMessage:
      "Congratulations! Your company is now eligible for more funding. You can apply for additional funds to further grow your business.",
  },
];
//TODO: This should be implied by backend
var AllFields = [
  {
    id: 0,
    label: "Select fields type to send",
  },
  { id: "businessName", label: "Business name" },
  { id: "businessWebsite", label: "Business website" },
  { id: "incorporationDate", label: "Date of incorporation" },
  { id: "incorporationCountry", label: "Country of incorporation (HQ" },
  { id: "placesOfOperation", label: "Places of operation" },
  { id: "industry", label: "Industry" },
  { id: "companyStage", label: "Company stage" },
  {
    id: "institutionalInvestors",
    label: "Number of institutional investors in the capital",
  },
  { id: "activeContracts", label: "Number of active users" },
  { id: "shariaCompliant", label: "Shariah compliant facility preference" },
  { id: "annualRevenue", label: "ARR" },
  { id: "requestedAmount", label: "Requested amount " },
  { id: "debtAmount", label: "Debt amount" },
  { id: "investmentRounds", label: "Number of investment rounds to date" },
  { id: "raisedAmount", label: "Amount raised" },
  { id: "runwayMonths", label: "Runway in months" },
  { id: "paymentSystem", label: "Payment System" },
  { id: "docCapTable", label: "Cap Table" },
  { id: "docMonthlyManagement", label: "Monthly Management Account" },
  { id: "docRegistration", label: "Registration Certificate" },
  {
    id: "docStatementPreviousYear",
    label: "Audited financial statement for last year",
  },
  {
    id: "docStatementPreviousTwoYears",
    label: "Audited financial statement for the year before last year",
  },
];

import ApplicationsService from "@/services/applications.service";
export default {
  name: "new-notification-modal",
  props: ["application_id"],
  emits: ["new-notification"],

  data() {
    return {
      error: null,
      loading: false,
      label: null,
      notificationOptions: updates,
      notificationType: null,
      notificationText: "",
      allowApply: false,
      showAllow: false,
      offerId: "6fa9660d-4264-410c-bd68-0535c5d3fdf1",
      reportId: "0b527839-cac6-4269-b7b3-0897996ed1dc",
      availableFields: AllFields,
      selectedFields: [],
    };
  },

  mounted() {
    this.notificationType = null;
    this.notificationText = "";
    this.allowApply = false;
    this.showAllow = false;
    this.availableFields = AllFields;
    this.selectedFields = [];
  },

  methods: {
    closeModal() {
      this.$bvModal.hide("new-notification-modal");
    },
    async handleSubmit() {
      const payload = {
        type: this.notificationType,
        text: this.notificationText,
        allowApply: this.allowApply,
        offerId: this.offerId,
        reportId: this.reportId,
        updateFields: this.selectedFields.map((o) => o.id),
      };
      try {
        await ApplicationsService.sendUpdate(payload, this.application_id);
        this.$emit("new-notification", true);
      } catch (e) {
        this.$emit("new-notification", false);
      } finally {
        this.closeModal();
      }
    },
    setValue(val, field) {
      this[field] = val;
      let selectedOption = this.notificationOptions.filter(
        (obj) => obj.id == val
      );
      this.notificationText = selectedOption[0].subTitleMessage;
      this.showAllow = [
        "ApplicationCompleted",
        "ApplicationNotQualified",
        "ApplicationUpdate",
        "CompanyMoreFunding",
      ].includes(val);

      this.allowApply = val == "CompanyMoreFunding";
    },

    setFieldsValue(val, field) {
      if (val == null || val == 0) return;
      const idx = this.availableFields.map((o) => o.id).indexOf(val);

      const targetObj = this.availableFields.splice(idx, 1);
      this.selectedFields.push(targetObj[0]);
    },
    moveBack(id) {
      const idx = this.selectedFields.map((o) => o.id).indexOf(id);
      const targetObj = this.selectedFields.splice(idx, 1);
      this.availableFields.push(targetObj[0]);
    },
  },
};
</script>

<style scoped>
.modal-title {
  font-size: 24px;
  font-family: Roboto_Medium;
  color: #5e595c;
  border-bottom: 2px solid #dfddd9;
  margin-bottom: 2rem;
}
.cursor-grabbing {
  cursor: grabbing !important;
}

.draggable-list > div {
  cursor: pointer;
}
</style>
