<template>
  <div class="section-two-wrapper box">
    <div class="box-25 facility-box">
      <div class="header-title">Facility Extended</div>
      <div class="header-amount">
        {{ formatNumber(facilityExtendedAmount) }}
      </div>
    </div>
    <div class="box-inside box-75">
      <div class="header">
        <div class="header-title">@ Sales Price</div>
        <div class="header-value">
          {{
            formatNumber(parseInt(repaidAmount) + parseInt(outstandingAmount))
          }}
        </div>

        <v-chart
          class="chartProgress"
          :option="progressBarOptions"
          autoresize
        />
        <div class="info-wrapper">
          <div class="info">
            <EllipseSvg color="#0A7FA3" />

            <div class="info-title">Repaid:</div>
            <div class="info-amount">{{ formatNumber(repaidAmount) }}</div>
          </div>
          <div class="info">
            <EllipseSvg color="#B4E7E2" />
            <div class="info-title">Outstanding:</div>
            <div class="info-amount">{{ formatNumber(outstandingAmount) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

import EllipseSvg from "@/components/common/ellipseSvg.vue";
import { formatNumber } from "@/utils/numberUtils";

export default {
  name: "FacilityExtended",
  components: { VChart, EllipseSvg },
  props: ["facilityExtendedAmount", "repaidAmount", "outstandingAmount"],
  data() {
    return {
      progressBarOptions: {
        grid: {
          top: "0",
          left: "0",
          bottom: "0",
          right: "0",
          containLabel: false,
        },
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            const formattedValue = this.formatNumber(params.value);
            return `${params.seriesName}: ${formattedValue}`;
          },
        },
        xAxis: {
          type: "value",
          show: false,
        },
        yAxis: {
          type: "category",
          show: false,
        },
        series: [
          {
            name: "Repaid",
            type: "bar",
            stack: "total",
            barWidth: 20,
            itemStyle: {
              color: "#0A7FA3",
              borderRadius: [15, 0, 0, 15],
            },
            emphasis: {
              itemStyle: {
                color: "#0A7FA3",
              },
            },
            data: [parseInt(this.repaidAmount)],
          },
          {
            name: "Outstanding",
            type: "bar",
            stack: "total",
            barWidth: 30,
            itemStyle: {
              color: "#DAF3F1",
              borderRadius: [0, 15, 15, 0],
            },
            emphasis: {
              itemStyle: {
                color: "#DAF3F1",
              },
            },
          
            data: [parseInt(this.outstandingAmount)],
          },
        ],
      },
    };
  },
  methods: {
    formatNumber,
  },
  mounted() {},
};
</script>

<style scoped>
.facility-box {
  padding: 24px !important;
  border-right: 1px solid #e9e9ea;
  min-height: 200px;
}
.box {
  padding: 24px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px #0000001a;
}
.box-inside {
  padding: 24px;
  background: #ffffff;
  border-radius: 8px;
}

.box-75 {
  flex: 0 0 70%;
}

.header-value {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #071015;
  margin-top: 8px;
}

.box-25 {
  flex: 0 0 30%;
  padding: 0;
}
.header-amount {
  font-size: 32px;
  font-weight: 700;
  line-height: 35.2px;
  color: #071015;
  margin-top: 16px;
}
.header-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: #071015;
}
.info-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  color: #071015;
}
.info-amount {
  font-size: 14px;
  font-weight: 700;
  line-height: 18.2px;
  color: #071015;
}
.info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
}
.chartProgress {
  height: 80px;
}
.section-two-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: self-start;
}

.info-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 16px;
}
</style>
