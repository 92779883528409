<template>
  <div class="px-4 d-flex h-500">
    <div ref="chart" class="chart"></div>
    <div class="content">
      <div class="funnel-info-container">
        <div class="funnel-info">
          <EllipseSvg color="#91CC75" />
          <div class="info-title">Total applications:</div>
          <div class="info-amount">{{ totalApplications }}</div>
        </div>
        <div class="funnel-info-value">
          Requested Amount:
          <span class="amount">{{ formatNumber(requestedAmount) }}</span>
        </div>
      </div>

      <div class="funnel-info-container">
        <div class="funnel-info">
          <EllipseSvg color="#EE6666" />
          <div class="info-title">Eligible:</div>
          <div class="info-amount">{{ eligibleApplications }}</div>
        </div>
        <div class="funnel-info-value">
          Eligible Amount:
          <span class="amount">{{ formatNumber(eligibleAmount) }}</span>
        </div>
      </div>
      <div class="funnel-info-container">
        <div class="funnel-info">
          <EllipseSvg color="#0A7FA3" />
          <div class="info-title">Qualified:</div>
          <div class="info-amount">{{ qualifiedApplications }}</div>
        </div>
        <div class="funnel-info-value">
          Approved Amount:
          <span class="amount">{{ formatNumber(qualifiedAmount) }}</span>
        </div>
      </div>
      <div class="funnel-info-container">
        <div class="funnel-info">
          <EllipseSvg color="#FAC858" />
          <div class="info-title">Funded:</div>
          <div class="info-amount">{{ fundedApplications }}</div>
        </div>
        <div class="funnel-info-value">
          Funded Amount:
          <span class="amount">{{ formatNumber(fundedAmount) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import EllipseSvg from "@/components/common/ellipseSvg.vue";
import { formatNumber } from "@/utils/numberUtils";

export default {
  name: "Funnel",
  components: { EllipseSvg },
  props: [
    "totalApplications",
    "eligibleApplications",
    "qualifiedApplications",
    "fundedApplications",
    "requestedAmount",
    "eligibleAmount",
    "qualifiedAmount",
    "fundedAmount",
  ],
  data() {
    return {};
  },
  methods: {
    formatNumber,
    initChart() {
      const chartDom = this.$refs.chart;
      const myChart = echarts.init(chartDom);
      const option = {
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            return `${params.name}: ${params.data.actualValue}`;
          },
        },
        series: [
          {
            type: "funnel",
            left: "10%",
            top: 60,
            bottom: 60,
            width: "80%",
            min: 0,
            max: 100,
            minSize: "0%",
            maxSize: "100%",
            sort: "descending",
            gap: 2,
            label: {
              show: true,
              position: "inside",
              verticalAlign: "top",
              padding: [0, 0, 0, 0],
              formatter: function (params) {
                if (params.name === "Funded") {
                  return "Funded" + "\n" + "\n" + "\n" + "  ";
                } else {
                  return params.name;
                }
              },
            },
            itemStyle: {
              color: ["#5470c6", "#91cc75", "#ee6666", "#fac858"],
              borderColor: "#fff",
              borderWidth: 1,
            },
            emphasis: {
              label: {
                fontSize: 20,
              },
            },
            data: [
              {
                value: 80,
                name: "Total Applications",
                actualValue: parseInt(this.totalApplications),
                itemStyle: {
                  color: "#91CC75",
                },
              },
              {
                value: 60,
                name: "Eligible",
                actualValue: parseInt(this.eligibleApplications),
                itemStyle: {
                  color: "#EE6666",
                },
              },
              {
                value: 40,
                name: "Qualified",
                actualValue: parseInt(this.qualifiedApplications),
                itemStyle: {
                  color: "#0A7FA3",
                },
              },
              {
                value: 20,
                name: "Funded",
                actualValue: parseInt(this.fundedApplications),
                itemStyle: {
                  color: "#FAC858",
                },
                label: {
                  show: true,
                },
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
    },
  },
  mounted() {
    this.initChart();
  },
};
</script>

<style scoped>
.amount {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #071015;
}
.funnel-info-value {
  padding: 4px 16px 4px 16px;
  border-radius: 8px;
  background-color: #e9f8f6;
  font-size: 14px;
  font-weight: 700;
  line-height: 18.2px;
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}
.chart {
  flex: 6;
}

.h-500 {
  height: 500px;
}
.content {
  flex: 4;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 60px 8px 0px 8px;
  gap: 16px;
  border-radius: 8px;
}
.funnel-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}
.funnel-info-container {
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px #0000001a;
}
.info-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  color: #071015;
}
.info-amount {
  font-size: 14px;
  font-weight: 700;
  line-height: 18.2px;
  color: #071015;
}
</style>
