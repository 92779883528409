<template>
  <div id="integration-settings">
    <div class="top-container">
      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>
      <div class="pagination-container" v-if="total_count">
        <base-pagination :active_page="active_page" :total_page="total_page" :total_count="total_count"
                         :page_size="page_size" @set-page="setPage" />
      </div>
    </div>
    <div v-if="loading">
      <common-loading />
    </div>
    <div class="col-4 align-items-center">
      <base-button style_variant="primary" cus_style="font-size:15px" @onClick="openAddModal">
        Add Service Config
      </base-button>
    </div>
    <div class="content-table" v-if="serviceConfigs && serviceConfigs.length > 0">
      <div class="table-container">
        <b-row class="table-title">
          <b-col class="table-title-field">
            <div class="table-title-text">Service Name</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">Validation Keys</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">Configuration</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">Actions</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
        </b-row>
        <b-row class="table-data" v-for="config in serviceConfigs" :key="config.id">
          <b-col>
            <div class="com-name">{{ config.service_name }}</div>
          </b-col>
          <b-col>
            <VJsoneditor v-model="config.validation_keys" :options="{ mode: 'view' }" />
          </b-col>
          <b-col>
            <VJsoneditor v-model="config.configuration" :options="{ mode: 'view' }" />
          </b-col>
          <b-col>
            <div class="action-container" @click.stop="openMenu(config.id)">
              <img :src="openMenuFlag === config.id ? '/images/eye-icon.png' : '/images/dot-icon.png'"
                   :alt="openMenuFlag === config.id ? 'eye-icon' : 'dot-icon'" />
            </div>
            <div class="action-menu" v-if="openMenuFlag === config.id">
              <div class="action-menu-item" @click="openEditModal(config)">Edit</div>
              <div class="action-menu-item" @click="deleteServiceConfig(config.id)">Delete</div>
            </div>
          </b-col>
        </b-row>

      </div>
    </div>
    <div class="bottom-container">
      <div class="pagination-container" v-if="total_count">
        <base-pagination :active_page="active_page" :total_page="total_page" :total_count="total_count"
                         :page_size="page_size" @set-page="setPage" />
      </div>
    </div>
    <ServiceConfigModal v-if="showModal" :selectedConfig="selectedConfig || {}" @close="closeModal" @save="saveServiceConfig" />
  </div>
</template>

<script>
import axios from "axios";
import ServiceConfigModal from "./ServiceConfigModal.vue";
import VJsoneditor from 'v-jsoneditor';
import {JWTService} from "@/storage/index.js";
const integration_url = import.meta.env.VITE_INTEGRATION_URL

export default {
  name: "IntegrationSettings",
  components: { ServiceConfigModal, VJsoneditor },
  data() {
    return {
      serviceConfigs: [],
      loading: false,
      error: null,
      showModal: false,
      selectedConfig: null,
      openMenuFlag: null,
      page_size: 10,
      active_page: 1,
      total_page: 0,
      total_count: 0,
    };
  },
  methods: {
    fetchServiceConfigs() {
      this.loading = true;
      axios
          .get(`${integration_url}/service-configs`, {
            headers: {
              Authorization: `Bearer ${JWTService.getToken()}`
            }
          })
          .then((response) => {
            this.serviceConfigs = response.data;
            this.total_count = response.data.length;
            this.total_page = Math.ceil(this.total_count / this.page_size);
          })
          .catch((error) => {
            this.error = error.response.data.error || "Error fetching data";
          })
          .finally(() => {
            this.loading = false;
          });
    },
    openAddModal() {
      this.selectedConfig = null;
      this.showModal = true;
    },
    openEditModal(config) {
      this.selectedConfig = { ...config };
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    saveServiceConfig(config) {
      if (config.id) {
        axios
            .put(`${integration_url}/service-configs/${config.id}/`, config,{
              headers: {
                Authorization: `Bearer ${JWTService.getToken()}`
              }
            })
            .then(() => {
              this.fetchServiceConfigs();
              this.closeModal();
            })
            .catch((error) => {
              this.error = error.response.data.error || "Error saving data";
            });
      } else {
        axios
            .post(`${integration_url}/service-configs`, config,{
              headers: {
                Authorization: `Bearer ${JWTService.getToken()}`
              }
            })
            .then(() => {
              this.fetchServiceConfigs();
              this.closeModal();
            })
            .catch((error) => {
              this.error = error.response.data.error || "Error saving data";
            });
      }
    },
    deleteServiceConfig(id) {
      axios
          .delete(`${integration_url}/service-configs/${id}`,{
            headers: {
              Authorization: `Bearer ${JWTService.getToken()}`
            }
          })
          .then(() => {
            this.fetchServiceConfigs();
          })
          .catch((error) => {
            this.error = error.response.data.error || "Error deleting data";
          });
    },
    openMenu(id) {
      this.openMenuFlag = this.openMenuFlag === id ? null : id;
    },
    setPage(page) {
      this.active_page = page;
      this.updateFilteredData();
    },
    updateFilteredData() {
      const start = (this.active_page - 1) * this.page_size;
      const end = start + this.page_size;
      this.filteredData = this.serviceConfigs.slice(start, end);
    },
  },
  mounted() {
    this.fetchServiceConfigs();
  },
};
</script>

<style scoped>
#integration-settings {
  padding: 1rem 1rem;
}

.top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0rem;
}

.content-table {
  margin-top: 1.5rem;
  box-shadow: 1px 2px 29px #1717241a;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem;
}

.table-container {
  padding: 0.5rem;
}

.table-title {
  background-color: #f5f3ef;
  border-radius: 5px;
  padding: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}

.table-title-field {
  display: flex;
  align-items: center;
  border-right: 1px solid #e9e6e3;
}

.sort-icon {
  height: 10px;
  margin-left: 0.5rem;
  cursor: pointer;
}

.table-title-text {
  font-size: 11px;
  font-family: Roboto_Bold;
  color: #5e595c;
}

.table-data {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem;
  box-shadow: 0px 1px 4px #17172429;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
}

.com-name {
  font-size: 15px;
  font-family: Roboto_Bold;
}

.action-container {
  height: 30px;
  width: 30px;
  margin-left: 0.5rem;
  border-radius: 8px;
  background-color: #dfddd9;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-menu {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px #17172429;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 12px;
  z-index: 99999;
}

.action-menu-item {
  cursor: pointer;
  color: #260b19;
  font-size: 13px;
}

.bottom-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}
</style>