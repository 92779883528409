import { createStore } from "vuex";
import auth from "@/store/auth.module.js";
import onboarding from "@/store/onboarding.moudule.js";
import chart from "./createChart.module";
import application from "./application.module";

const store = createStore({
  modules: {
    auth,
    onboarding,
    chart,
    application
  },
});

export default store;
