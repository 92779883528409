<template>
  <button
    :class="[
      'btn',
      {
        primaryBtn: style_variant === 'primary',
        secondaryBtn: style_variant === 'secondary',
      },
    ]"
    :type="type"
    @click="onClick"
    :style="cus_style"
    :disabled="disabled || loading"
  >
    <div class="d-flex justify-content-center align-items-center">
      <b-spinner small v-if="loading" style="margin-right: 0.5rem"></b-spinner>
      <slot></slot>
    </div>
  </button>
</template>

<script>
export default {
  emits: ["onClick"],
  props: ["type", "style_variant", "cus_style", "loading", "disabled"],
  methods: {
    onClick() {
      this.$emit("onClick");
    },
  },
};
</script>

<style scoped>
.btn {
  border-radius: 6px;
  text-transform: capitalize;
  padding: 10px 20px;
  width: -webkit-fill-available;
  cursor: pointer;
  font-family: Roboto_Regular;
}
.btn:focus {
  box-shadow: none;
}
.primaryBtn {
  background-color: #0A7FA3;
    color: #B4E7E2;
    border: 1px solid #0A7FA3;
}
.primaryBtn:hover {
  background-color: #024058;
  color: #B4E7E2;

}
.primaryBtn:disabled {
  opacity: 0.3;
}
.secondaryBtn {
  background-color: #0A7FA3;
    color: #B4E7E2;
    border: 1px solid #0A7FA3;
}
.secondaryBtn:hover {
  background-color: #024058;
  color: #B4E7E2;

}
.secondaryBtn:disabled {
  opacity: 0.3;
}
</style>
