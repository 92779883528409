import * as Vue from 'vue';
import Client from "@/services/api/api.service"
class AuthService {
  static login(payload) {
    const url = "admin/login";

    return Client.post(url, payload);
  }

  static forgotPassword(payload) {
    const url = "admin/forgot-password";

    return Client.post(url, payload);
  }

  static resetPassword(slug, payload) {
    const url = `admin/reset-password/${slug}`;

    return Client.post(url, payload);
  }

  static verifyResetPasswordSlug(slug) {
    const url = `admin/reset-password/${slug}`;

    return Client.get(url);
  }
}

export default AuthService;
