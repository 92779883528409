<template>
  <div class="filter-section-wrapper">
    <div
      class="d-flex justify-content-between align-items-center py-4 px-2 mx-4"
    >
      <div class="filter-title">Insights Into Applications</div>
      <div id="date-range-container" class="d-flex">
        <date-range-picker opens="left" v-model="dateRange">
          <template #input="picker">
            <span v-if="picker.startDate && picker.endDate">
              {{ formatDate(picker.startDate) }} -
              {{ formatDate(picker.endDate) }}
            </span>
            <span v-else> Start - End </span>
            <img src="/images/icon _arrow down 1_.svg" alt="up" />
          </template>
        </date-range-picker>
      </div>
    </div>
    <div class="funnel-wrapper">
      <div class="title p-4">By Stage</div>
      <div>
        <FunnelStages
          totalApplications="197"
          eligibleApplications="79"
          qualifiedApplications="22"
          fundedApplications="16"
          requestedAmount="89300000"
          eligibleAmount="16500000"
          qualifiedAmount="3700000"
          fundedAmount="2000000"
        />
      </div>
    </div>
    <div class="pt-4">
      <div class="funnel-wrapper">
        <div class="map-wrapper">
          <Map  jordanApp="61" saudiApp="31" egyptApp="13" uaeApp="32" palestine="4" pakistan="2" ukApp="4" lebanon="1" nigeria="2" usa="7" korea="1" oman="3" tunisia="1" morocco="2" />
        </div>
      </div>
    </div>
    <div>
      <div><CompanyStage :stages="stages" :categories="categories" /></div>
    </div>
  </div>
</template>

<script>
import Map from "./map.vue";
import FunnelStages from "./funnelStages.vue";
import CompanyStage from "./companyStage.vue";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "DashboardPage",
  components: { Map, FunnelStages, CompanyStage, DateRangePicker },

  data() {
    return {
      adminName: "Admin",
      unutilized: "$205k",
      dateRange: { startDate: null, endDate: null },
      categories: [
        { value: 74, name: "Software" },
        // { value: 1, name: "Content" },
        { value: 47, name: "B2B" },
        { value: 22, name: "E-commerce" },
        { value: 18, name: "B2C" },
        { value: 13, name: "Fin-tech" },
        { value: 12, name: "Education" },
        { value: 10, name: "Marketplace" },
        { value: 6 ,name: "Health" },
        { value:7 ,    name:"Others"}
      ],
      stages: [
        { value: 62, name: "Pre-seed" },
        { value: 57, name: "Seed" },
        { value: 10, name: "Post-Series A" },
        { value: 8, name: "Series A" },
        { value: 5, name: "Series B" },
      ],
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD MMMM YYYY");
    },
  },
  mounted() {},
};
</script>

<style scoped>
.filter-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 20.8px;
  color: #071015;
}
.funnel-wrapper {
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px #0000001a;
  margin-right: 24px;
  margin-left: 24px;
}
.filter-section-wrapper {
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 0px 0px 5px 0px #0000001a;
}
.title {
  font-size: 18px;
  font-weight: 400;
  line-height: 23.4px;
  color: #071015;
  padding-bottom: 0px !important;
}
</style>
