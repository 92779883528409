<template>
  <div>
    <div class="form-label labelText" v-if="label">
      {{ label }}
    </div>
    <b-form-group>
      <b-form-tags
        id="tags-component-select"
        :class="[{ invalidInput: is_invalid ? true : false }]"
        v-model="inputfield"
        size="lg"
        class="mb-2"
        add-on-change
        no-outer-focus
      >
        <template
          v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }"
        >
          <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
            <li v-for="tag in tags" :key="tag" class="list-inline-item">
              <b-form-tag
                @remove="removeTag(tag)"
                :title="tag"
                :disabled="disabled"
                variant="info"
                >{{ tag }}</b-form-tag
              >
            </li>
          </ul>
          <b-form-select
            v-if="availableOptions && availableOptions.length"
            v-bind="inputAttrs"
            v-on="inputHandlers"
            :options="availableOptions"
          >
            <template #first>
              <option disabled value="" v-if="label">
                Choose a
                {{
                  label.substr(0, label.length - 1).toLocaleLowerCase()
                }}
              </option>
              <option disabled value="" v-else>Choose a tag</option>
            </template>
          </b-form-select>
        </template>
      </b-form-tags>
    </b-form-group>
    <div class="helper_text" :style="helper_text_style" v-if="helper_text">
      {{ helper_text }}
    </div>
  </div>
</template>

<script>
export default {
  emits: ["change-value"],
  props: ["label", "options", "field", "value", "helper_text", "is_invalid"],
  data() {
    return {
      inputfield: [],
    };
  },
  watch: {
    inputfield(val) {
      let opt = [];
      for (let i = 0; i < val.length; i++) {
        const element = val[i];
        const rec = this.options.find((each) => each.label === element);
        if (rec) opt.push(rec.id);
      }
      this.$emit("change-value", opt, this.field);
    },
  },
  computed: {
    availableOptions() {
      return this.options
        .map((each) => each.label)
        .filter((opt) => this.inputfield.indexOf(opt) === -1);
    },
  },
};
</script>

<style>
.labelText {
  font-size: 15px;
  color: #5e595c;
  margin-bottom: 5px;
}
.helper_text {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  margin-top: -6px;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}
.invalidInput {
  border-color: red !important;
  background-color: white !important;
}
#tags-component-select {
  padding: 6px;
  font-size: 16px;
  font-family: Roboto_Regular;
  width: -webkit-fill-available;
  border-radius: 10px;
  border: 1px solid #edece9;
}
output,
#tags-component-select___removed_tags__ {
  display: none !important;
}
.list-inline-item span {
  background-color: #741b47;
  color: #ffe599;
  border: 1px solid #741b47;
}
.list-inline-item:not(:last-child) {
  margin-right: 0rem !important;
  margin-bottom: 0.25rem !important;
}
.b-form-tag > button.b-form-tag-remove {
  background-color: #741b47;
  color: #ffe599;
  font-size: 100%;
  border: none;
}
.custom-select {
  padding: 0px;
  font-size: 14px;
  font-family: Roboto_Regular;
  width: -webkit-fill-available;
  border: none;
}
.custom-select:focus-visible {
  border: none;
  outline: none;
  box-shadow: none;
}
.custom-select:hover {
  border: none;
  outline: none;
  box-shadow: none;
}
.custom-select:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
</style>
