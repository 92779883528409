<script setup>
import { reactive, watch } from "vue";
import VueMultiselect from "vue-multiselect";
import MetricService from "@/services/metric.service";
import Calculation from "@/ui-components/common/Calculation.vue";

const props = defineProps({
  metricType: {
    required: false,
  },
  industry: {
    required: true,
  },
  selectedMetric: {
    required: true,
  },
  allData: {
    required: true,
  },
});

const SIGN_OPTIONS = [
  { name: "<", sign: "<" },
  { name: ">", sign: ">" },
  { name: "<=", sign: "<=" },
  { name: ">=", sign: ">=" },
  { name: "==", sign: "==" },
  { name: "!=", sign: "!=" },
];
const fetchSegmentOptions = async () => {
  if (!props.industry) return;
  try {
    state.loading = true;
    let result = await MetricService.getSegmentOption(props?.industry?.industry);
    state.segmentOption = result.map((item) => {
      return { name: item, segment: item };
    });
  } catch {
    console.log("eeee");
  } finally {
    state.loading = false;
  }
};
const state = reactive({
  segment: null,
  metricName: null,
  segmentOption: null,
  symbol: null,
  definition: null,
  whyImportant: null,
  calculations: {
    numerator: "",
    denominator: "",
    multiplyBy: "",
    type: "manual",
    image: null,
  },
  code: null,
  isMetric: null,
  id: null,
  isMetricChecked: false,
});

const benchmarkState = reactive({
  ratingData:
    props.industry?.industry != "SAAS"
      ? {
          Deficient: {
            firstValue: null,
            secondValue: null,
            firstSign: null,
            secondSign: null,
          },
          Good: {
            firstValue: null,
            secondValue: null,
            firstSign: null,
            secondSign: null,
          },
          Excellent: {
            firstValue: null,
            secondValue: null,
            firstSign: null,
            secondSign: null,
          },
        }
      : {
          Deficient: {
            firstValue: null,
            secondValue: null,
            firstSign: null,
            secondSign: null,
          },
          Good: {
            firstValue: null,
            secondValue: null,
            firstSign: null,
            secondSign: null,
          },
          "Very Good": {
            firstValue: null,
            secondValue: null,
            firstSign: null,
            secondSign: null,
          },
          Excellent: {
            firstValue: null,
            secondValue: null,
            firstSign: null,
            secondSign: null,
          },
        },
});

const addSegmentTag = (newTag) => {
  const newSegment = { name: newTag, segment: newTag };
  state.segmentOption.push(newSegment);
  state.segment = newSegment;
};
// const handleFileUpload = (event) => {
//   const file = event.target.files[0];
//   if (file && file.type.startsWith("image/")) {
//     state.calculationImg = file;
//   } else {
//     alert("Please upload a valid image file.");
//   }
// };
watch(
  () => props.industry,
  (newValue) => {
    if (newValue) {
      fetchSegmentOptions();
    }
  },
  { immediate: true, deep: true }
);
watch(
  () => props.selectedMetric,
  (newValue) => {
    if (newValue) {
      state.segment = newValue.segment
        ? { segment: newValue.segment, name: newValue.segment }
        : null;
      state.symbol = newValue.symbol ?? null;
      state.definition = newValue.definition ?? null;
      state.whyImportant = newValue.importance ?? null;
      state.calculations = {
        numerator: newValue.calculations?.numerator ?? "",
        denominator: newValue.calculations?.denominator ?? "",
        multiplyBy: newValue.calculations?.multiplyBy ?? "",
        type: newValue.calculations?.type ?? "manual",
        image: newValue.calculations?.image ?? null,
      };
      state.code = newValue.code ?? null;
      state.metricName = newValue.name ?? null;
      state.isMetric = newValue.is_metric;
      state.id = newValue.id ?? null;
      state.isMetricChecked = newValue.is_metric;

      if (newValue?.benchmarks?.length > 2) {
        newValue.benchmarks.forEach((item) => {
          if (!benchmarkState.ratingData[item.label]) {
            benchmarkState.ratingData[item.label] = {};
          }

          benchmarkState.ratingData[item.label].firstValue =
            item?.tests[0]?.value ?? null;
          benchmarkState.ratingData[item.label].firstSign = item?.tests[0]?.condition
            ? { name: item.tests[0].condition, sign: item.tests[0].condition }
            : null;

          benchmarkState.ratingData[item.label].secondValue =
            item?.tests[1]?.value ?? null;
          benchmarkState.ratingData[item.label].secondSign = item?.tests[1]?.condition
            ? { name: item.tests[1].condition, sign: item.tests[1].condition }
            : null;
        });
      } else {
        benchmarkState.ratingData =
          props.industry?.industry != "SAAS"
            ? {
                Deficient: {
                  firstValue: null,
                  secondValue: null,
                  firstSign: null,
                  secondSign: null,
                },
                Good: {
                  firstValue: null,
                  secondValue: null,
                  firstSign: null,
                  secondSign: null,
                },
                Excellent: {
                  firstValue: null,
                  secondValue: null,
                  firstSign: null,
                  secondSign: null,
                },
              }
            : {
                Deficient: {
                  firstValue: null,
                  secondValue: null,
                  firstSign: null,
                  secondSign: null,
                },
                Good: {
                  firstValue: null,
                  secondValue: null,
                  firstSign: null,
                  secondSign: null,
                },
                "Very Good": {
                  firstValue: null,
                  secondValue: null,
                  firstSign: null,
                  secondSign: null,
                },
                Excellent: {
                  firstValue: null,
                  secondValue: null,
                  firstSign: null,
                  secondSign: null,
                },
              };
      }
    } else {
      state.segment = null;
      state.metricName = null;
      state.segmentOption = null;
      state.symbol = null;
      state.definition = null;
      state.whyImportant = null;
      state.calculations = {
        numerator: "",
        denominator: "",
        multiplyBy: "",
        type: "manual",
        image: null,
      };
      benchmarkState.ratingData = {
        Deficient: {
          firstValue: null,
          secondValue: null,
          firstSign: null,
          secondSign: null,
        },
        Good: {
          firstValue: null,
          secondValue: null,
          firstSign: null,
          secondSign: null,
        },
        Excellent: {
          firstValue: null,
          secondValue: null,
          firstSign: null,
          secondSign: null,
        },
      };
      state.code = null;
      state.isMetric = null;
      state.id = null;
      state.isMetricChecked = false;
    }
  },
  { immediate: true, deep: true }
);
const updateRating = (metric, key, value) => {
  benchmarkState.ratingData[metric][key] = value;
};
const validateColumns = () => {
  if (!state.metricName) {
    return "Missing metricName field.";
  }
  if (!state.definition) {
    return "Missing definition field.";
  }
  if (!state.whyImportant) {
    return "Missing whyImportant field.";
  }
  if (!state.calculations) {
    return "Missing calculations field.";
  }
  if (!state.segment) {
    return "Missing segment field.";
  }
  return null;
};
function hasDuplicate(data, testName, testSegment) {
  return data.some(
    (record) => record.name === testName && record.segment === testSegment
  );
}

const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file && file.type.startsWith("image/")) {
    const reader = new FileReader();
    reader.onload = (e) => {
      state.calculations.image = e.target.result;
    };
    reader.readAsDataURL(file);
  } else {
    alert("Please upload a valid image file.");
  }
};

function getRecordsByName(data, name) {
  return data.filter((record) => record.name === name);
}
const handleSubmit = async () => {
  // const validationError = validateColumns();
  // if (validationError) {
  //   alert(validationError);
  //   return;
  // }

  const transform = (input) => {
    return Object.entries(input)?.map(([label, data]) => {
      if (data.firstValue === null) {
        return false;
      }
      const tests = [];
      let description;
      description = `${label} ${data?.firstSign?.sign} ${data.firstValue}`;

      if (data.firstValue !== null) {
        tests.push({
          value: data.firstValue,
          condition: data.firstSign?.sign,
        });
      }
      if (data.secondValue !== null) {
        tests.push({
          value: data.secondValue,
          condition: data.secondSign?.sign,
        });
      }
      if (data.secondValue !== null && data.secondSign) {
        description = `${data.firstValue} ${data.firstSign?.sign} ${label} ${data.secondSign?.sign} ${data.secondValue}`;
      }

      return {
        label,
        tests,
        description,
      };
    });
  };

  const output = transform(benchmarkState.ratingData);
  let benchmark;
  if (state.isMetricChecked === false) {
    benchmark = null;
  } else if (!output.includes(false)) {
    benchmark = output;
  } else {
    alert("Please fill all the benchmark fields");
    return false;
  }

  const payload = {
    id: state.id,
    code: state.code || "not exist",
    name: state.metricName,
    segment: state.segment?.segment || null,
    industry: props.industry?.industry,
    benchmarks: benchmark,
    symbol: state.symbol,
    definition: state.definition,
    importance: state.whyImportant,
    calculations: {
      numerator:
        state.calculations?.type == "upload" ? "" : state.calculations?.numerator ?? "",
      denominator:
        state.calculations?.type == "upload" ? "" : state.calculations?.denominator ?? "",
      multiplyBy:
        state.calculations?.type == "upload" ? "" : state.calculations?.multiplyBy ?? "",
      type: state.calculations?.type ?? "manual",
      image: state.calculations?.image ?? null,
    },
  };
  try {
    const response = await MetricService.addMetrics(payload);
    if (response.code === 0) {
      alert(response.message);
      window.location.reload();
    } else {
      alert(response.data);
    }
  } catch {
    console.log("eeee");
  }
};
</script>

<template>
  <div class="form-container py-4">
    <div class="width-100">
      <div>Industry: {{ props.industry.name }}</div>
      <div>metric code : {{ state.code }}</div>

      <input
        type="text"
        class="form-control"
        v-model="state.code"
        placeholder="Metric code *"
      />
      <input
        type="text"
        class="form-control"
        v-model="state.metricName"
        placeholder="Metric name *"
      />

      <div class="py-2">
        <VueMultiselect
          v-if="state.segmentOption"
          v-model="state.segment"
          tag-placeholder="Select segment *"
          placeholder="Select segment  *"
          label="name"
          track-by="segment"
          :options="state.segmentOption"
          :multiple="false"
          :taggable="true"
          @tag="addSegmentTag"
        />
      </div>
      <div class="py-2">
        <input
          type="text"
          class="form-control"
          v-model="state.symbol"
          placeholder="Symbol"
        />
      </div>
      <div class="py-2">
        Definition *
        <textarea
          class="form-control"
          v-model="state.definition"
          placeholder="Definition *"
          rows="4"
        ></textarea>
      </div>

      <div class="py-2">
        Why is this important? *
        <textarea
          class="form-control"
          v-model="state.whyImportant"
          placeholder="Why is this important? *"
          rows="4"
        ></textarea>
      </div>
      <div class="py-2">
        <div>Calculations *</div>
        <div class="calculation-radio">
          <label>
            <input type="radio" v-model="state.calculations.type" value="manual" />
            Enter Calculation Manually
          </label>
          <label>
            <input type="radio" v-model="state.calculations.type" value="upload" />
            Upload Calculation Image
          </label>
        </div>
      </div>
      <div v-if="state.calculations.type === 'manual'">
        <div class="py-2">
          Calculations? *
          <div>
            <input
              type="text"
              v-model="state.calculations.numerator"
              placeholder="Enter numerator formula"
              class="form-control"
            />
          </div>
          <div>
            <input
              type="text"
              v-model="state.calculations.denominator"
              placeholder="Enter denominator formula"
              class="form-control"
            />
          </div>

          <div>
            <input
              type="text"
              v-model="state.calculations.multiplyBy"
              placeholder="Enter multiply By "
              class="form-control"
            />
          </div>
        </div>
        <div v-if="state.calculations.numerator">
          <Calculation
            :numeratorInput="state?.calculations?.numerator"
            :denominatorInput="state?.calculations?.denominator ?? ''"
            :multiplyByHundred="state?.calculations?.multiplyBy ?? ''"
          />
        </div>
      </div>
      <div v-else>
        <input type="file" @change="handleFileUpload" accept="image/*" />
      </div>

      <div class="py-2">
        <label>
          <input type="checkbox" v-model="state.isMetricChecked" />
          Is Metric
        </label>
      </div>
      <div v-if="state.isMetricChecked">
        <!--  -->
        <div class="benchmark py-4">
          <div
            v-for="(data, metric) in benchmarkState.ratingData"
            :key="metric"
            class="rating-row"
          >
            <input
              type="text"
              v-model.number="data.firstValue"
              placeholder="Enter first value"
              class="input-box"
              @input="updateRating(metric, 'firstValue', data.firstValue)"
            />
            <div class="width-200">
              <VueMultiselect
                v-model="data.firstSign"
                :options="SIGN_OPTIONS"
                placeholder="Select sign type"
                label="name"
                track-by="sign"
                :multiple="false"
                aria-label="Select a sign type"
                @change="updateRating(metric, 'sign', data.firstSign)"
              />
            </div>
            <div class="title">{{ metric }}</div>
            <div
              class="width-200"
              v-if="metric !== 'Deficient' && metric !== 'Excellent'"
            >
              <VueMultiselect
                v-model="data.secondSign"
                :options="SIGN_OPTIONS"
                placeholder="Select sign type"
                label="name"
                track-by="sign"
                :multiple="false"
                aria-label="Select a sign type"
                @change="updateRating(metric, 'sign', data.secondSign)"
              />
            </div>
            <input
              v-if="metric !== 'Deficient' && metric !== 'Excellent'"
              type="text"
              v-model.number="data.secondValue"
              placeholder="Enter second value"
              class="input-box"
              @input="updateRating(metric, 'secondValue', data.secondValue)"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="py-2 d-flex justify-content-center">
      <button class="standard-btn" @click="handleSubmit">Submit</button>
    </div>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.calculation-radio {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.width-100 {
  width: 100%;
}
.error-message {
  font-size: 12px;
  color: red;
}
.custom-gap {
  gap: 8px;
}
.standard-btn {
  height: 36px;
  padding: 8px 10px 8px 10px;
  border-radius: 6px;
  background-color: #024058;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.form-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
}

.title {
  min-width: 80px;
  font-size: 16px;
}
.width-200 {
  min-width: 200px !important;
}
.rating-row {
  margin-bottom: 20px;
  display: flex;
  gap: 24px;
}

.input-box {
  margin-right: 10px;
  padding: 8px;
  font-size: 14px;
  width: 150px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 40px;
}
</style>
