<template>
  <div class="d-flex align-items-center justify-content-between">
    <div class="page-info">
      <span class="text-secondary">
        {{ start_count }}-{{ end_count }}
        <span class="text-muted">of {{ total_count }}</span>
      </span>
    </div>

    <nav aria-label="Page navigation">
      <ul class="pagination mb-0">
        <li :class="['page-item', { disabled: active_page === 1 }]">
          <a class="page-link" href="#" @click.prevent="prevPage" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>

        <template v-for="(page, index) in displayedPages" :key="index">
          <li v-if="page === '...'" class="page-item disabled">
            <span class="page-link">...</span>
          </li>
          <li v-else :class="['page-item', { active: page === active_page }]">
            <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
          </li>
        </template>

        <li :class="['page-item', { disabled: active_page === total_page }]">
          <a class="page-link" href="#" @click.prevent="nextPage" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>

    <!-- Page Selection Dropdown -->
    <div class="page-select">
      <select
        class="form-select form-select-sm"
        v-model="selectedPage"
        @change="changePage(selectedPage)"
      >
        <option v-for="page in total_page" :key="page" :value="page">
          Page {{ page }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  emits: ["set-page"],
  props: {
    active_page: {
      type: Number,
      required: true,
    },
    total_page: {
      type: Number,
      required: true,
    },
    total_count: {
      type: Number,
      required: true,
    },
    page_size: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      start_count: 0,
      end_count: 0,
      selectedPage: this.active_page, // Initialize selectedPage with active_page
    };
  },
  computed: {
    displayedPages() {
      const delta = 2;
      const range = [];
      const rangeWithDots = [];
      let l;

      for (let i = 1; i <= this.total_page; i++) {
        if (
          i === 1 ||
          i === this.total_page ||
          (i >= this.active_page - delta && i <= this.active_page + delta)
        ) {
          range.push(i);
        }
      }

      range.forEach((i) => {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push("...");
          }
        }
        rangeWithDots.push(i);
        l = i;
      });

      return rangeWithDots;
    },
  },
  methods: {
    prevPage() {
      if (this.active_page > 1) {
        this.$emit("set-page", this.active_page - 1);
      }
    },
    changePage(page) {
      this.$emit("set-page", page);
    },
    nextPage() {
      if (this.active_page < this.total_page) {
        this.$emit("set-page", this.active_page + 1);
      }
    },
    updateCounts() {
      this.start_count = (this.active_page - 1) * this.page_size + 1;
      this.end_count = Math.min(this.active_page * this.page_size, this.total_count);
    },
  },
  watch: {
    active_page: {
      handler(newPage) {
        this.updateCounts();
        this.selectedPage = newPage; // Update selectedPage when active_page changes
      },
      immediate: true,
    },
  },
  mounted() {
    this.updateCounts();
  },
};
</script>

<style scoped>
.form-select {
  width: auto;
  display: inline-block;
  height: 38px;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"); /* Custom arrow */
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
}

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.page-info {
  font-size: 0.875rem;
}

.pagination {
  margin-left: 1rem;
}

.page-link {
  color: #6c757d;
}

.page-item.active .page-link {
  background-color: #6c757d;
  border-color: #6c757d;
}

.page-link:hover {
  background-color: #e9ecef;
  border-color: #dee2e6;
  color: #024058;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-select {
  margin-left: 1rem;
}

.form-select {
  width: auto;
  display: inline-block;
}
</style>
