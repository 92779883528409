<template>
  <b-modal
    id="Add-User-Modal"
    size="lg"
    centered
    hide-header
    hide-footer
    @hidden="resetForm"
  >
    <div v-if="loading" class="loader-overlay">
      <div
        id="loading-container"
        class="p-5 d-flex justify-content-center align-items-center"
      >
        <b-spinner></b-spinner>
      </div>
    </div>
    <div class="modal-title">AddUserModal</div>
    <div class="settings-wrapper">
      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>
      <b-row>
        <b-col lg="12" sm="12" class="py-1">
          <base-input
            label="Email"
            type="text"
            field="email"
            placeholder="Email"
            :value="formFields.email"
            @change-value="setValue"
            :is_invalid="v$.email.$error"
            :helper_text="v$.email.$errors[0]?.$message || ''"
          ></base-input>
        </b-col>

        <b-col lg="12" sm="12" class="py-1">
          <base-input
            label="First name"
            type="text"
            field="firstName"
            placeholder="First name"
            :value="formFields.firstName"
            @change-value="setValue"
            :is_invalid="v$.firstName.$error"
            :helper_text="v$.firstName.$errors[0]?.$message || ''"
          ></base-input>
        </b-col>
        <b-col lg="12" sm="12" class="py-1">
          <base-input
            label="Last name"
            type="text"
            field="lastName"
            placeholder="Last name"
            :value="formFields.lastName"
            @change-value="setValue"
            :is_invalid="v$.lastName.$error"
            :helper_text="v$.lastName.$errors[0]?.$message || ''"
          ></base-input>
        </b-col>
        <b-col lg="12" sm="12" class="py-1">
          <base-select
            label="Company"
            :options="companiesOptions"
            field="company_id"
            :value="formFields.company_id"
            @change-value="setValue"
            class="m-0"
          ></base-select>
          <div class="helper-text" v-if="errors && errors.length">
            {{ errors[0] }}
          </div>
        </b-col>

        <b-col lg="12" sm="12" class="py-1">
          <div class="form-check form-switch pl-0">
            <label for="checkbox" class="form-label labelText">Password</label>
            <input
              class="form-check-input ml-1"
              type="checkbox"
              v-model="formFields.isPassword"
              :checked="formFields.isPassword"
              @change="togglePasswordValidation"
            />
          </div>

          <base-input
            type="text"
            field="password"
            :disabled="formFields.isPassword"
            :value="formFields.password"
            @change-value="setValue"
          ></base-input>
          <p v-if="v$?.password?.$error" class="error-msg">
            {{ v$?.password?.$errors[0]?.$message }}
          </p>
        </b-col>
        <b-col lg="12" sm="12" class="py-1">
          <div class="form-check form-switch pl-0">
            <label for="notify" class="form-label labelText">Notify</label>
            <input
              class="form-check-input ml-1"
              type="checkbox"
              v-model="formFields.notify"
              :checked="formFields.notify"
            />
          </div>
          <div class="helper-text" v-if="errors && errors.length">
            {{ errors[0] }}
          </div>
        </b-col>

        <b-col lg="12" sm="12" class="py-1">
          <base-button
            :style_variant="isSubmitDisabled ? 'secondary' : 'primary'"
            cus_style="font-size:15px"
            @onClick="submitForm"
            :disabled="isSubmitDisabled"
          >
            SUBMIT
          </base-button>
        </b-col>
      </b-row>
    </div>
    <ConfirmationDialog
      :onSubmit="onSubmitDialog"
      text="A user with this email already exists. Do you want to overwrite it with new information?"
    />
  </b-modal>
</template>

<script>
import { reactive, computed, watch } from "vue";
import {
  required,
  email as emailValidator,
  minLength,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import SettingsService from "@/services/settingsDashboard.service";
import { ErrorMessages } from "@/lib/errorMessages";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";

export default {
  components: {
    ConfirmationDialog,
  },
  emits: ["refresh-page"],
  setup() {
    const formFields = reactive({
      email: "",
      firstName: "",
      lastName: "",
      company_id: null,
      password: "",
      notify: false,
      isPassword: true,
    });

    const passwordRules = computed(() => {
      return !formFields.isPassword
        ? {
            required: required,
            minLength: minLength(8),
          }
        : {};
    });

    const rules = reactive({
      email: { required, email: emailValidator },
      firstName: { required },
      lastName: { required },
      company_id: { required },
      password: passwordRules,
    });

    const v$ = useVuelidate(rules, formFields);

    const resetForm1 = () => {
      Object.keys(formFields).forEach((key) => {
        formFields[key] = key === "notify" || key === "isPassword" ? false : "";
      });
      v$.value.$reset();
    };
    return {
      formFields,
      v$,
      resetForm1,
    };
  },
  data() {
    return {
      companiesOptions: [
        {
          id: null,
          label: "Select Company ",
        },
      ],
      errors: null,
      error: null,
      loading: false,
    };
  },
  methods: {
    resetForm() {
      this.resetForm1();
    },
    setValue(val, field) {
      this.formFields[field] = val;
    },
    async onSubmitDialog() {
      try {
        this.loading = true;
        await SettingsService.addUsers(this.formFields);
        this.$emit("refresh-page");
        this.$bvModal.hide("Add-User-Modal");
      } catch (error) {
        this.error = ErrorMessages.errorLoading;
      } finally {
        this.loading = false;
      }
    },
    async submitForm() {
      const valid = await this.v$.$validate();
      if (!valid) return;

      const params = {
        email: this.formFields.email,
      };
      let checkUserExist = await SettingsService.checkUser(params);

      if (checkUserExist?.exists) {
        this.$bvModal.show("confirmation-modal");
        return;
      }

      try {
        this.loading = true;
        await SettingsService.addUsers(this.formFields);
        this.$emit("refresh-page");
        this.$bvModal.hide("Add-User-Modal");
      } catch (error) {
        this.error = ErrorMessages.errorLoading;
      } finally {
        this.loading = false;
      }
    },
    getCompanyOptions() {
      this.loading = true;
      SettingsService.companyLists()
        .then((data) => {
          data?.objects?.map((item) => {
            let temp = { id: item.id, label: item.name };
            this.companiesOptions.push(temp);
          });
        })
        .catch((e) => {
          this.error = ErrorMessages.errorLoading;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    togglePasswordValidation() {
      this.formFields.password = null;
    },
  },
  computed: {
    isSubmitDisabled() {
      const { email, firstName, lastName, company_id, isPassword, password } =
        this.formFields;
      return (
        !email ||
        !firstName ||
        !lastName ||
        !company_id ||
        (!isPassword && !password)
      );
    },
   
  },
  mounted() {
    this.getCompanyOptions();
  },
};
</script>

<style scoped>
.error-msg {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  z-index: 1000;
}
#Add-User-Modal .modal-title {
  font-size: 24px;
  font-family: Roboto_Medium;
  color: #5e595c;
  border-bottom: 2px solid #dfddd9;
  margin-bottom: 1rem;
}

.wrapper {
  padding: 80px;
}

.labelText {
  font-size: 15px;
  color: #5e595c;
  margin-bottom: 5px;
}

.helper-text {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.form-control:focus-visible {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}

.form-control:hover {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}

.form-control:focus {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}
</style>
