<template>
  <b-modal
    id="new-application-modal"
    size="md"
    centered
    hide-header
    hide-footer
    @hidden="reset"
  >
    <div class="modal-title">Create Application (2nd Application)</div>
      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>

      <b-row>
        <b-col md="12">
       
            <base-select
              label="Eligible Company*"
              :options="company_options"
              field="company_id"
              :value="company_id"
              @change-value="setValue"
              :disabled="loading"
              :is_invalid="errors && errors.length"
              :helper_text="errors && errors.length ? errors[0] : ''"
            ></base-select>
        </b-col>
        <b-col md="12">
            <base-input
              label="Average Annual Recurring Revenue (ARR)*"
              placeholder="Enter Average Annual Recurring Revenue (ARR)*"
              type="number"
              :min="0"
              field="arr"
              :value="arr"
              @change-value="setValue"
              :disabled="loading"
              :is_invalid="errors && errors.length"
              :helper_text="errors && errors.length ? errors[0] : ''"
            ></base-input>
        </b-col>
        <b-col md="12">
            <base-input
              label="Application Name*"
              placeholder="Enter Application Name*"
              type="text"
              field="name"
              :value="name"
              @change-value="setValue"
              :disabled="loading"
              :is_invalid="errors && errors.length"
              :helper_text="errors && errors.length ? errors[0] : ''"
            ></base-input>
        </b-col>
        <b-col md="12">
         
            <base-input
              label="Requested Amount from Capifly*"
              placeholder="Enter requested amount from Capifly"
              type="number"
              :min="0"
              field="requested"
              :value="requested"
              @change-value="setValue"
              :disabled="loading"
              :is_invalid="errors && errors.length"
              :helper_text="errors && errors.length ? errors[0] : ''"
            ></base-input>
        </b-col>
        <b-col md="12">
        
            <base-input
              label="Number of Investment Rounds to Date*"
              placeholder="Enter the number of investment rounds to date"
              type="number"
              :min="0"
              field="investments"
              :value="investments"
              @change-value="setValue"
              :disabled="loading"
              :is_invalid="errors && errors.length"
              :helper_text="errors && errors.length ? errors[0] : ''"
            ></base-input>
        </b-col>
        <b-col md="12">
          
            <base-input
              label="Number of Active Contracts*"
              placeholder="Enter the number of active contracts"
              type="number"
              :min="0"
              field="contracts"
              :value="contracts"
              @change-value="setValue"
              :disabled="loading"
              :is_invalid="errors && errors.length"
              :helper_text="errors && errors.length ? errors[0] : ''"
            ></base-input>
        </b-col>
        <b-col md="12">
          
            <base-select
              label="Shariah compliant*"
              :options="shariah_compliant_options"
              field="shariah_compliant"
              :value="shariah_compliant"
              @change-value="setValue"
              :disabled="loading"
              :is_invalid="errors && errors.length"
              :helper_text="errors && errors.length ? errors[0] : ''"
            ></base-select>
        </b-col>
      </b-row>
    <b-row>
      <b-col
        offset-lg="6"
        lg="6"
        offset-sm="6"
        sm="6"
        class="d-flex align-items-center"
      >
        <base-button
          style_variant="primary"
          cus_style="font-size: 15px; font-family: Roboto_Medium;margin-right:0.5rem;"
          :loading="loading"
          :disabled="loading"
          @onClick="submit"
        >
          SUBMIT
        </base-button>
        <base-button
          style_variant="secondary"
          cus_style="font-size: 15px; font-family: Roboto_Medium"
          :disabled="loading"
          @onClick="closeModal"
        >
          CANCEL
        </base-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>


import ApplicationsService from "../../services/applications.service";
import CompanyService from "../../services/company.service";

export default {
  name: "NewCompanyModal",
  emits: ["refresh-page"],
  components: {
  },
  data() {
    return {
      error: null,
      loading: false,
      shariah_compliant_options: [
        { id: null, label: "Choose anyone" },
        { id: true, label: "Yes" },
        { id: false, label: "No" },
      ],
      company_options: [],
      company_id: null,
      arr: null,
      requested: null,
      investments: null,
      contracts: null,
      shariah_compliant: null,
      name: null,
    };
  },
  methods: {
    async submit() {
      let payload = {
        company_id: this.company_id,
        arr: this.arr,
        requested: this.requested,
        investments: this.investments,
        contracts: this.contracts,
        shariah_compliant: this.shariah_compliant,
        name:this.name,
      };

      this.loading = true;
      ApplicationsService.post(payload)
        .then(() => {
          this.$emit("refresh-page");
          this.closeModal();
        })
        .catch(({ message }) => {
          this.error = message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeModal() {
      this.$bvModal.hide("new-application-modal");
      this.reset();
    },
    setValue(val, field) {
      this[field] = val;
    },
    reset() {
      this.error = null;
      this.loading = false;
      this.company_id = null;
      this.arr = null;
      this.requested = null;
      this.investments = null;
      this.contracts = null;
      this.shariah_compliant = null;
      this.name = null;
    },
  },
  mounted() {
    this.loading = true;
    CompanyService.query()
      .then(({ objects }) => {
        if (objects && objects.length)
          this.company_options = objects
            .filter((each) => each.state === "ACCEPTED")
            .map((item) => {
              return {
                id: item.id,
                label: item.name,
              };
            });
      })
      .catch(({ message }) => {
        this.error = message;
      })
      .finally(() => {
        this.loading = false;
        this.company_options.unshift({
          id: null,
          label: "Select Eligible Company",
        });
      });
  },
};
</script>

<style scoped>
#new-application-modal .modal-title {
  font-size: 24px;
  font-family: Roboto_Medium;
  color: #5e595c;
  border-bottom: 2px solid #dfddd9;
  margin-bottom: 2rem;
}
</style>
