<template>
  <b-modal id="credit-history" size="lg" centered hide-header hide-footer>
    <div class="modal-title">Add Credit History</div>
    <b-alert :show="error && error.length ? true : false" variant="danger">
      <div v-html="error || '&nbsp;'"></div>
    </b-alert>

    <b-row>
      <b-col lg="6" sm="12">
        <div>
          <base-input
              label="Give it a name"
              placeholder="Enter Label"
              type="text"
              field="label"
              :value="label"
              @change-value="setValue">></base-input>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" sm="12">
        <base-input
            label="And upload the pdf file"
            :placeholder="'Upload your file here'"
            ref="fileInput"
            type="file"
            class="ibra"
            accept=".pdf"
            :value="uploadedFile ? uploadedFile.name : ''"
            @change-value="handleFileUpload">
        >
        </base-input>
      </b-col>
    </b-row>
    <div class="buttons-block">
      <base-button
          id="myBtn"
          style_variant="primary"
          cus_style="font-size: 15px; font-family: Roboto_Medium;margin-right:0.5rem;width:150px"
          @onClick="handleSubmitCreditData()">
        SUBMIT
      </base-button>
      <base-button
          style_variant="secondary"
          cus_style="font-size: 15px; font-family: Roboto_Medium;width:150px"
          @onClick="closeModal">
        CANCEL
      </base-button>
    </div>
  </b-modal>
</template>

<script>
//TODO: monthly.xlsx template should be uploaded to the S3/Cloudfront as part of the TF

import ApplicationsService from "@/services/applications.service";
import {ErrorMessages} from "@/lib/errorMessages";

export default {
  name: "credit-history",
  data() {
    return {
      error: null,
      loading: false,
      label: null,
      showLabel: false,
      uploadedFile: null,

    };
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("credit-history");
    },
    async handleFileUpload(event) {
      this.errorMessage = null;
      this.uploadedFile = event;
    },
    setValue(val, field) {
      this[field] = val;
    },
    async handleSubmitCreditData() {
      console.log(this.uploadedFile)
      const creditHistoryFile = await toBase64(this.uploadedFile);
      const payload = {
        file: creditHistoryFile,
        label: this.label,
      }
      const id = this.$route.params.id;
      ApplicationsService.storeCreditHistory(payload, id)
          .then((res) => {
            this.closeModal()
            this.loading = true;
          })
          .catch((err) => {
            this.errorMessage = ErrorMessages.errorSheet;
            this.loading = false;
          });
    },
  },
};
const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
</script>

<style scoped>
 .modal-title {
  font-size: 24px;
  font-family: Roboto_Medium;
  color: #5e595c;
  border-bottom: 2px solid #dfddd9;
  margin-bottom: 2rem;
}
.cursor-grabbing {
  cursor: grabbing !important;
}

.draggable-list > div {
  cursor: pointer;
}
</style>
