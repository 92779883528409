<template>
  <div id="application-repayments-schedule-container">
    <b-row class="table-title">
      <b-col md="3" class="table-title-field">
        <div class="table-title-text">PAYMENT ID</div>
        <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
      </b-col>
      <b-col md="3" class="table-title-field">
        <div class="table-title-text">STATUS</div>
        <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
      </b-col>
      <b-col md="3" class="table-title-field">
        <div class="table-title-text">DUE DATE</div>
        <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
      </b-col>
      <b-col md="3" class="table-title-field">
        <div class="table-title-text">DUE AMOUNT</div>
        <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
      </b-col>
    </b-row>

    <b-row class="table-data" v-if="loading">
      <common-loading />
    </b-row>

    <b-row class="table-data" v-else-if="error && error.length && !loading">
      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>
    </b-row>

    <b-row class="table-data" v-else v-for="(each, index) in data" :key="index">
      <b-col md="3" class="due-date">{{ each.number }}</b-col>
      <b-col md="3">
        <span
          :class="[
            'status',
            {
              upcoming:
                each && each.status && each.status === 'SCHEDULED'
                  ? true
                  : false,
            },
            {
              overdue:
                each && each.status && each.status === 'OVERDUE' ? true : false,
            },
            {
              paid:
                each && each.status && each.status === 'PAID' ? true : false,
            },
          ]"
        >
          <span v-if="each && each.status && each.status === 'PAID'">
            PAID ON {{ formatMomentDate(each.paid_at,"MMM DD, YYYY") }}
          </span>
          <span v-else-if="each && each.status && each.status === 'SCHEDULED'">
            SCHEDULED
          </span>
          <span v-if="each && each.status && each.status === 'OVERDUE'">
            {{
              Math.floor(
                Math.abs(new Date() - new Date(each.due_at)) /
                  (1000 * 60 * 60 * 24)
              )
            }}
            DAYS OVERDUE
          </span>
        </span>
      </b-col>
      <b-col md="3">
        <div class="number">
          {{  formatMomentDate(each.due_at,"MM-DD-YYYY")}}
        </div>
      </b-col>
      <b-col md="3" class="amount-container" style="position: relative">
        <div class="due-amount">
          <span v-html="$amount(each.amount)"></span>
        </div>
        <div
          v-if="each.status !== 'PAID'"
          class="action-container"
          @click="openMenu(index)"
        >
          <img
            :src="
              openMenuFlag === index
                ? '/images/eye-icon.png'
                : '/images/dot-icon.png'
            "
            :alt="openMenuFlag === index ? 'eye-icon' : 'dot-icon'"
          />
        </div>
        <div class="action-menu" v-if="openMenuFlag === index">
          <div class="action-menu-item" @click="paid(each.id)">Paid</div>
        </div>
      </b-col>
    </b-row>

    <div class="d-flex justify-content-end">
      <div class="pagination-container" v-if="total_count">
        <base-pagination
          :active_page="active_page"
          :total_page="total_page"
          :total_count="total_count"
          :page_size="page_size"
          @set-page="setPage"
        />
      </div>
    </div>

    <b-modal id="confirm-modal" size="lg" centered hide-header hide-footer>
      <div class="modal-title">
        You are about to mark this payment as paid, this action is irreversible.
        Are you sure you want to continue?
      </div>
      <b-row>
        <b-col lg="2" sm="0" />
        <b-col lg="4" sm="6">
          <base-button
            type="submit"
            style_variant="secondary"
            cus_style="font-size: 15px; font-family: Roboto_Medium;margin-right:0.5rem;"
            :loading="loading"
            @onClick="confirmPaid"
          >
            YES
          </base-button>
        </b-col>
        <b-col lg="4" sm="6">
          <base-button
            type="submit"
            style_variant="secondary"
            cus_style="font-size: 15px; font-family: Roboto_Medium"
            :disabled="loading"
            @onClick="cancel"
          >
            CANCEL
          </base-button>
        </b-col>
        <b-col lg="2" sm="0" />
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import PaymentsService from "../../services/payments.service";
import moment from "moment";

export default {
  name: "RepaymentsSchedule",
  props: ["application_id"],
  data() {
    return {
      openMenuFlag: null,
      error: null,
      loading: false,
      page_size: 5,
      active_page: 1,
      total_page: 0,
      total_count: 0,
      data: [],
      payment_id: null,
    };
  },
  methods: {
    formatMomentDate(date, format) {
      return moment(date).format(format);
    },
    setPage(page) {
      this.active_page = page;
    },
    openMenu(index) {
      if (this.openMenuFlag === index) this.openMenuFlag = null;
      else this.openMenuFlag = index;
    },
    paid(payment_id) {
      this.payment_id = payment_id;
      this.openMenuFlag = null;
      this.$bvModal.show("confirm-modal");
    },
    confirmPaid() {
      this.loading = true;

      PaymentsService.pay(this.payment_id)
        .then(() => {
          this.query();
        })
        .catch(({ message }) => {
          this.error = message;
        })
        .finally(() => {
          this.loading = false;
          this.cancel();
        });
    },
    cancel() {
      this.payment_id = null;
      this.$bvModal.hide("confirm-modal");
    },
    query(params = {}) {
      if (this.application_id) {
        params["application_id"] = this.application_id;
      }

      params["page"] = this.active_page || 1;
      params["limit"] = this.page_size;

      this.error = null;
      this.loading = true;
      this.data = [];
      this.total_count = 0;

      PaymentsService.query(params)
        .then(({ count, objects }) => {
          if (!count) {
            this.error = "No payments found.";
          }
          this.total_count = count;
          this.data = objects.map((item) => {
            return item;
          });
        })
        .catch(({ message }) => {
          this.error = message
            ? message
            : "There was an error loading payments.";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    total_count(val) {
      this.total_page = Math.ceil(val / this.page_size);
    },
    active_page() {
      this.query();
    },
    application_id() {
      this.active_page = 1;
      this.query();
    },
  },
  mounted() {
    this.query();
  },
};
</script>

<style scoped>
#application-repayments-schedule-container {
  padding: 1rem;
}
#application-repayments-schedule-container .table-title {
  background-color: #f5f3ef;
  border-radius: 5px;
  padding: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}
#application-repayments-schedule-container .table-title-field {
  display: flex;
  align-items: center;
  border-right: 1px solid #e9e6e3;
}
#application-repayments-schedule-container .sort-icon {
  height: 10px;
  margin-left: 0.5rem;
  cursor: pointer;
}
#application-repayments-schedule-container .table-title-text {
  font-size: 11px;
  font-family: Roboto_Bold;
  color: #5e595c;
}
#application-repayments-schedule-container .table-data {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem;
  box-shadow: 0px 1px 4px #17172429;
  align-items: center;
  margin-bottom: 1rem;
}
#application-repayments-schedule-container .due-date {
  font-size: 14px;
  font-family: Roboto_Medium;
  color: #5e595c;
}
#application-repayments-schedule-container .status {
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  font-size: 11px;
  font-family: Roboto_Bold;
}
#application-repayments-schedule-container .status.upcoming {
  background-color: #dfddd9;
  color: #5e595c;
  border: 1px solid #5e595c;
}
#application-repayments-schedule-container .status.overdue {
  background-color: #f7dfe3;
  color: #d91f3c;
  border: 1px solid #d91f3c;
}
#application-repayments-schedule-container .status.paid {
  background-color: #f6fdf4;
  color: #37a928;
  border: 1px solid #37a928;
}
#application-repayments-schedule-container .com-info {
  display: flex;
  align-items: center;
}
#application-repayments-schedule-container .com-name {
  font-size: 14px;
}
#application-repayments-schedule-container .amount-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#application-repayments-schedule-container .due-amount {
  font-size: 14px;
  font-family: Roboto_Bold;
  color: #5e595c;
}
#application-repayments-schedule-container .action-container {
  height: 30px;
  width: 30px;
  border-radius: 8px;
  background-color: #dfddd9;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
#application-repayments-schedule-container .action-menu {
  position: absolute;
  width: 100px;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px #17172429;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 12px;
  right: 12px;
  bottom: -40px;
  z-index: 99999;
}
#application-repayments-schedule-container .action-menu-item {
  cursor: pointer;
  color: #260b19;
  font-size: 13px;
}
</style>
