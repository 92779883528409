import { jwtDecode } from "jwt-decode";


import StorageService from "./storage.service";


class JWTService extends StorageService {
    /**
     * Return the default storage key.
     *
     * @returns {string} The default storage key.
     */
    static get key() {
        return "token";
    }

    /**
     * Return the JWT from the local storage, if any.
     *
     * @returns {string} The JWT.
     */
    static getToken() {
        return sessionStorage.getItem(this.key);
    }

    /**
     * Set the JWT in the local storage.
     *
     * @param {string} token The JWT.
     */
    static setToken(token) {
        sessionStorage.setItem(this.key, token);
    }

    /**
     * Remove the JWT from the local storage.
     */
    static removeToken() {
        sessionStorage.removeItem(this.key);
    }

    /**
     * Check whether the JWT has expired.
     *
     * @returns {boolean} Whether the JWT has expired.
     */
    static isExpired() {
        let token, payload;

        token = this.getToken();

        if (!token)
            return true;

        payload = jwtDecode(token);

        return new Date().getTime() >= new Date(payload.exp * 1000);
    }
}


export default JWTService;
