<template>
  <b-modal id="change-bucket-rating-modal" size="lg" centered hide-header hide-footer>
    <div class="modal-title">New Notification</div>
    <b-alert :show="error && error.length ? true : false" variant="danger">
      <div v-html="error || '&nbsp;'"></div>
    </b-alert>
    <p>Choose the application rating for every bucket from below</p>
    <b-row>
      <b-col lg="6" sm="6">
        <base-select
            label="Unit Economics"
            :options="ratingOptions"
            field="bucket_unit"
            :value="bucket_unit"
            @change-value="setValue">
        </base-select>
      </b-col>
      <b-col lg="6" sm="6">
        <base-input
            type="text"
            label="murabaha_rate"
            field="murabaha_rate"
            :value="murabaha_rate"
            @change-value="setValue"
        >
        </base-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" sm="6">
        <base-select
          label="Growth"
          :options="ratingOptions"
          field="bucket_growth"
          :value="bucket_growth"
          @change-value="setValue">
        </base-select>
      </b-col>
      <b-col lg="6" sm="6">
        <base-input
          label="approved_amount"
          field="max_allowed"
          :value="max_allowed"
          @change-value="setValue"
        >
        </base-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" sm="12">
        <base-select
          label="Churn"
          :options="ratingOptions"
          field="bucket_churn"
          :value="bucket_churn"
          @change-value="setValue">
        </base-select>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" sm="12">
        <base-select
          label="Efficiency"
          :options="ratingOptions"
          field="bucket_efficiency"
          :value="bucket_efficiency"
          @change-value="setValue">
        </base-select>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="6" sm="12">
        <base-select
          label="Financial Position"
          :options="ratingOptions"
          field="bucket_finposition"
          :value="bucket_finposition"
          @change-value="setValue">
        </base-select>
      </b-col>
    </b-row>

    <b-row style="margin-top: 30px">
      <b-col lg="6" sm="6">
        <base-button style_variant="secondary" cus_style="font-size:15px" @onClick="closeModal"> Cancel </base-button>
      </b-col>
      <b-col lg="6" sm="6">
        <base-button style_variant="primary" cus_style="font-size:15px" @onClick="handleSubmit"> Confirm </base-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
const RatingOptions = [
  {
    id: null,
    label: "Select rating",
  },
  {
    id: "EXCELLENT",
    label: "Excellent",
  },
  {
    id: "VERY_GOOD",
    label: "Very Good",
  },
  {
    id: "GOOD",
    label: "Good",
  },
  {
    id: "MODERATE",
    label: "Moderate",
  },
  {
    id: "DIFICIENT",
    label: "Needs Improvement",
  },
];

import ApplicationsService from "@/services/applications.service";
export default {
  name: "change-bucket-rating-modal",
  props: ["application_id", "unit", "growth", "churn", "efficiency", "finposition","max_allow","murabaha"],
  emits: ["bucket-rating-changed"],

  data() {
    return {
      error: null,
      loading: false,
      label: null,
      ratingOptions: RatingOptions,
      bucket_unit: null,
      murabaha_rate:null,
      max_allowed:null,
      bucket_growth: null,
      bucket_churn: null,
      bucket_efficiency: null,
      bucket_finposition: null,
    };
  },

  mounted() {
    this.bucket_unit = this.unit;
    this.bucket_growth = this.growth;
    this.bucket_churn = this.churn;
    this.bucket_efficiency = this.efficiency;
    this.bucket_finposition = this.finposition;
    this.max_allowed = this.max_allow;
    this.murabaha_rate = this.murabaha;
    // console.log(props);
  },

  methods: {
    closeModal() {
      this.$bvModal.hide("change-bucket-rating-modal");
    },
    async handleSubmit() {
      const payload = {
        bucket_unit_economics: this.bucket_unit,
        bucket_growth: this.bucket_growth,
        bucket_churn: this.bucket_churn,
        bucket_efficiency: this.bucket_efficiency,
        bucket_finposition: this.bucket_finposition,
        max_allowed : this.max_allowed,
        murabaha_rate : this.murabaha_rate,
      };
      try {
        await ApplicationsService.changeBucketRating(payload, this.application_id);
        this.$emit("bucket-rate-changed", true);
      } catch (e) {
        this.$emit("bucket-rate-changed", false);
      } finally {
        this.closeModal();
      }
    },
    setValue(val, field) {
      this[field] = val;
    },
  },
};
</script>

<style scoped>
.modal-title {
  font-size: 24px;
  font-family: Roboto_Medium;
  color: #5e595c;
  border-bottom: 2px solid #dfddd9;
  margin-bottom: 2rem;
}
.cursor-grabbing {
  cursor: grabbing !important;
}

.draggable-list > div {
  cursor: pointer;
}
</style>
