export function genericChart(data, details) {
    if (!data) {
      throw new Error("Data is undefined");
    }
    let series= [];
    let tooltip = null;
    let legend = null;


    const hasBothStackedColumnAndBar = ['stacked-column', 'bar'].every(type =>
      data?.columns?.some(column => column?.type?.toLowerCase() === type)
    ) || false;
    
    series = data?.columns?.flatMap((column, index) => {
      const modifiedType =
        column.type.toLowerCase() === "area"
          ? "line"
          : column.type.toLowerCase() === "column"
          ? "bar"
          : column.type.toLowerCase() === "stacked-column"
          ? "bar"
          : column.type.toLowerCase();
  
      return {
        name: column?.label,
        type: modifiedType,
        barWidth: hasBothStackedColumnAndBar?'auto':20,
        stack: column.type.toLowerCase() === "stacked-column" ? "total" : null,
        yAxisIndex: column.axis,
        emphasis: { focus: "series" },
        data: column?.data?.slice(details === false ? -12 : 0),
        itemStyle: {
          color: column.color,
          borderRadius: column.type.toLowerCase() === "stacked-column" ? [0, 0, 0, 0] : [10, 10, 10, 10],
        },
        symbolSize: 6,
        lineStyle: {
          width: 3,
        },
        symbolData: column.symbol,
        ...(column.type.toLowerCase() === "area" && {
          areaStyle: { color: column.color },
          itemStyle: { color: "#0A7FA3" },
          lineStyle: {
            color: "#0A7FA3",
            width: 2,
          },
        }),
      };
    });
  
    tooltip = {
      trigger: "item",
      formatter: function (params) {
        const symbol = data?.columns[params.seriesIndex]?.symbol || "";
        if (symbol == "%" || symbol == "x" || symbol == "Month" || symbol == "Months") {
          return `${params.seriesName}: ${formatTooltipValue(params.value)}${symbol}`;
        } else {
          return `${params.seriesName}: ${symbol}${formatTooltipValue(params.value)}`;
        }
      },
    };
    if (details) {
      legend =
        data.columns.length > 1
          ? { data: data?.columns?.map((column) => column.label).concat(data.name), top: 10 }
          : null;
    }
  
    const formatDate = (dateString)=> {
      const date = new Date(dateString);
      const year = date.getFullYear().toString().slice(-2);
      const month = date.getMonth() + 1;
      return `${month}-${year}`;
    };
  
    function formatNumber(value, suffix) {
      return Number.isInteger(value) ? `${value}${suffix}` : `${value.toFixed(1).replace(/\.0$/, "")}${suffix}`;
    }
  
    function getFormattedValue(value) {
      if (Math.abs(value) >= 1_000_000) {
        return formatNumber(value / 1_000_000, "M");
      } else if (Math.abs(value) >= 1_000) {
        return formatNumber(value / 1_000, "K");
      } else {
        return value.toString();
      }
    }
  
    function formatTooltipValue(value) {
      return getFormattedValue(value);
    }
    function formatAxis(value, axisLabel) {
      const formattedValue = getFormattedValue(value);
      if (axisLabel === "Month" || axisLabel === "Months") {
        return formattedValue;
      }
      if (axisLabel === "%" || axisLabel === "x") {
        return `${formattedValue}${axisLabel}`;
      }
      return `${axisLabel ?? ""}${formattedValue}`;
    }
  
    function formatLtm(value, data) {
      if (!value) return null;
      const formattedValue = getFormattedValue(value);
      const axisLabel = data?.columns?.find((column) => column.axis === data.metric_axis)?.symbol ?? "";
  
      if (axisLabel === "Month" || axisLabel === "Months") {
        return formattedValue;
      }
      if (axisLabel === "%" || axisLabel === "x") {
        return `${formattedValue}${axisLabel}`;
      }
      return `${axisLabel ?? ""}${formattedValue}`;
    }
  
    let dataAverage = data?.columns?.find((column) => column.axis === data.metric_axis)?.averages ?? [];
    let lastAverage = dataAverage?.[dataAverage.length - 1]?.value || null;
    const firstAxisSymbol = data?.columns?.find((column) => column.axis == 0)?.symbol;
    const secondAxisSymbol = data?.columns?.find((column) => column.axis == 1)?.symbol;
  
    const yAxisConfig = data?.columns.some((item) => item.axis == 1)
      ? [
          {
            type: "value",
            name: "",
            nameLocation: "end",
            nameTextStyle: {
              fontSize: 14,
              fontWeight: "bold",
              padding: [0, 0, 10, 0],
            },
            axisLine: { show: true },
            splitLine: {
              show: false,
            },
            axisLabel: {
              formatter: function (value) {
                return formatAxis(value, firstAxisSymbol);
              },
            },
          },
          {
            type: "value",
            name: "",
            nameLocation: "end",
            position: "right",
            nameTextStyle: {
              fontSize: 14,
              fontWeight: "bold",
              padding: [0, 0, 10, 0],
            },
            axisLabel: {
              formatter: function (value) {
                return formatAxis(value, secondAxisSymbol);
              },
            },
            axisLine: { show: true },
            splitLine: {
              show: false,
            },
          },
        ]
      : [
          {
            type: "value",
            name:
              data?.name == "CAC payback period" ||
              data?.name == "Months to Recover CAC" ||
              data?.name == "CAC Payback Period"
                ? "Months"
                : "",
            nameLocation: "center",
            nameRotate: 90,
            nameTextStyle: {
              fontSize: 14,
              fontWeight: "bold",
              padding: [0, 0, 15, 0],
            },
            axisLabel: {
              formatter: function (value) {
                return formatAxis(value, firstAxisSymbol);
              },
            },
            axisLine: {
              show: true,
            },
            splitLine: {
              show: false,
            },
          },
        ];
  
    const option1 = {
      graphic: {
        elements: [
          {
            type: "rect",
            left: "center",
            top: "center",
            shape: {
              width: details ? 1000 : 500,
              height: details ? 400 : 400,
              r: 10,
            },
            style: {
              fill: "#FAFAFA",
              stroke: "#DDDDDE",
              lineWidth: 2,
              borderRadius: 50,
            },
            z: 0,
          },
          {
            type: "text",
            left: "center",
            top: "center",
            style: {
              text: data.columns[0]?.data[0] + (data.columns[0]?.symbol ?? ""),
              fontSize: 60,
              fontWeight: "bold",
              lineDash: [0, 200],
              lineDashOffset: 0,
              fill: "transparent",
              stroke: "#0A7FA3",
              lineWidth: 1,
              textAlign: "center",
            },
            keyframeAnimation: {
              duration: 3000,
              loop: false,
              keyframes: [
                {
                  percent: 0.7,
                  style: {
                    fill: "transparent",
                    lineDashOffset: 200,
                    lineDash: [200, 0],
                  },
                },
                {
                  percent: 0.8,
                  style: {
                    fill: "transparent",
                  },
                },
                {
                  percent: 1,
                  style: {
                    fill: "#0A7FA3",
                  },
                },
              ],
            },
            z: 1,
          },
        ],
      },
    };
    let result  = {
      score: data.label,
      LTM: formatLtm(lastAverage, data),
      name: data.name,
      options:
        data.columns[0].type === "SINGLE_VALUE"
          ? option1
          : {
              tooltip: tooltip,
              xAxis: [
                {
                  type: "category",
                  boundaryGap: !data?.columns.some((item) => item.type == "AREA"),
                  data: (data.columns[0].date?.map((item) => formatDate(item)) || []).slice(
                    details === false ? -12 : 0
                  ),
                  axisLabel: {
                    interval: 0,
                    rotate: 0,
                  },
                  axisTick: {
                    show: true,
                    alignWithLabel: true,
                  },
                  splitLine: {
                    show: false,
                  },
                  axisLine: {
                    onZero: true,
                    onZeroAxisIndex: data.main_axis ?? 0,
                  },
                },
              ],
              yAxis: yAxisConfig,
              series: series,
              legend: legend,
            },
    };
    return result;
  }
  