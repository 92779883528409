<template>
  <div id="company-detail-container">
    <div class="d-inline-block">
      <div class="back-link" @click="goBack">
        <img src="/images/back-icon.png" alt="back-icon" style="margin-right: 0.5rem" />
        <div>All Companies</div>
      </div>
    </div>
    <b-alert :show="error && error.length ? true : false" variant="danger">
      <div v-html="error || '&nbsp;'"></div>
    </b-alert>
    <div v-if="querying">
      <common-loading />
    </div>
    <div v-else-if="company_data">
      <div class="d-flex justify-content-between align-items-center mt-4 mb-4">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center justify-content-center com-avatar" style="margin-right: 1rem">
            <img v-if="company_data.image" :src="company_data.image" alt="com-logo" height="100px" width="100px" />
            <div v-else class="d-flex justify-content-center align-items-center">
              {{ company_data.name.charAt(0).toLocaleUpperCase() }}
            </div>
          </div>
          <div>
            <div class="com-name">{{ company_data.name }}</div>
            <div class="d-flex align-items-center">
              <div
                class="d-flex align-items-center justify-content-center"
                style="margin-right: 0.25rem; height: 20px; width: 20px">
                <img src="/images/Path 840.png" alt="Path 840" />
              </div>
              <div class="com-place">
                {{ company_data.country }}
              </div>
            </div>
            <div class="d-flex align-items-center">
              <div
                class="d-flex align-items-center justify-content-center"
                style="margin-right: 0.25rem; height: 20px; width: 20px">
                <img src="/images/Path 841.png" alt="Path 841" />
              </div>
              <div class="com-email">{{ company_data.email }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isEdit" class="content-table">
        <b-container class="filter-container">
          <b-row>
            <b-col md="11" class="flex-container">
              <div
                v-for="(tab, index) in tabs"
                :key="index"
                @click="selectTab(index)"
                :class="['tab', selectedTab === index ? 'active-tab' : '']">
                {{ tab }}
              </div>
            </b-col>
            <b-col v-if="selectedTab === 1" md="1" class="d-flex align-items-center justify-content-end">
              <div class="action-container" @click="edit">
                <img :src="'/images/edit.png'" :alt="'edit'" height="15px" width="15px" />
              </div>
            </b-col>
          </b-row>
        </b-container>
        <RepaymentsSchedule :company_id="company_id" v-if="selectedTab === 0" />
        <DetailData v-if="selectedTab === 1" :company_data="company_data" />
        <CompanyApplications v-if="selectedTab === 2" :company_name="company_name" :company_id="company_id" />
      </div>
      <div v-if="isEdit" class="edit-data-container">
        <EditDetailData @close-edit-mode="edit" :company_data="company_data" />
      </div>
    </div>
  </div>
</template>

<script>
import CompanyService from "../../services/company.service";
import EnumeratesService from "../../services/enumerates.service";

import RepaymentsSchedule from "./RepaymentsSchedule.vue";
import DetailData from "./DetailData.vue";
import EditDetailData from "./EditDetailData.vue";
import CompanyApplications from "./CompanyApplications.vue";

export default {
  components: {
    RepaymentsSchedule,
    DetailData,
    EditDetailData,
    CompanyApplications,
  },
  data() {
    return {
      countries: [],
      selectedTab: 0,
      isEdit: false,
      tabs: ["Repayments Schedule", "Company Details", "Applications"],
      company_id: null,
      querying: false,
      company_data: null,
      error: null,
      company_name: "",
    };
  },
  methods: {
    changeStatus() {
      this.$bvModal.show("change-status-modal");
    },
    goBack() {
      this.$router.back();
    },
    selectTab(index) {
      this.selectedTab = index;
    },
    edit() {
      this.isEdit = !this.isEdit;
      const element = document.getElementById("content-container");
      if (element) {
        element.scrollTop = 0;
      }
      this.query();
    },
    query() {
      if (this.$route.params.id) {
        this.company_id = this.$route.params.id;
        this.querying = true;

        CompanyService.get(this.$route.params.id)
          .then((data) => {
            if (data.country && this.countries && this.countries.length) {
              data.country = this.countries.find((each) => each.id === data.country).name;
            }
            this.company_data = data;
            this.company_name = data.name;
          })
          .catch(({ message }) => {
            this.error = message ? message : "There was an error loading company.";
          })
          .finally(() => {
            this.querying = false;
          });
      }
    },
  },
  mounted() {
    this.querying = true;
    EnumeratesService.countries()
      .then((data) => {
        if (data && data.length)
          this.countries = data.map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
      })
      .catch(({ message }) => {
        this.error = message;
      })
      .finally(() => {
        this.querying = false;
        this.query();
      });
  },
};
</script>

<style scoped>
#company-detail-container {
  padding: 25px 45px;
}
#company-detail-container .back-link {
  font-size: 13px;
  color: #8f8b8d;
  display: flex;
  align-items: center;
  cursor: pointer;
}
#company-detail-container .com-avatar {
  margin-right: 0.5rem;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #ebdfe5;
  color: #260b19;
  font-size: 24px;
  font-family: Roboto_Medium;
}
#company-detail-container .com-avatar img {
  border-radius: 50%;
}
#company-detail-container .com-name {
  font-size: 18px;
  font-family: Roboto_Bold;
  color: #260b19;
}
#company-detail-container .com-place {
  font-size: 15px;
  color: #8f8b8d;
  margin: 0.2rem 0rem;
}
#company-detail-container .com-email {
  font-size: 15px;
  text-decoration: underline;
  color: #741b47;
}
#company-detail-container .com-state {
  font-size: 11px;
  font-family: Roboto_Bold;
  border-radius: 5px;
  padding: 0rem 0.2rem;
}
#company-detail-container .app-status {
  font-size: 12px;
  font-family: Roboto_Italic;
  color: #5e595c;
  margin: 0.2rem 0rem;
}
#company-detail-container .need-updates {
  font-size: 15px;
  font-family: Roboto_Regular;
  color: #741b47;
  text-decoration: underline;
  width: 160px;
  margin-right: 10px;
  cursor: pointer;
}
#company-detail-container .block {
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
}
#company-detail-container .top-label {
  font-size: 14px;
  font-family: Roboto_Medium;
}
#company-detail-container .middle-label {
  font-size: 16px;
  font-family: Roboto_Bold;
}
#company-detail-container .bottom-label {
  font-size: 13px;
}
#company-detail-container .content-table {
  margin-top: 1.5rem;
  box-shadow: 1px 2px 29px #1717241a;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem;
}
#company-detail-container .flex-container {
  display: flex;
  align-items: center;
  margin: 1rem 0rem;
}
#company-detail-container .tab {
  color: #8f8b8d;
  font-size: 15px;
  font-family: Roboto_Medium;
  margin-right: 2rem;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}
#company-detail-container .tab:hover {
  border-bottom: 2px solid #8f8b8d;
}
#company-detail-container .active-tab {
  color: #741b47;
  border-bottom: 2px solid #741b47;
}
#company-detail-container .action-container {
  height: 30px;
  width: 30px;
  margin-left: 0.5rem;
  border-radius: 8px;
  background-color: #dfddd9;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
#company-detail-container .edit-data-container {
  margin-top: 1.5rem;
  box-shadow: 1px 2px 29px #1717241a;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem;
}
</style>
