import * as Vue from 'vue';
import ResourceService from "./resource.service";
import Client from "@/services/api/api.service"

class PaymentsService extends ResourceService {
  static get resource() {
    return "admin/payments";
  }

  static summary(params) {
    const url = `${this.resource}/summary`;

    return new Promise((resolve, reject) => {
      Client
        .get(url, { params })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static pay(payment_id) {
    const url = `${this.resource}/${payment_id}/pay`;

    return new Promise((resolve, reject) => {
      Client
        .post(url, {})
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default PaymentsService;
