import axios from "axios";
import router from "../../router";
import { JWTService, UserService } from "../../storage";
import { APIError, APIResponse } from "./utils";

const CONTENT_TYPE = "application/json";
const PUBLIC_URLS = ["admin/refresh-token", "admin/verify-token", "admin/login"];

const baseUrl =import.meta.env.VITE_VUE_APP_API_URL
const timeOut =import.meta.env.VITE_VUE_APP_TIMEOUT

const client = axios.create({
  baseURL: baseUrl,
  timeout: timeOut,
  responseType: "json",
  headers: {
    accept: CONTENT_TYPE,
    "content-type": CONTENT_TYPE,
  },
});

// Interceptors
client.interceptors.request.use(
  (config) => {
    const token = JWTService.getToken();
    if (token && !PUBLIC_URLS.includes(config.url)) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(new APIError(error))
);

client.interceptors.response.use(
  (response) => Promise.resolve(new APIResponse(response)),
  async (error) => {
    if (!error.response) {
      return Promise.reject(new APIError(error));
    }
    if (error.response.status === 401 && error.config.url !== "/api/admin/login") {
      try {
        const { data } = await client.post("admin/refresh-token", {
          token: JWTService.getToken(),
        });
        JWTService.setToken(data.token);
        error.config.headers["Authorization"] = `Bearer ${data.token}`;
        return client.request(error.config);
      } catch (refreshError) {
        JWTService.removeToken();
        UserService.removeUser();
        router.push({ name: "login" });
        return Promise.reject(new APIError(refreshError));
      }
    }

    return Promise.reject(new APIError(error));
  }
);

export default client;