<template>
  <b-modal id="change-financial-position-modal" size="lg" centered hide-header hide-footer>
    <div class="modal-title">Financial position</div>
    <b-alert :show="errors && errors.length ? true : false" variant="danger">
      <div v-html="errors || '&nbsp;'"></div>
    </b-alert>
    <p>Please fill the Financial position related data below:</p>
    <b-row>
      <b-col lg="4" sm="12">
        <base-input label="Debt" type="number" field="debit" placeholder="Debt" :value="debit" @change-value="setValue">
        </base-input>
      </b-col>
      <b-col lg="4" sm="12">
        <base-input
          label="Monthly Expenses"
          type="number"
          field="monthly_cost"
          placeholder="Monthly expenses"
          :value="monthly_cost"
          @change-value="setValue"
          :helper_text="monthly_comment ? monthly_comment : ''"></base-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="4" sm="12">
        <base-input
          label="Valuation"
          type="number"
          field="valuation"
          placeholder="Valuation"
          :value="valuation"
          @change-value="setValue"
          :helper_text="valuation_comment ? valuation_comment : ''">
        </base-input>
      </b-col>
      <b-col lg="4" sm="12">
        <base-select
          label="Debt:Valuation rating"
          :options="ratingOptions"
          field="debt2valuation_rating"
          :value="debt2valuation_rating"
          @change-value="setValue">
        </base-select>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="4" sm="12">
        <base-input
          label="Raised amount"
          type="number"
          field="raised_amount"
          placeholder="Raised amount"
          :value="raised_amount"
          @change-value="setValue"
          :helper_text="raised_comment ? raised_comment : ''">
        </base-input>
      </b-col>
      <b-col lg="4" sm="12">
        <base-select
          label="Debt:Equity rating"
          :options="ratingOptions"
          field="debt2equity_rating"
          :value="debt2equity_rating"
          @change-value="setValue">
        </base-select>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="4" sm="12">
        <base-input
          label="Annualized Revenue"
          type="number"
          field="arr"
          placeholder="Annualized revenue"
          :value="arr"
          @change-value="setValue"
          :helper_text="arr_comment ? arr_comment : ''">
        </base-input>
      </b-col>
      <b-col lg="4" sm="12">
        <base-select
          label="Debt:Annualized revenue rating"
          :options="ratingOptions"
          field="debt2arr_rating"
          :value="debt2arr_rating"
          @change-value="setValue">
        </base-select>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="4" sm="12">
        <base-input
          label="Current Runway"
          type="number"
          field="runway"
          placeholder="Runway in months"
          :value="runway"
          @change-value="setValue"
          :helper_text="runway_comment ? runway_comment : ''"></base-input>
      </b-col>
      <b-col lg="4" sm="12">
        <base-select
          label="Runway rating"
          :options="ratingOptions"
          field="runway_rating"
          :value="runway_rating"
          @change-value="setValue">
        </base-select>
      </b-col>
    </b-row>

    <b-row style="margin-top: 30px">
      <b-col lg="6" sm="6">
        <base-button style_variant="secondary" cus_style="font-size:15px" @onClick="closeModal"> Cancel </base-button>
      </b-col>
      <b-col lg="6" sm="6">
        <base-button style_variant="primary" cus_style="font-size:15px" @onClick="handleSubmit"> Confirm </base-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
const RatingOptions = [
  {
    id: null,
    label: "Select rating",
  },
  {
    id: "High performing metric",
    label: "High performing metric",
  },
  {
    id: "Moderate performing metric",
    label: "Moderate performing metric",
  },
  {
    id: "Low performing metric",
    label: "Low performing metric",
  },
];

const RequiredValues = [
  "debit",
  "valuation",
  "raised_amount",
  "arr",
  "runway",
  "monthly_cost",
  "debt2valuation_rating",
  "debt2arr_rating",
  "debt2equity_rating",
  "runway_rating",
  "max_allowed",
  "requested",
];

const copy_values = (dst, src, values) => {
  values.forEach((attr) => {
    if (attr in src) dst[attr] = src[attr];
  });
};

import ApplicationsService from "@/services/applications.service";
export default {
  name: "change-financial-position-modal",
  props: ["application_data"],
  emits: ["financial-data-changed"],

  data() {
    return {
      errors: null,
      loading: false,

      ratingOptions: RatingOptions,

      debit: null,
      valuation: null,
      raised_amount: null,
      arr: null,
      monthy_costs: null,
      runway: null,
      debt2valuation_rating: null,
      debt2arr_rating: null,
      debt2equity_rating: null,
      runway_rating: null,
      monthly_cost: null,

      max_allowed: null,
      requested: null,

      raised_comment: null,
      arr_comment: null,
      valuation_comment: null,
      runway_comment: null,
      monthly_comment: null,
    };
  },

  mounted() {
    copy_values(this, this.application_data, RequiredValues);

    this.calc_ratios();
  },

  methods: {
    closeModal() {
      this.$bvModal.hide("change-financial-position-modal");
    },
    async handleSubmit() {
      const payload = {};
      copy_values(payload, this, RequiredValues);

      try {
        await ApplicationsService.changeFinancialInfo(this.application_data.id, payload);
        this.$emit("refresh-page", true);
        this.closeModal();
      } catch (e) {
        this.errors = e;
      }
    },
    setValue(val, field) {
      this[field] = val;
      this.calc_ratios();
    },

    calc_ratios() {
      if (this.debit) {
        let r = this.valuation ? ((100 * this.debit) / this.valuation).toFixed(0) + "%" : "N/A";
        this.valuation_comment = `Debt:Valuation ${r}`;

        r = this.raised_amount ? ((100 * this.debit) / this.raised_amount).toFixed(0) + "%" : "N/A";
        this.raised_comment = `Debt:Equity ${r}`;

        r = this.arr ? ((100 * this.debit) / this.arr).toFixed(0) + "%" : "N/A";
        this.arr_comment = `Debt:Annaulized revenue ${r}`;
      }

      if (this.monthly_cost) {
        let nr = Number(this.runway) + Math.round(this.requested / this.monthly_cost);
        this.runway_comment = `New runway ${nr}: requested ${this.requested} at monthly burn rate ${this.monthly_cost}`;
      }

      this.monthly_comment = (this.monthly_cost ? "Set" : "Leave") + " this to empty to disable the cash position";
    },
  },
};
</script>

<style scoped>
.modal-title {
  font-size: 24px;
  font-family: Roboto_Medium;
  color: #5e595c;
  border-bottom: 2px solid #dfddd9;
  margin-bottom: 2rem;
}
.cursor-grabbing {
  cursor: grabbing !important;
}

.draggable-list > div {
  cursor: pointer;
}
</style>
