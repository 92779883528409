<template>
  <div class="step-three">
    <div class="step-three-wrapper">
      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>
      <b-alert :show="errorMessage && errorMessage.length ? true : false" variant="danger">
        <div v-html="errorMessage || '&nbsp;'"></div>
      </b-alert>
    </div>
    <div class="data-list" v-if="managementSystem">
      <div>
        <div class="wrapper-key-value">
          <div class="key-value-container">
            <div class="key-value">
              <div class="key">Monthly Management Data:</div>
              <div class="value">
                {{ dataAccounting.mappingData.fileName }}
              </div>
              <img
                src="/images/downloadButton.svg"
                alt="Download"
                class="download-img"
                @click="downloadFile(dataAccounting.fileUrl)" />
            </div>

            <div class="key-value">
              <div class="key">Mapping:</div>
              <div class="value">Mapping</div>
              <img
                src="/images/downloadButton.svg"
                alt="Download"
                class="download-img"
                @click="downloadFile(dataAccounting.mappingUrl)" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="wrapper-key-value">
        <div class="key-value-container">
          <div class="key">System Name</div>
          <div class="value">
            {{ dataAccounting.system_name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OnboardingService from "@/services/onboarding.service";
import { ErrorMessages } from "../../lib/errorMessages";

export default {
  components: {},
  name: "accounting_step",
  props: ["data"],
  data() {
    return {
      error: null,
      errorMessage: null,
      dataAccounting: {},
      managementSystem: false,
    };
  },
  methods: {
    downloadFile(path) {
      if (!path) return;
      let payload = { file: path };
      OnboardingService.download(payload)
        .then(({ data }) => {
          if (data) {
            window.open(data, "_blank");
          }
        })
        .catch(({ message }) => {
          this.errorMessage = ErrorMessages.errorDownloading;
        });
    },
  },
  mounted() {
    if (!this.data.accounting_step) {
      this.error = ErrorMessages.noData;
    } else {
      const firstData = this.data.accounting_step[0]?.data;
      this.managementSystem = firstData?.mappingData ?? null;
      this.dataAccounting = firstData ?? null;
      if (this.managementSystem) {
        this.dataAccounting.mappingUrl = this.data.files.FinancialConfig ?? null;
        this.dataAccounting.fileUrl = this.data.files.MonthlyManagement ?? null;
        this.managementSystem = true;
      }
    }
  },
};
</script>

<style scoped>
.step-three {
  padding: 2rem 1rem;
}

.wrapper-key-value {
  display: flex;
  justify-content: center;
  align-items: center;
}

.key-value-container {
  width: 670px;
  padding: 32px 24px 32px 24px;
  gap: 16px;
  border-radius: 8px;
  background-color: #ffffff;
}

.key-value {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 0px 8px 0px;
}

.key {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #394044;
  word-break: break-all;
}

.value {
  width: 100%;
  background-color: #daf3f1;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #024058;
  padding: 10px 16px 10px 16px;
  border-radius: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

img {
  width: 50px;
  height: 50px;
}

.step-three-wrapper {
  padding-top: 20px;
}

.data-list {
  font-family: "Arial", sans-serif;
  padding: 20px;
}

.data-item {
  display: flex;
  margin-bottom: 10px;
  background: white;
  padding: 6px 16px 8px 16px;
  border-radius: 20px;
  font-size: 13px;
  cursor: pointer;
  justify-content: space-between;
  width: 30%;
}
</style>
