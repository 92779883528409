import ResourceService from "./resource.service";
import Client from "@/services/api/api.service"

class SettingsCharts extends ResourceService {
  static get resource() {
    return "admin";
  }
  static chartTypes() {
    return SettingsCharts.get("settings/charts/types");
  }

  static industries() {
    return SettingsCharts.get("settings/industries");
  }

  static chartColors() {
    return SettingsCharts.get("settings/charts/colors");
  }
  static getCharts(industry ,params) {
    return SettingsCharts.get(`settings/industries/${industry}/charts${params}`);
  }
  static getChartDetails(params) {
    return SettingsCharts.get(`settings/charts/${params}`);
  }
  static getMetricsOption(params) {
    return SettingsCharts.get(`settings/industries/${params}/metrics`);
  }
  static getSegmentOption(params) {
    return SettingsCharts.get(`settings/industries/${params}/segments`);
  }
  static getBucketsOption(params) {
    return SettingsCharts.get(`settings/industries/${params}/buckets`);
  }
  static addNewChart(payload) {
    
    return SettingsCharts.post(payload,`settings/charts`);
  }
  static updateChart(params,payload) {
    return SettingsCharts.put(`settings/charts/${params}`,payload,);
  }
  static deleteChart(params,payload) {
    // return SettingsCharts.delete(`settings/charts/${params}`,payload,);
    return {msg:true}
  }
  static getCompanies() {
    return SettingsCharts.get(`search_company`);
  }
  static getApplications(params) {
    return SettingsCharts.get(`search_appliction/${params}`);
  }
  static getApplicationData(params) {
    return SettingsCharts.get(`settings/get_application_chart/${params}/chart`);
  }

  static getApps(payload) {
    return SettingsCharts.post(payload,`settings/application_metrics`);
  }

  static getPreviewData(payload) {
    return SettingsCharts.post(payload,`settings/charts`);
  }
 
}
export default SettingsCharts;
