<template>
  <div class="header" v-if="totalAmount">
    <div class="header-title">Total Amount Raised</div>
    <div class="header-amount">{{ formatNumber(totalAmount) }}</div>
    <v-chart
      id="myCanvas"
      class="chartProgress"
      :option="progressBarOptions"
      autoresize
    />
    <div class="info">
      <EllipseSvg color="#0A7FA3" />
      <div class="info-title">Returned to investor:</div>
      <div class="info-amount">{{ formatNumber(investorsAmount) }}</div>
      <div class="info-profit">
        {{ "+ " + formatNumber(profitAmount) + "  upside" }}
      </div>
    </div>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";

import VChart from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

import EllipseSvg from "@/components/common/ellipseSvg.vue";
import { formatNumber } from "@/utils/numberUtils";

export default {
  name: "TotalRaisedPage",
  components: { VChart, EllipseSvg },
  props: ["totalAmount", "investorsAmount", "profitAmount"],
  data() {
    return {
      progressBarOptions: {
        grid: {
          top: "0",
          left: "0",
          bottom: "0",
          right: "0",
          containLabel: false,
        },
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            const formattedValue = this.formatNumber(params.value);
            return `${params.seriesName}: ${formattedValue}`;
          },
        },
        xAxis: {
          type: "value",
          show: false,
        },
        yAxis: {
          type: "category",
          show: false,
        },
        series: [
          {
            name: "Returned to investor",
            type: "bar",
            stack: "total",
            barWidth: 30,
            itemStyle: {
              color: "#0A7FA3",
              borderRadius: [15, 0, 0, 15],
            },
            emphasis: {
              itemStyle: {
                color: "#0A7FA3",
              },
            },
            data: [parseInt(this.investorsAmount)],
          },
          {
            name: "Total amount raised",
            type: "bar",
            stack: "total",
            barWidth: 30,
            itemStyle: {
              color: "#DAF3F1",
              borderRadius: [0, 15, 15, 0],
            },

            emphasis: {
              itemStyle: {
                color: "#DAF3F1",
              },
            },
            data: [parseInt(this.totalAmount)],
          },
        ],
      },
    };
  },
  methods: {
    formatNumber,
  },
  mounted() {},
};
</script>

<style scoped>
.info-profit {
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
  background: #e7ffe5;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
}
.header-amount {
  font-size: 32px;
  font-weight: 700;
  line-height: 35.2px;
  color: #071015;
  margin-top: 16px;
}
.header-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: #071015;
}
.info-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  color: #071015;
}
.info-amount {
  font-size: 14px;
  font-weight: 700;
  line-height: 18.2px;
  color: #071015;
}
.info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
}
.chartProgress {
  height: 80px;
}
</style>
