<template>
  <div>
    <div class="wrapper py-4">
      <div class="container-wrapper">
        <div class="formula-container">
          <div>
            <div class="numerator">
              <div class="formula-content">
                <div class="formula-line" v-html="formattedFormula"></div>
              </div>
            </div>
            <div class="fraction-line" v-if="props.denominatorInput"></div>
            <div class="denominator" v-if="props.denominatorInput">
              <div class="formula-content">
                <div class="formula-line" v-html="formattedDenominator"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="multiplyByHundred" class="x100"><span class="font-weight-600">*</span> {{ multiplyByHundred }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
const props = defineProps({
  numeratorInput: String,
  denominatorInput: String,
  multiplyByHundred: String,
});

const formattedFormula = ref("");
const formattedDenominator = ref("");
const multiplyByHundred = ref(props.multiplyByHundred);

const formatFormula = () => {
  formattedFormula.value = props.numeratorInput?.replace(/\(([^)]+)\/([^)]+)\)/g, (_, numerator, denominator) => {
    return `<span class="parenthesis">(</span><span class="fraction"><span class="numerator">${numerator}</span><div class="divider1"></div><span class="denominator">${denominator}</span></span><span class="parenthesis">)</span>`;
  });
};

const formatDenominator = () => {
  formattedDenominator.value = props.denominatorInput?.replace(/\(([^)]+)\/([^)]+)\)/g, (_, numerator, denominator) => {
    return `<span class="parenthesis">(</span><span class="fraction"><span class="numerator">${numerator}</span><div class="divider1"></div><span class="denominator">${denominator}</span></span><span class="parenthesis">)</span>`;
  });
};

watch(() => props.numeratorInput, formatFormula);
watch(() => props.denominatorInput, formatDenominator);
watch(
  () => props.multiplyByHundred,
  (newVal) => {
    multiplyByHundred.value = newVal;
  }
);

formatFormula();
formatDenominator();
</script>

<style scoped>
.font-weight-600 {
  font-weight: 600;
}
.container-wrapper {
  border-right: 4px solid #aaaaaa;
  border-left: 4px solid #aaaaaa;
  border-radius: 55px;
  padding-inline: 45px;
  max-width: 900px;
  width: fit-content;
}
.wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.formula-line {
  font-family: Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20.8px;
  letter-spacing: 0%;
  text-align: center;
  color: #071015;
  display: flex;
  align-items: center;
  gap: 8px;
}

.fraction {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-align: center;
}
:deep(.numerator) {
  display: block;
  text-align: center;
}

:deep(.divider1) {
  width: 100%;
  height: 2px;
  background-color: #aaaaaa;
  margin: 2px 0;
}

:deep(.parenthesis) {
  color: #aaaaaa;
}

:deep(.denominator) {
  display: block;
  text-align: center;
}
.input .formula-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-family: Arial, sans-serif;
  max-width: fit-content;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.numerator,
.denominator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
}

.fraction-line {
  width: 100%;
  height: 2px;
  background-color: #aaaaaa;
  margin: 10px 0;
}

input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
}

.formula-line button {
  background: #ff4444;
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  padding: 0;
}

.multiplier {
  font-size: 1.2em;
  margin-top: 10px;
  font-weight: bold;
}
</style>
