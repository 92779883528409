<template >
    <div id="SubmitStatusHeader">
        <h3>Applications Details</h3>
        <b-row style="padding: 0rem 0.5rem">
            <b-col style="padding: 0rem 0.25rem">
                <div class="block" style="background-color: #bab8b9; color: #260b19">
                    <div class="label">INIT</div>
                    <div class="value"> {{data.INIT || 0}}</div>
                </div>
            </b-col>
            <b-col style="padding: 0rem 0.25rem">
                <div class="block" style="background-color: #e8dff5; color: #411884">
                    <div class="label">PROCESSING</div>
                    <div class="d-flex align-items-center">
                        <img src="/images/qualified-icon.png" alt="qualified-icon" style="margin-right: 0.5rem" />
                        <div class="value">{{data.PROCESSING || 0}}</div>
                    </div>
                </div>
            </b-col>
            <b-col style="padding: 0rem 0.25rem">
                <div class="block" style="background-color: #fae3d4; color: #cc4e00">
                    <div class="label">SUCCESS</div>
                    <div class="d-flex align-items-center">
                        <img src="/images/not-qualified-icon.png" alt="not-qualified-icon" style="margin-right: 0.5rem" />
                        <div class="value">{{data.SUCCESS || 0}}</div>
                    </div>
                </div>
            </b-col>
            <b-col style="padding: 0rem 0.25rem">
                <div class="block" style="background-color: #dfddd9; color: #5e595c">
                    <div class="label">FAILED</div>
                    <div class="d-flex align-items-center">
                        <img src="/images/execution-icon.png" alt="execution-icon" style="margin-right: 0.5rem" />
                        <div class="value">{{data.FAIL || 0}}</div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>


<script>
export default {
    name: "SubmitStatusHeader",
    props: {
    data: Object, 
  },
};
</script>

<style>

#SubmitStatusHeader {
    padding: 1rem 1rem;
}

#SubmitStatusHeader .block {
    padding: 10px 20px;
    margin-bottom: 1rem;
    border-radius: 5px;
}

#SubmitStatusHeader .label {
    font-size: 14px;
    font-family: Roboto_Medium;
}

#SubmitStatusHeader .value {
    font-size: 16px;
    font-family: Roboto_Bold;
}
</style>