<script setup>
import { onMounted, reactive, watch } from "vue";
import VueMultiselect from "vue-multiselect";
import MetricDetails from "./MetricDetails.vue";
import MetricService from "@/services/metric.service";

const fetchIndustryOptions = async () => {
  try {
    state.loading = true;
    let result = await MetricService.industries();
    state.industryOptions = result?.map((item) => {
      return { name: item, industry: item };
    });
  } catch (e) {
    console.log("eeee", e);
  } finally {
    state.loading = false;
  }
};

const fetchMetric = async () => {
  if (!state.industry) return;

  const params = new URLSearchParams();
  params.append("industry", state?.industry?.industry);
  try {
    state.loading = true;
    let result = await MetricService.getMetrics(params);
    state.metricOptions = [
      ...(result?.data?.measurements?.map((item) => ({
        name: item.name + " - From Metric (data only)",
        metric: item.id,
      })) || []),
      ...(result?.data?.metrics?.map((item) => ({
        name: item.name + " - From Metric info has benchmark (" + item.is_metric + ")",
        metric: item.id,
      })) || []),
    ];
    state.allData = [
      ...(result?.data?.measurements || []),
      ...(result?.data?.metrics || []),
    ];
  } catch (e) {
    console.log("eeee", e);
  } finally {
    state.loading = false;
  }
};

const state = reactive({
  industry: null,
  metricType: null,
  metricOptions: null,
  industryOptions: null,
  allData: null,
  selectedMetric: null,
  loading: false,
  newMetric: false,
});
watch(
  () => state.industry,
  (newValue) => {
    if (newValue) {
      fetchMetric();
      state.metricType = null;
    }
  }
);
watch(
  () => state.metricType,
  (newValue) => {
    if (newValue) {
      state.selectedMetric = state.allData.find((item) => item.id == newValue.metric);
      state.newMetric = false;
    }
  }
);
onMounted(() => {
  fetchIndustryOptions();
});

function addNewMetric() {
  state.metricType = null;
  state.selectedMetric = null;
  state.newMetric = true;
}
</script>

<template>
  <div class="main-container">
    <div v-if="state.loading" class="loader-overlay">
      <common-loading />
    </div>
    <div class="form-container">
      <div class="form-group">
        <label for="industry" class="form-label">Industry *</label>
        <VueMultiselect
          v-if="state.industryOptions"
          v-model="state.industry"
          :options="state.industryOptions"
          placeholder="Select industry"
          label="name"
          track-by="industry"
          :multiple="false"
          aria-label="Select an industry"
        />
      </div>
      <button v-if="state.industry" class="standard-btn" @click="addNewMetric">
        Add New Metric
      </button>
      <div class="form-group" v-if="state.industry && state.metricOptions">
        <label for="Metric" class="form-label">Select Metric *</label>
        <VueMultiselect
          v-model="state.metricType"
          :options="state.metricOptions"
          placeholder="Select metric type"
          label="name"
          track-by="metric"
          :multiple="false"
          aria-label="Select a metric type"
        />
      </div>

      <div v-if="(state.industry && state.metricType) || state.newMetric">
        <MetricDetails
          :metricType="state.metricType"
          :industry="state.industry"
          :selectedMetric="state.selectedMetric"
          :allData="state.allData"
        />
      </div>
    </div>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.disable {
  opacity: 0.6;
  pointer-events: none;
  cursor: not-allowed;
}
.standard-btn {
  height: 36px;
  padding: 8px 10px 8px 10px;
  border-radius: 6px;
  background-color: #024058;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.chart-container {
  width: 1000px;
}
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
}
.form-container {
  width: 70%;
}
.mapping-label {
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
  color: #394044;
  margin-bottom: 8px;
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  z-index: 1000;
}
</style>
