const state = {
  onboardingTab: "all",
  activePages: {
    all: 1,
    pending: 1,
    rejected: 1,
    ineligible: 1,
    deleted: 1,
    applied: 1,
  },
};

const mutations = {
  setOnboardingTab(state, tab) {
    state.onboardingTab = tab;
  },
  setActivePage(state, { tab, page }) {
    state.activePages[tab] = page;
  },
};

const actions = {
  updateOnboardingTab({ commit }, tab) {
    commit("setOnboardingTab", tab);
  },
  updateActivePage({ commit }, { tab, page }) {
    commit("setActivePage", { tab, page });
  },
};

const getters = {
  getOnboardingTab: (state) => state.onboardingTab,
  getActivePage: (state) => (tab) => state.activePages[tab],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
