<template>
  <div v-if="!errorMessage" class="container-details">
    <div v-if="querying">
          <common-loading />
    </div>
    <SubmitStatusHeader :data="data" />
    <SubmitStatus />
  </div>
</template>

<script>
import SubmitStatusHeader from './SubmitStatusHeader.vue';
import SubmitStatus from './SubmitStatus.vue';
import ApplicationsService from '../../services/applications.service';

export default {
  name: "applications-details",
  components: { SubmitStatusHeader, SubmitStatus },
  data() {
    return {
      message: '',
      querying: false,
      data: {},
      errorMessage:null,
    }
  },
  methods: {
    query() {
      this.querying = true;
      ApplicationsService.getAnalysisSummary()
        .then(({ data }) => {
            this.data = data;
        })
        .catch((e) => {
          this.errorMessage="something went wrong !"
        })
        .finally(() => {
          this.querying = false;
        });
    },
  },
  mounted() {
    this.querying = true;
        this.query();
        this.querying = false;
  }
};
</script>
<style>
.container-details{
    /* padding: 60px 70px; */
}
</style>
