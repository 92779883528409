<template>
  <div id="stepOne">
    <div class="wrapper-key-value">
      <div class="key-value-container">
        <div v-for="(value, key) in stepData" :key="key" class="key-value">
          <div class="key">{{ stepFields[key] }}</div>
          <div class="value" :class="{ 'wd-75': key == 'businessWebsite' }">
            {{ formatValue(key, value) }}
          </div>
          <img src="/images/goto.svg" alt="" v-if="key == 'businessWebsite'" @click="goTo(value)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const STEPFIELDS = {
  dateOfIncorporation: "Date of incorporation :",
  creationDate: "Application date :",
  businessType: "Industry :",
  businessWebsite: "Business website :",
  businessName: "Business name :",
  debt: "Any outstanding debt ?",
  runway: "Runway in months :",
  eligible: "Eligible :",
  isSharia: "Shariah compliant facility preference ?",
  isSoftware: "Tech company?",
  companyStage: "Company stage :",
  activeContracts: "Number of active users:",
  investmentRounds: "Number of investment rounds to date :",
  ARRYearlyRevenue: "Annualized revenue :",
  placeOfOperation: "Place of operation :",
  countryOfIncorporation: "Country of incorporation (HQ) :",
  requestedAmount: "Requested amount :",
  phoneNumber: "Phone number :",
  name: "Applicant name :",
  email: "Email address :",
  entertainment: "Entertainment ?",
  debtAmount: "Debt amount :",
  institutionalInvestors: "Number of institutional investors in the capital :",
  raisedAmount: "Amount raised :",
};
export default {
  name: "eligibility_step",
  props: ["data"],
  data() {
    return {
      stepData: {},
      stepFields: STEPFIELDS,
    };
  },
  methods: {
    formatValue(key, value) {
      if (value === null || value === undefined) {
        return "";
      }
      if (["dateOfIncorporation", "creationDate"].includes(key)) {
        return new Date(value).toISOString().substring(0, 10);
      }
      if (key === "ARRYearlyRevenue" || key === "requestedAmount" || key === "debtAmount" || key === "raisedAmount") {
        return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
      }

      if (typeof value !== "string" && Array.isArray(value)) {
        return value.map((obj) => obj.name).join(", ");
      } else {
        return value.toString();
      }
    },
    goTo(link) {
      window.location.href = link;
    },
  },
  watch: {
    data: {
      handler(newApplication) {
        let sd = {
          name: newApplication.name,
          email: newApplication.email,
          phoneNumber: newApplication.phone_number,
          creationDate: newApplication.created_at,
          ...newApplication.eligibility_step,
        };

        this.stepData = {};
        for (const k in sd) {
          if (k in this.stepFields) this.stepData[k] = sd[k] === null ? "N/A" : sd[k];
        }
        if ("debt" in this.stepData && this.stepData["debt"].toLowerCase() == "no") delete this.stepData["debtAmount"];
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.wrapper-key-value {
  display: flex;
  justify-content: center;
  align-items: center;
}
.key-value-container {
  width: 670px;
  padding: 32px 24px 32px 24px;
  gap: 16px;
  border-radius: 8px;
  background-color: #ffffff;
}

.key-value {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 0px 8px 0px;
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  z-index: 1000;
}
.data-list {
  font-family: "Arial", sans-serif;
  padding: 20px;
}

.data-item {
  display: flex;
  margin-bottom: 10px;
  background: white;
  padding: 16px;
  border-radius: 20px;
}

.key {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #394044;
}

.value {
  width: 100%;
  background-color: #daf3f1;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #024058;
  padding: 10px 16px 10px 16px;
  border-radius: 8px;
}
.position-rel {
  position: relative;
}
.action-menu {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px #17172429;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 12px;
  z-index: 99999;
}
.action-menu-item {
  cursor: pointer;
  color: #260b19;
  font-size: 13px;
}
.action-container {
  height: 30px;
  width: 30px;
  margin-left: 0.5rem;
  border-radius: 8px;
  background-color: #dfddd9;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.accept {
  color: rgb(102, 170, 34);
}
.declined {
  color: #741b47;
}
.delete {
  color: #741b47;
}

.refresh-btn {
  border-radius: 6px;
  text-transform: capitalize;
  padding: 10px 20px;
  width: -webkit-fill-available;
  cursor: pointer;
  font-family: Roboto_Regular;
  background-color: #0A7FA3;
    color: #B4E7E2;
    border: 1px solid #0A7FA3;
}

#stepOne {
  padding: 2rem 1rem;
}

#stepOne .top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0rem;
}

#stepOne .content-table {
  margin-top: 1.5rem;
  box-shadow: 1px 2px 29px #1717241a;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem;
}

#stepOne .table-container {
  padding: 0.5rem;
}

#stepOne .table-title {
  background-color: #f5f3ef;
  border-radius: 5px;
  padding: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}

#stepOne .table-title-field {
  display: flex;
  align-items: center;
  border-right: 1px solid #e9e6e3;
}

#stepOne .sort-icon {
  height: 10px;
  margin-left: 0.5rem;
  cursor: pointer;
}

#stepOne .table-title-text {
  font-size: 11px;
  font-family: Roboto_Bold;
  color: #5e595c;
}

#stepOne .table-data {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem;
  box-shadow: 0px 1px 4px #17172429;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
}

#stepOne .com-info {
  display: flex;
  align-items: center;
}

#stepOne .com-name {
  font-size: 15px;
  font-family: Roboto_Bold;
}

#stepOne .com-country {
  font-size: 13px;
  color: #8f8b8d;
}

#stepOne .link-s3 {
  font-size: 13px;
  color: blue;
  font-weight: 600;
}

#stepOne .bottom-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}
.wd-75 {
  width: 75%;
}
</style>
