<template>
  <div>
    <v-chart class="chart" :option="option" autoresize />
    <div class="p-4 m-t-130">
      <div class="info">
        <EllipseSvg color="#0A7FA3" />
        <div class="info-title">From investors:</div>
        <div class="info-amount">
          {{ formatNumber(unutilizedInvestorAmount) }}
        </div>
      </div>
      <div class="info mt-2">
        <EllipseSvg color="#B4E7E2" />
        <div class="info-title">From facilities:</div>
        <div class="info-amount">
          {{ formatNumber(unutilizedFacilityAmount) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";

import VChart from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

import EllipseSvg from "@/components/common/ellipseSvg.vue";
import { formatNumber } from "@/utils/numberUtils";

export default {
  name: "UnutilizedPage",
  components: { VChart, EllipseSvg },
  props: ["unutilizedInvestorAmount", "unutilizedFacilityAmount"],
  data() {
    return {
      option: {
        title: [
          {
            text: "Unutilized",
            left: "center",
            top: "25%",
            textStyle: {
              color: "#071015",
              fontSize: 20,
            },
          },
          {
            text: this.formatNumber(
              parseInt(this.unutilizedInvestorAmount) +
                parseInt(this.unutilizedFacilityAmount)
            ),
            left: "center",
            top: "35%",
            textStyle: {
              color: "#071015",
              fontSize: 20,
            },
          },
        ],
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            return `${params.name}: 0`;
          },
        },
        series: [
          {
            type: "pie",
            radius: ["55%", "70%"],
            center: ["50%", "45%"],
            startAngle: 180,
            endAngle: 360,
            avoidLabelOverlap: false,
            padAngle: 5,
            itemStyle: {
              borderRadius: 0,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              itemStyle: {
                color: "#DAF3F1",
              },
            },
            data: [
              {
                value: 0,
                name: "from Investors",
                itemStyle: { color: "#0A7FA3" },
              },
              {
                value: 100,
                name: "from Facilities",
                itemStyle: { color: "#DAF3F1" },
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    formatNumber,
  },
  mounted() {},
};
</script>

<style scoped>
.header-amount {
  font-size: 32px;
  font-weight: 700;
  line-height: 35.2px;
  color: #071015;
  margin-top: 16px;
}
.header-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: #071015;
}
.info-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  color: #071015;
}
.info-amount {
  font-size: 14px;
  font-weight: 700;
  line-height: 18.2px;
  color: #071015;
}
.info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
}
.chart {
  height: 300px;
  position: absolute;
  top: 0;
  left: 0;
}
.m-t-130 {
  margin-top: 130px;
}
</style>
