<template>
  <div class="modal">
    <div class="modal-content">
      <h3>{{ selectedConfig.id ? "Edit Service Config" : "Add Service Config" }}</h3>
      <form @submit.prevent="save">
        <div class="form-group">
          <label for="service_name">Service Name</label>
          <input
              type="text"
              id="service_name"
              v-model="selectedConfig.service_name"
              required
          />
        </div>
        <div class="form-group">
          <label for="validation_keys">Validation Keys</label>
          <VJsoneditor
              v-model="selectedConfig.validation_keys"
              :options="{ mode: 'code' }"
          />
        </div>
        <div class="form-group">
          <label for="configuration">Configuration</label>
          <VJsoneditor
              v-model="selectedConfig.configuration"
              :options="{ mode: 'code' }"
          />
        </div>
        <div class="form-actions">
          <button type="submit">Save</button>
          <button type="button" @click="$emit('close')">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import VJsoneditor from 'v-jsoneditor';

export default {
  name: "ServiceConfigModal",
  components: { VJsoneditor },
  props: {
    selectedConfig: {
      type: Object,
      default: () => ({
        service_name: "",
        validation_keys: {},
        configuration: {},
      }),
    },
  },
  methods: {
    save() {
      this.$emit("save", this.selectedConfig);
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 500px;
}
.form-group {
  margin-bottom: 15px;
}
.form-actions {
  display: flex;
  justify-content: space-between;
}
</style>