<template>
  <div class="wrapper">
    <h3 class="text-center">Settings</h3>

    <div class="settings-wrapper">
      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>
      <b-row>
        <b-col lg="12" sm="12" class="py-4">
          <label class="form-label labelText" for="raised"
            >Facility Raised</label
          >
          <base-input
            placeholder="Facility Raised"
            type="text"
            field="raised"
            :value="form.raised"
            @change-value="setValue"
            :disabled="loading"
            :is_invalid="v$?.raised?.$invalid && v$?.raised?.$dirty"
            :helper_text="
              v$?.raised?.$error ? v$?.raised?.$errors[0].$message : ''
            "
          ></base-input>
        </b-col>

        <b-col lg="12" sm="12">
          <base-button
            style_variant="primary"
            cus_style="font-size:15px"
            @onClick="submit"
          >
            SUBMIT
          </base-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { reactive } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, numeric } from "@vuelidate/validators";
import SettingsService from "@/services/settingsDashboard.service";
import { ErrorMessages } from "@/lib/errorMessages";
export default {
  name: "settings",
  components: {},
  setup() {
    const form = reactive({
      raised: null,
    });

    const rules = {
      raised: { required, numeric },
    };

    const v$ = useVuelidate(rules, form);
    const loading = reactive(false);
  
    const setValue = (val, field) => {
      form[field] = val;
    };
    return { form, v$, loading, setValue };
  },
  data() {
    return {
      errors: null,
      error: null,
    };
  },
  methods: {
    updateFacilityRaised() {
      SettingsService.updateFacilityRaised(this.form.raised)
        .then((data) => {
          this.form.raised = data.raised_value;
        })
        .catch(({ message }) => {
          this.error = ErrorMessages.errorUploading;
        });
    },
    settings() {
      SettingsService.settings()
        .then((data) => {
          this.form.raised = data?.raised_value;
        })
        .catch(({ message }) => {
          this.error = ErrorMessages.errorLoading;
        });
    },
    async submit() {
      // TODO: ADD overlay loading
      this.v$.$touch();
      if (this.v$.$invalid) return;
      this.updateFacilityRaised();
    },
  },
  mounted() {
    this.settings();
  },
};
</script>
<style scoped>
.settings-wrapper {
  padding: 50px;
  background-color: #ffff;
  border-radius: 20px;
}
.wrapper {
  padding: 80px;
}
.labelText {
  font-size: 15px;
  color: #5e595c;
  margin-bottom: 5px;
}
.helper-text {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}
.form-control:focus-visible {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}
.form-control:hover {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}
.form-control:focus {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}
</style>
