<template>
  <div class="p-4 d-flex h-800 gap-4">
    <div class="chart">
      <v-chart :option="chartStage"></v-chart>
      <div class="stages-wrapper">
        <div
          v-for="(stage, index) in stages"
          :key="index"
          class="funnel-info-container py-2"
        >
          <div class="funnel-info">
            <div class="d-flex align-items-center gap-2">
              <EllipseSvg :color="stageColors[index]" />
              <div class="info-title">{{ stage.name }}</div>
            </div>
            <div class="info-amount">{{ stage.value }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="chart">
      <v-chart :option="chartCategory"></v-chart>
      <div class="categories-wrapper">
        <div
          v-for="(category, index) in categories"
          :key="index"
          class="funnel-info-container py-2"
        >
          <div class="funnel-info">
            <div class="d-flex align-items-center gap-2">
              <EllipseSvg :color="categoryColors[index]" />
              <div class="info-title">{{ category.name }}</div>
            </div>
            <div class="info-amount">{{ category.value }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
]);

import EllipseSvg from "@/components/common/ellipseSvg.vue";

export default {
  name: "CompanyStage",
  components: { VChart, EllipseSvg },
  props: ["stages", "categories"],
  data() {
    return {
      categoriesData: [],
      chartStage: {
        title: {
          text: "By Company Stages",
          padding: [24, 24],
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
        },
        series: [
          {
            type: "pie",
            radius: [40, 120],
            center: ["50%", "40%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 8,
            },
            data: this.stages,
          },
        ],
      },
      chartCategory: {
        title: {
          text: "By Customer Categories",
          padding: [24, 24],
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ",
        },
        series: [
          {
            type: "pie",
            radius: [40, 150],
            center: ["50%", "40%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 8,
            },
            data: [],
          },
        ],
      },
      categoryColors: [
        "#3BA272",
        "#B4E7E2",
        "#EE6666",
        "#0A7FA3",
        "#FAC858",
        "#91CC75",
        "#5470C6",
        "#73C0DE",
        "#024058",
        "#E77E3D",
      ],
      stageColors: ["#91cc75", "#5470c6", "#ee6666", "#fac858", "#73c0de"],
    };
  },
  watch: {
    categoriesData: {
      handler(newValue) {
        this.chartCategory.series[0].data = newValue;
      },
      deep: true,
    },
  },
  mounted() {
    let updatedData = this.categories.map((element, index) => {
      return { ...element, itemStyle: { color: this.categoryColors[index] } };
    });
    this.categoriesData = updatedData;
    this.chartCategory.series[0].data = this.categoriesData;
  },
};
</script>

<style scoped>
.h-800 {
  height: 800px;
}
.chart {
  flex: 1;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px #0000001a;
  position: relative;
}
.funnel-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  margin-right: 8px;
}
.funnel-info-container {
  margin-bottom: 8px;
  border-bottom: 1px solid #e9e9ea;
}
.stages-wrapper {
  position: absolute;
  bottom: 16px;
  max-height: 250px;
  left: 16px;
  width: 90%;
}
.categories-wrapper {
  position: absolute;
  bottom: 8px;
  max-height: 225px;
  left: 16px;
  width: 90%;
  overflow-y: auto;
}
.info-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  color: #071015;
}
.info-amount {
  font-size: 14px;
  font-weight: 700;
  line-height: 18.2px;
  color: #071015;
}
</style>
