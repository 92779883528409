
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueAxios from 'vue-axios';
import client from './services/api';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueMoment from 'vue-moment';
import AmountFormat from './plugins/amount_format';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import BaseInput from './components/UI/BaseInput.vue';
import BaseSelect from './components/UI/BaseSelect.vue';
import BaseTagsSelect from './components/UI/BaseTagsSelect.vue';
import BaseButton from './components/UI/BaseButton.vue';
import BaseSpinner from './components/UI/BaseSpinner.vue';
import BasePagination from './components/UI/BasePagination.vue';
import CommonMenu from './components/common/menu.vue';
import CommonLoading from './components/common/loading.vue';

const app = createApp(App);

app.use(router);
app.use(store);
app.use(VueAxios, client);
app.use(VueMoment);
app.use(AmountFormat);
app.use(BootstrapVue);
app.use(IconsPlugin);

app.component('base-input', BaseInput);
app.component('base-select', BaseSelect);
app.component('base-tags-select', BaseTagsSelect);
app.component('base-button', BaseButton);
app.component('base-spinner', BaseSpinner);
app.component('base-pagination', BasePagination);
app.component('common-menu', CommonMenu);
app.component('common-loading', CommonLoading);


app.mount('#app');