import * as Vue from 'vue';
import ResourceService from "./resource.service";
import Client from "@/services/api/api.service"

class CompanyService extends ResourceService {
  static get resource() {
    return "admin/companies";
  }

  static accept(company_id, payload) {
    const url = `${this.resource}/${company_id}/accept`;

    return new Promise((resolve, reject) => {
      Client
        .post(url, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static decline(company_id, payload) {
    const url = `${this.resource}/${company_id}/decline`;

    return new Promise((resolve, reject) => {
      Client
        .post(url, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static applications(company_id) {
    return CompanyService.get(`${company_id}/applications`);
  }
  static delete_company(company_id) {
    return CompanyService.get(`${company_id}/delete`);
  }
}

export default CompanyService;
