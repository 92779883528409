<template>
  <div class="p-4">
    <div class="map-title">By Location</div>
    <div ref="chart" class="map"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import middleEastJson from "@/assets/custom.geo.json";

export default {
  name: "MiddleEastMap",
  props: ["jordanApp", "saudiApp",  "egyptApp" ,"uaeApp", "palestine", "pakistan" ,"ukApp" ,"lebanon", "nigeria", "usa", "korea" ,"oman", "tunisia", "morocco" ],
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      let myChart = echarts.init(this.$refs.chart);

      myChart.showLoading();
      echarts.registerMap("MiddleEast", middleEastJson);
      myChart.hideLoading();

      let option = {
        tooltip: {
          trigger: "item",
          showDelay: 0,
          transitionDuration: 0.2,
          formatter: function (params) {
            return `${params.name}: ${params.value || 0}`;
          },
        },
        visualMap: {
          show: false,
          inRange: {
            color: ["#83888A", "#F1F1F4"],
          },
          text: ["High", "Low"],
          calculable: true,
        },
        series: [
          {
            type: "map",
            roam: true,
            map: "MiddleEast",
            center: [35.923963, 31.963158],
            zoom: 6,
            emphasis: {
              label: {
                show: true,
              },
            },
            itemStyle: {
              normal: {
                areaColor: "#F1F1F4",
                borderColor: "#fff",
                borderWidth: 1,
              },
              emphasis: {
                areaColor: "#0A7FA3",
              },
            },
            data: [
              {
                name: "Saudi Arabia",
                value: this.saudiApp,
              },
              {
                name: "Iraq",
                value: this.iraqApp,
              },
              {
                name: "Jordan",
                value: this.jordanApp,
              },
              {
                name: "Lebanon",
                value: this.lebanon,
              },
              {
                name: "Egypt",
                value: this.egyptApp,
              },
              {
                name: "United Arab Emirates",
                value: this.uaeApp,
              },
              {
                name: "palestine",
                value: this.palestine,
              },
              {
                name: "Pakistan",
                value: this.pakistan,
              },
              {
                name: "United Kingdom",
                value: this.ukApp,
              },
              {
                name: "Nigeria",
                value: this.nigeria,
              },
              {
                name: "United States of America",
                value: this.usa,
              },
              {
                name: "South Korea",
                value: this.korea,
              },
              {
                name: "Oman",
                value: this.oman,
              },
              {
                name: "Tunisia",
                value: this.tunisia,
              },    {
                name: "Morocco",
                value: this.morocco,
              },
            ],
          },
        ],
      };

      myChart.setOption(option);
    },
  },
};
</script>

<style scoped>
.map-title {
  font-size: 18px;
  font-weight: 400;
  line-height: 23.4px;
  color: #071015;
}
.map {
  width: 100%;
  height: 600px;
}
</style>
